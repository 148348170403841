angular.module 'app'
.constant 'ueditorConfig',
# 'fullscreen',
  ue:
    config:
      toolbars: [
        [
          'source', 'undo', 'redo', '|', 'blockquote', 'horizontal', '|', 'justifyleft', 'justifycenter', 'justifyright',
          'justifyjustify', 'insertorderedlist', 'insertunorderedlist', 'indent',
          'subscript', 'superscript', 'formatmatch', 'autotypeset', '|', 'rowspacingtop',
          'rowspacingbottom', 'lineheight', 'paragraph', 'customstyle', 'fontfamily', 'fontsize',
          '|', 'link' ,'selectall', 'forecolor', 'backcolor', 'bold', 'italic', 'underline',
          'table', 'strikethrough', 'removeformat', 'cleardoc', 'horizontal', 'date', 'time',
          '|', 'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow',
          'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells',
          'splittorows', 'splittocols'
        ]
      ]
