angular.module 'app'
.controller 'ImportStudentCtrl', ($stateParams, $uibModalInstance, $scope, $location, Storage, $http, FileUploader, config, growl, data) ->
  $scope.ui =
    isUpload: false
    user: Storage.get('login_user')
    thisUrl: $location.$$absUrl.substr(0, $location.$$absUrl.indexOf('/#/'))

    uploadData: {}

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  uploader = $scope.uploader = new FileUploader(
    url: "#{config.api.baseUrl}/cumpus/upload/students?group_id=#{$scope.ui.user.group_id}&original_id=#{$stateParams.original_id}"
  )
  # FILTERS
  uploader.filters.push
    name: 'excelFilter'
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 5
        growl.error '文件大小超過限制!請選擇小於5M的文件上傳。'
        return
      type = '|' + item.name.slice(item.name.lastIndexOf('.') + 1) + '|'
      '|csv|xlsx|xls|'.indexOf(type) != -1

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.isUpload = true
    $scope.ui.uploadData = response
    $scope.ui.uploadData.noChange = if $scope.ui.uploadData.count - $scope.ui.uploadData.addCount - $scope.ui.uploadData.updateCount < 0 then 0 else $scope.ui.uploadData.count - $scope.ui.uploadData.addCount - $scope.ui.uploadData.updateCount
    growl.success '上傳成功'


  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error '上傳失敗'

