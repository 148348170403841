angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.smartService',
      url: '/smartService'
      templateUrl: 'apps/smartService/layout/layout'
      controller: 'LayoutTestCtrl'

    .state 'app.smartService.qa',
      url: '/qa'
      templateUrl: 'apps/smartService/qa/qa'
      controller: 'QaCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
              ]
          ])

    .state 'app.smartService.synonym',
      url: '/synonym'
      templateUrl: 'apps/smartService/synonym/synonym'
      controller: 'SynonymCtrl'

    .state 'app.smartService.default',
      url: '/default'
      templateUrl: 'apps/smartService/default/default'
      controller: 'DefaultQaCtrl'

    .state 'app.smartService.concern',
      url: '/concern'
      templateUrl: 'apps/smartService/concern/concern'
      controller: 'ConcernQaCtrl'
