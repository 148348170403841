angular.module 'app'
.controller 'AddJumpLinkCtrl',($rootScope, $scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs,growl, $timeout, SweetAlert, data) ->

  $scope.ui =
    isLoading: false
    url: data.url


  $scope.save = ->
    return growl.error "請輸入跳轉鏈接或取消" if $scope.ui.url == ''
    $scope.ui.url = 'http://' + $scope.ui.url if $scope.ui.url.indexOf('http://') < 0 and $scope.ui.url.indexOf('https://') < 0
    $uibModalInstance.close ($scope.ui.url)

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'