angular.module('app')
  .directive('ngThumb', ['$window', ($window) ->
    helper =
      support: !!($window.FileReader && $window.CanvasRenderingContext2D)
      isFile: (item) ->
        return angular.isObject(item) && item instanceof $window.File
      isImage: (file) ->
        type =  '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|'
        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

    return {
      restrict: 'A'
      template: '<canvas/>'
      link: (scope, element, attributes) ->
        return if !helper.support

        params = scope.$eval(attributes.ngThumb)

        return if !helper.isFile(params.file)
        return if !helper.isImage(params.file)

        canvas = element.find('canvas')
        reader = new FileReader()

        onLoadImage = ->
          width = params.width || 150
          height = params.height || 100
          canvas.attr({ width: width, height: height })
          canvas.css(params.style) if params.style != undefined
          canvas[0].getContext('2d').drawImage(this, 0, 0, width, height)

        onLoadFile = (event) ->
          img = new Image()
          img.onload = onLoadImage
          img.src = event.target.result

        reader.onload = onLoadFile
        reader.readAsDataURL(params.file)
    }
  ])
