angular.module 'app'
.controller 'TemplateModalCtrl', ($scope, $http, $state, $sce, $stateParams, $uibModalInstance, config, Storage, dialogs, growl, data) ->

  $scope.ui =
    user: Storage.get('login_user')
    totalItems: 0
    currentPage: 1
    step: 0
    perPage: 10
    maxSize: 6
    dataArr: []
    _data: {}
    jumpUrl: ''

  getTemplate = ->
    # $scope.ui.dataArr = []
    # $scope.ui.totalItems = 0
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/template?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        data.tip = $sce.trustAsHtml '<h4 style="color:#555;">' + data.name + '</h4><div class="margin-bottom-md">' + data.created_at + '</div><div style="font-size:13px;">' + data.example.replace(new RegExp('\n','g'),'<br>') + '</div>'
      $scope.ui.dataArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.$watch 'ui.currentPage', -> $scope.findData
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findTemplateData = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/template/#{$scope.ui._data._id}?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      if res.status == 200 && res.data != null
        option = res.data.content
        html_str = option.replace(new RegExp('\n','g'),'<br>').split('<br>')
        keys = []

        _.each html_str, (val) ->
          _name = ''
          _key = ''
          _name = val.split('{{')[0]
          _key = val.split('{{')[1].split('.DATA}}')[0] if val.split('{{')[1] != undefined
          keys.push {name: _name, key: _key, val: '', color: '#173177'}
        res.data.content_val = keys
        res.data.example_html = $sce.trustAsHtml res.data.example.replace(new RegExp('\n','g'),'<br>')
        $scope.ui._data = res.data
        $scope.ui._data.template_id_short = res.data.template_id
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.prev = ->
    $scope.ui.step = 0
    getTemplate()

  $scope.next = (data) ->
    $scope.ui.step = 1
    if data != null
      option = data.content
      html_str = option.replace(new RegExp('\n','g'),'<br>').split('<br>')
      keys = []

      _.each html_str, (val) ->
        _name = ''
        _key = ''
        _name = val.split('{{')[0]
        _key = val.split('{{')[1].split('.DATA}}')[0] if val.split('{{')[1] != undefined
        keys.push {name: _name, key: _key, val: '', color: '#173177'}

      data.content_val = keys
      data.example_html = $sce.trustAsHtml data.example.replace(new RegExp('\n','g'),'<br>')
      $scope.ui._data = data
      $scope.ui._data.template_id_short = data.template_id


  # 套用
  $scope.setValue = ->
    _strs = $scope.ui._data.example.replace(new RegExp('\n','g'),'<br>').split('<br>')

    _.each $scope.ui._data.content_val, (data, i) ->
      if data.name == ''
        data.val = _strs[i]
      else
        data.val = _strs[i].substring(_strs[i].indexOf(data.name) + data.name.length)

  # 编制
  $scope.setExample = ->
    $scope.ui._data.content_edit = angular.copy $scope.ui._data.content_val

    _strs = $scope.ui._data.example.replace(new RegExp('\n','g'),'<br>').split('<br>')

    _.each $scope.ui._data.content_edit, (data, i) ->
      data.color = '#173177'
      if data.name == ''
        data.val = _strs[i]
      else
        data.val = _strs[i].substring(_strs[i].indexOf(data.name) + data.name.length)

  # 保存示例
  $scope.saveExample = ->
    _str = angular.copy $scope.ui._data.content
    _.each $scope.ui._data.content_edit, (data, i) ->
      _str = _str.replace('{{' + data.key + '.DATA}}', data.val)

    $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/template/#{$scope.ui._data._id}?access_token=" + Storage.get('access_token'),
      example: _str
    .then (res) ->
      findTemplateData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.save = ->
    _exa = ''
    _str = '{'
    _.each $scope.ui._data.content_val, (key) ->
      key.val = "" if !key.val
      key.val = key.val.replace('\n','').replace('\r','')
      _str += key.key + ': {value: "' + key.val + '", color: "' + key.color + '"},' if key.key != ''
      _exa += key.name + key.val + '\n'
    _str += '}'

    str = eval("(" + _str + ")")

    _status = true
    _.each str, (e) ->
      _status = false if e.value == ''
    if _status == false
      growl.error('輸入框不可為空')
      return

    $uibModalInstance.close {data: str, _id: $scope.ui._data.template_id, example: _exa, jumpUrl: $scope.ui.jumpUrl}

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.setColor = (_index, _color) ->
    $scope.ui._data.content_val[_index].color = _color

  $scope.$watch 'ui.currentPage', getTemplate
