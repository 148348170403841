angular.module 'app'
.controller 'WatermarkCtrl', ($scope, $http, $state, $uibModalInstance, config, Storage, FileUploader, growl, data) ->
  $scope.ui =
    data: data
    resourcesUrl: config.api.baseUrl
    isDisabled: false

  $scope.upload = ->
    $scope.ui.isDisabled = true
    $scope.uploader.url = config.api.baseUrl + '/upload/wmimg?access_token=' + Storage.get('access_token') + '&original_id=' + $scope.ui.data.original_id

  uploader = $scope.uploader = new FileUploader(
    removeAfterUpload: true
    autoUpload: true
    url: ''
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 2
        growl.error("不能上傳大於2MB的圖片！", {referenceId: 1})
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.isDisabled = false
    $scope.ui.data.wm_path = response.wm_path
    growl.success '上傳成功'

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    $scope.ui.isDisabled = false
    growl.error '上傳失敗'

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
