angular.module 'app'
.controller 'TemplateUesrCtrl', ($scope, $http, $stateParams, $state, config, Storage, SweetAlert, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').manage.template.templateUesr.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    dataArr: []

  findData = ->
    $scope.ui.activeDataArr = []
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/template?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.dataArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', findData

  $scope.add = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定同步官方模板!"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/init/template?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findData()
          growl.success "同步成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.delete = (template) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除模板“#{template.name}”？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/template/#{template._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findData()
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.getDetails = (_index) ->
    dialogs.create('apps/template/template-library/detail/detail', 'TemplateDetailCtrl', {_id: $scope.ui.dataArr[_index]._id, type: "template"}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->
