angular.module 'app'
.controller 'GraphicUrlModalCtrl', ($uibModalInstance, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, data) ->
  $scope.ui =
    url: ''

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $uibModalInstance.close $scope.ui.url
