angular.module 'app'
.factory 'echartsThemeShine', ->
  theme =
     # 默认色板
    color: [
        '#3299e6','#32c0d8','#4683cb','#8bc640',
        '#f8802a','#3ec33e','#8350a8','#318ee7'
    ]
    # 图表标题
    title:
      itemGap: 8
      textStyle:
        fontWeight: 'normal'


    # 图例
    legend:
      itemGap: 8


    # 值域
    dataRange:
      itemWidth: 15            # 值域图形宽度，线性渐变水平布局宽度为该值 * 10
      color: ['#1790cf','#a2d4e6']


    # 工具箱
    toolbox:
      color: ['#329afe','#28bd28','#814da7','#ef6716']
      itemGap: 8


    # 提示框
    tooltip:
      trigger:      # 触发类型，默认数据触发，见下图，可选为：'item' ¦ 'axis'
        showDelay: 20            # 显示延迟，添加显示延迟可以避免频繁切换，单位ms
        hideDelay: 100            #隐藏延迟，单位ms
        transitionDuration: 0.4  #动画变换时间，单位s
        backgroundColor: 'rgba(0,0,0,0.7)'     # 提示背景颜色，默认为透明度为0.7的黑色
        borderColor: '#333'       #提示边框颜色
        borderRadius: 4           #提示边框圆角，单位px，默认为4
        borderWidth: 0            # 提示边框线宽，单位px，默认为0（无边框）
        padding: 5                #提示内边距，单位px，默认各方向内边距为5，
                                   # 接受数组分别设定上右下左边距，同css
        axisPointer:            # 坐标轴指示器，坐标轴触发有效
          type: 'line'         # 默认为直线，可选为：'line' | 'shadow'
          lineStyle:         # 直线指示器样式设置
            color: '#48b'
            width: 2
            type: 'dashed'

            shadowStyle:                       # 阴影指示器样式设置
              width: 'auto',                   # 阴影大小
              color: 'rgba(150,150,150,0.3)'  # 阴影颜色


        textStyle:
            color: '#fff'



    # 区域缩放控制器
    dataZoom:
      orient: 'horizontal'
      backgroundColor: 'rgba(0,0,0,0)'
      dataBackgroundColor: '#eee'            # 数据背景颜色
      fillerColor: 'rgba(144,197,237,0.2)'   # 填充颜色
      handleColor: 'rgba(70,130,180,0.8)'     # 手柄颜色


    grid:
      borderWidth: 0


    # 类目轴
    categoryAxis:
      axisLine:             # 坐标轴线
        show: false

      axisTick:             # 坐标轴小标记
        show: false


    # 数值型坐标轴默认参数
    valueAxis:
      axisLine:             # 坐标轴线
        show: false

      axisTick:             # 坐标轴小标记
        show: false

      splitArea:            # 分隔区域
        show: true       # 默认不显示，属性show控制显示与否
        areaStyle:        # 属性areaStyle（详见areaStyle）控制区域样式
          color: ['rgba(250,250,250,0.2)','rgba(200,200,200,0.2)']


    timeline:
      lineStyle:
        color: '#005eaa'

      controlStyle:
        normal: color: '#005eaa'
        emphasis: color: '#005eaa'

    # K线图默认参数
    k:
      itemStyle:
        normal:
          color: '#c12e34'          # 阳线填充颜色
          color0: '#2b821d'      # 阴线填充颜色
          lineStyle:
            width: 1
            color: '#c12e34',   # 阳线边框颜色
            color0: '#2b821d'   # 阴线边框颜色


    map:
      itemStyle:
        normal:
          areaStyle:
            color: '#ddd'

          label:
            textStyle:
              color: '#c12e34'

        emphasis:                 # 也是选中样式
          areaStyle:
            color: '#e6b600'

          label:
            textStyle:
              color: '#c12e34'


    force:
      itemStyle:
        normal:
          linkStyle:
            strokeColor: '#005eaa'


    chord:
      padding: 4
      itemStyle:
        normal:
          lineStyle:
            width: 1
            color: 'rgba(128, 128, 128, 0.5)'

          chordStyle:
            lineStyle:
              width: 1
              color: 'rgba(128, 128, 128, 0.5)'

        emphasis:
          lineStyle:
            width: 1
            color: 'rgba(128, 128, 128, 0.5)'

            chordStyle:
              lineStyle:
                width: 1
                color: 'rgba(128, 128, 128, 0.5)'


    gauge:
      startAngle: 225
      endAngle: -45
      axisLine:             # 坐标轴线
        show: true        # 默认显示，属性show控制显示与否
        lineStyle:        # 属性lineStyle控制线条样式
          color: [[0.2, '#2b821d'],[0.8, '#005eaa'],[1, '#c12e34']],
          width: 5

      axisTick:             # 坐标轴小标记
        splitNumber: 10   # 每份split细分多少段
        length: 8        # 属性length控制线长
        lineStyle:        # 属性lineStyle控制线条样式
          color: 'auto'

      axisLabel:            # 坐标轴文本标签，详见axis.axisLabel
        textStyle:        # 其余属性默认使用全局文本样式，详见TEXTSTYLE
          color: 'auto'

      splitLine:            # 分隔线
        length: 12,         # 属性length控制线长
        lineStyle:        # 属性lineStyle（详见lineStyle）控制线条样式
          color: 'auto'

      pointer:
        length: '90%'
        width: 3
        color: 'auto'

      title:
        textStyle:        # 其余属性默认使用全局文本样式，详见TEXTSTYLE
          color: '#333'

      detail:
        textStyle:        # 其余属性默认使用全局文本样式，详见TEXTSTYLE
          color: 'auto'


    textStyle:
      fontFamily: '微软雅黑, Arial, Verdana, sans-serif'

