angular.module 'app'
.controller 'ColumnPicturesCtrl', ($scope, $state, $http, $q, Storage, $stateParams, config, SweetAlert, growl, dialogs) ->
  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    isLoading: false
    user: Storage.get('login_user')
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    pictures: []
    column: {}

  do findColumn = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/#{$stateParams._id}", {}
    .then (res) ->
      $scope.ui.column = res.data
    .catch (error) ->

  findData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        group_id: $scope.ui.user.group_id
        programa_id: $stateParams._id
        sort: '-created_at'
    .then (res) ->
      $scope.ui.pictures = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .catch (error) ->
      $scope.ui.isLoading = false
      growl.error '查詢錯誤!', {ttl: 2000}

  $scope.$watch 'ui.currentPage', findData

  $scope.goBack = ->
    $state.go("app.column-manage", {})

  $scope.editImage = (image) ->
    dlg = dialogs.create('apps/campus/column-manage/modal/edit-picture/edit-picture', 'EditPictureCtrl', {_id: $stateParams._id, image: image}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->
  $scope.addPicture = ->

    dlg = dialogs.create('apps/campus/column-manage/modal/edit-picture/upload-file', 'UploadFileCampusCtrl', {_id: $stateParams._id}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  $scope.editIsShow = (image) ->
    $http.put "#{config.api.baseUrl}/cumpus/programa/list/#{image._id}", image
    .then (res) ->
      growl.success '保存成功！', ttl: 2000
      # findData()
    .catch (error) ->
      image.data.is_show = !image.is_show
      growl.error '保存失敗！', ttl: 2000


  $scope.delImage = (image) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除圖片“#{image.data.name}”嗎？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cumpus/programa/list/#{image._id}", {}
        .then (res) ->
          findData()
          growl.success '刪除成功'
        .catch (error) ->
          growl.error '刪除失敗'