angular.module 'app'
.controller 'UserAttributeCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, $timeout, Storage, dialogs, growl) ->

  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.fans.is_use == false

  $scope.ui =
    sex: {}
    language: {}
    area: {}

    sexSort: ''
    languageSort: ''
    areaSort: ''

  $scope.userAttributeGenderChart =
    tooltip:
      trigger: 'item'
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    legend:
      orient: 'vertical'
      x: 'left'
      data: ['男性', '女性', '未知']
    toolbox:
      show: true
      feature:
        mark:
          show: false
        dataView:
          show: false
          readOnly: false
        magicType:
          show: false
          type: [
            'line'
            'bar'
          ]
        restore:
          show: true
        saveAsImage:
          show: true
    calculable: true
    series: [
      name: '性別分佈'
      type: 'pie'
      radius: '55%'
      center: ['50%', '50%']
      data: [
        value: 0
        name: '男性'
      ,
        value: 0
        name: '女性'
      ,
        value: 0
        name: '未知'
      ]
    ]

  $scope.userAttributeLanguageChart =
    tooltip:
      trigger: 'item'
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    legend:
      orient: 'vertical'
      x: 'left'
      data: ['簡體中文', '繁體中文', '英文', '未知']
    toolbox:
      show: true
      feature:
        mark:
          show: false
        dataView:
          show: false
          readOnly: false
        magicType:
          show: false
          type: [
            'line'
            'bar'
          ]
        restore:
          show: true
        saveAsImage:
          show: true
    calculable: true
    series: [
      name: '語言分佈'
      type: 'pie'
      radius: '55%'
      center: ['50%', '50%']
      data: [
        value: 0
        name: '簡體中文'
      ,
        value: 0
        name: '繁體中文'
      ,
        value: 0
        name: '英文'
      ,
        value: 0
        name: '未知'
      ]
    ]

  $scope.userAttributeSourceChart =
    tooltip:
      trigger: 'item'
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    legend:
      orient: 'vertical'
      x: 'left'
      data: ['公眾號搜索', '掃描二維碼', '圖文頁右上角菜單', '圖文頁內公眾號名稱', '名片分享', '支付后關注', '其他合計']
    toolbox:
      show: true
      feature:
        mark:
          show: false
        dataView:
          show: false
          readOnly: false
        magicType:
          show: false
          type: [
            'line'
            'bar'
          ]
        restore:
          show: true
        saveAsImage:
          show: true
    calculable: true
    series: [
      name: '關注來源分佈'
      type: 'pie'
      radius: '55%'
      center: ['50%', '50%']
      data: [
        value: 0
        name: '公眾號搜索'
      ,
        value: 0
        name: '掃描二維碼'
      ,
        value: 0
        name: '圖文頁右上角菜單'
      ,
        value: 0
        name: '圖文頁內公眾號名稱'
      ,
        value: 0
        name: '名片分享'
      ,
        value: 0
        name: '支付后關注'
      ,
        value: 0
        name: '其他合計'
      ]
    ]

  $scope.userAttributeAreaChart =
    tooltip:
      trigger: 'item'
    legend:
      orient: 'vertical'
      x: 'left'
      data: ['地區分佈']
    dataRange:
      min: 0
      max: 2500
      x: 'left'
      y: 'bottom'
      text: ['高','低']
      calculable: true
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    series: [
      name: '地區分佈'
      type: 'map'
      mapType: 'china'
      roam: false
      itemStyle:
        normal:
          label:
            show: true
        emphasis:
          label:
            show: true
      data: [
          name: ''
          value: 0
      ]
    ]

  do fetchFollowers = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/nature_statis?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      _.each res.data.SexData.statis, (val) ->
        val.name = switch val.sex
          when "1" then "男性"
          when "2" then "女性"
          when "0" then "未知"
      _.each res.data.LangData.statis, (val) ->
        val.name = switch val.language
          when "zh_CN" then "簡體中文"
          when "zh_TW" then "繁體中文"
          when "en" then "英文"
          when "Other" then "未知"

      $scope.ui.sex = res.data.SexData.statis
      $scope.ui.language = res.data.LangData.statis
      $scope.ui.area = res.data.AreaData.statis
      man_obj = _.findWhere $scope.userAttributeGenderChart.series[0].data, name: "男性"
      find_man = _.findWhere res.data.SexData.statis, sex: "1"
      man_obj.value = find_man.count
      male_obj = _.findWhere $scope.userAttributeGenderChart.series[0].data, name: "女性"
      find_male = _.findWhere res.data.SexData.statis, sex: "2"
      male_obj.value = find_male.count
      unknow_sex = _.findWhere $scope.userAttributeGenderChart.series[0].data, name: "未知"
      find_unknow = _.findWhere res.data.SexData.statis, sex: "0"
      unknow_sex.value = find_unknow.count

      cn_obj = _.findWhere $scope.userAttributeLanguageChart.series[0].data, name: "簡體中文"
      find_cn = _.findWhere res.data.LangData.statis, language: "zh_CN"
      cn_obj.value = find_cn.count
      tw_obj = _.findWhere $scope.userAttributeLanguageChart.series[0].data, name: "繁體中文"
      find_tw = _.findWhere res.data.LangData.statis, language: "zh_TW"
      tw_obj.value = find_tw.count
      en_obj = _.findWhere $scope.userAttributeLanguageChart.series[0].data, name: "英文"
      find_en = _.findWhere res.data.LangData.statis, language: "en"
      en_obj.value = find_en.count
      unknow_language = _.findWhere $scope.userAttributeLanguageChart.series[0].data, name: "未知"
      find_unknow = _.findWhere res.data.LangData.statis, language: "Other"
      unknow_language.value = find_unknow.count

      area = []
      _.each res.data.AreaData.statis, (val) ->
        obj =
          name: val.province
          value: val.count
        area.push obj
      $scope.userAttributeAreaChart.series[0].data = area
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.userAttributeClick = (param) ->

  $scope.sortSex = (_index) ->
    return if $scope.ui.sex.length == 0

    $scope.ui.sex.sort (a, b) ->
      if $scope.ui.sexSort != _index
        return b[_index] - a[_index]
      else
        return a[_index] - b[_index]

    if $scope.ui.sexSort != _index
      $scope.ui.sexSort = _index
    else
      $scope.ui.sexSort = ''

  $scope.sortLanguage = (_index) ->
    return if $scope.ui.language.length == 0

    $scope.ui.language.sort (a, b) ->
      if $scope.ui.languageSort != _index
        return b[_index] - a[_index]
      else
        return a[_index] - b[_index]

    if $scope.ui.languageSort != _index
      $scope.ui.languageSort = _index
    else
      $scope.ui.languageSort = ''

  $scope.sortArea = (_index) ->
    return if $scope.ui.area.length == 0

    $scope.ui.area.sort (a, b) ->
      if $scope.ui.areaSort != _index
        return b[_index] - a[_index]
      else
        return a[_index] - b[_index]

    if $scope.ui.areaSort != _index
      $scope.ui.areaSort = _index
    else
      $scope.ui.areaSort = ''

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadSex = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/nature_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: 'sex'
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.downloadLanguage = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/nature_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: 'language'
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.downloadArea = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/nature_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: 'area'
      prepareCallback: prepare
      successCallback: success
      failCallback: error
