angular.module 'app'
.controller 'OneTimeCtrl', ($scope, $http, $stateParams, $state, config, Storage, growl, $timeout, $rootScope, dialogs) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.graphic.is_use == false

  $scope.ui =
    totalItems: 0  # 总页数
    currentPage: 1 # 当前页数
    maxSize: 10 # 最大条数
    perPage: 10  #条数

    isLoading: false
    showDetails: false

    tableData: []
    activeTableData: []
    activeSort: ''
    activeTableSort: ''

    activeData: {}
    tendencyData: []
    newStatisData: []
    subTableData: []

    radioIndex: '0'
    radioOne: '0'
    radioTwo: '0'

    date:
      startDate: moment().subtract(29, 'days')
      endDate: moment()

    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#one-time'
  $scope.ui.opts.opens = 'right'

  $scope.OneShotPieChart =
    backgroundColor: '#f3f3f3'
    title:
      text: '閱讀來源分佈'
      textStyle: fontSize: 14
    tooltip:
      trigger: 'item'
      formatter: "{a} <br/>{b}: {c} ({d}%)"
    legend:
      x: 'center'
      y: 'bottom'
      data: []
    series: [
        name: ''
        type: 'pie'
        radius: 0
        label: normal: position: 'center'
        itemStyle:
          normal:
            label:
              textStyle:
                color: '#000'
                fontSize: 14
        data: [
            name: '0次'
            tooltip:
              show: false
              formatter: ''
          ,
            name: '0人'
            tooltip:
              show: false
              formatter: ''
          ,
            name: '閱讀總數'
            tooltip:
              show: false
              formatter: ''
            itemStyle: normal: label: textStyle: color: '#aaa'
        ]
      ,
        name: '閱讀來源分佈'
        type: 'pie'
        radius: ['40%', '60%']
        label: normal: show: true
        labelLine: normal: show: true
        data: []
    ]

  $scope.OneShotBarChart =
    title:
      text: '轉化率'
      textStyle: fontSize: 14
    tooltip:
      trigger: 'axis'
      formatter: (data) ->
        return data[0].name + '<br/>' + data[0].data.name + '：' + data[0].data.value
    legend:
      x: 'center'
      y: 'bottom'
      data: []
    xAxis: type: 'value'
    yAxis:
      type: 'category'
      data: ['在朋友圈閱讀', '在朋友圈再次分享', '從公眾號分享到朋友圈', '公眾號會話閱讀', '送達']
    series: [
        name: ''
        type: 'bar'
        barMaxWidth: 20
        data: [
            name: '二次傳播'
            value: 0
            itemStyle: normal: color: 'rgb(91,204,223)'
          ,
            name: '二次傳播'
            value: 0
            itemStyle: normal: color: 'rgb(91,204,223)'
          ,
            name: '一次傳播'
            value: 0
            itemStyle: normal: color: '#3ca5f6'
          ,
            name: '一次傳播'
            value: 0
            itemStyle: normal: color: '#3ca5f6'
          ,
            name: '一次傳播'
            value: 0
            itemStyle: normal: color: '#3ca5f6'
        ]
      ,
        name: ''
        type: 'line'
        z: 0
        itemStyle:
          normal:
            color: 'rgba(60, 165, 246, .5)'
        lineStyle:
          normal:
            color: 'rgba(60, 165, 246, .5)'
        areaStyle:
          normal:
            color: 'rgba(60, 165, 246, .3)'
        label:
          normal:
            show: true
            position: [0, 15]
            formatter: (params) ->
              return '' if params.dataIndex < 3
              return '' if params.data.value <= 0
              return params.data.name + '%'
            textStyle:
              color: 'rgba(60, 165, 246, 1)'
        data: [
            name: ''
            value: ''
          ,
            name: ''
            value: ''
          ,
            name: ''
            value: 0
          ,
            name: ''
            value: 0
          ,
            name: ''
            value: 0
        ]
    ]


  $scope.OneShotLineChart =
    title:
      text: ''
    tooltip:
      trigger: 'axis'
    legend:
      x: 'center'
      y: 'bottom'
      data: ['圖文頁閱讀次數', '圖文頁閱讀人數']
    grid:
      containLabel: true
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    xAxis: [{
      type: 'category'
      boundaryGap: false
      splitLine:
        show: false
      data: ['']
    }]
    yAxis: [{
      type: 'value'
    }]
    series: [
        name: '圖文頁閱讀次數'
        type: 'line'
        data: [0]
      ,
        name: '圖文頁閱讀人數'
        type: 'line'
        data: [0]
    ]

  $scope.OneShotPie2Chart =
    title:
      text: ''
    tooltip:
      trigger: 'item'
      formatter: "{a} <br/>{b}: {c} ({d}%)"
    legend:
      x: 'right'
      orient: 'vertical'
      data: ['']
    series: [
        name: '閱讀來源分佈'
        type: 'pie'
        radius: [0, '60%']
        data: [0]
    ]

  $scope.OneShotLine2Chart =
    title: text: ''
    tooltip: trigger: 'axis'
    legend:
      y: 'bottom'
      data: ['']
    toolbox:
      show: true
      feature:
        magicType:
          show: true
          type: ['stack', 'tiled']
        restore:
          show: true
        saveAsImage:
          show: true
    grid:
      containLabel: true
    xAxis: [{
      type: 'category'
      boundaryGap: false
      splitLine:
        show: false
      data: ['']
    }]
    yAxis: [{type: 'value'}]
    series: []

  # 表格
  getAllData = ->
    $scope.ui.isLoading = true
    _type = if $scope.ui.radioIndex is '0' then 'count_data' else 'average_data'
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/once_articles?access_token=" + Storage.get('access_token'),
      params:
        type: _type
        start_release_date: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_release_date: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      $scope.ui.tableData = []
      $scope.ui.tableData = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .finally ->
      $scope.$watch 'ui.currentPage', (newData) ->
        $scope.ui.activeTableData = []
        return if $scope.ui.tableData.length == 0
        _tableData = []
        _tableData = $scope.ui.tableData.slice (newData - 1) * $scope.ui.perPage, (newData - 1) * $scope.ui.perPage + $scope.ui.perPage
        _index = 0
        _.each _tableData, (data, i) ->
          $scope.ui.activeTableData.push data
          _.each data.titleArray, (obj, j) ->
            if j == 0
              $scope.ui.activeTableData[_index].sub_sequence_number = obj.sequence_number
              $scope.ui.activeTableData[_index].sub_title = obj.title
              $scope.ui.activeTableData[_index].sub_target_user = obj.target_user
            else
              $scope.ui.activeTableData.push {sub_sequence_number: obj.sequence_number, sub_title: obj.title, sub_target_user: obj.target_user}
            _index++
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  $scope.sort = (_name) ->
    return if $scope.ui.tableData.length == 0

    if _name == 'release_date'
      _.each $scope.ui.tableData, (data) ->
        data.release_date = moment(data.release_date)

    $scope.ui.tableData.sort (a, b) ->
      if $scope.ui.activeSort != _name
        return b[_name] - a[_name]
      else
        return a[_name] - b[_name]

    if _name == 'release_date'
      _.each $scope.ui.tableData, (data) ->
        data.release_date = moment(data.release_date).format('YYYY-MM-DD')

    $scope.ui.activeTableData = []
    _tableData = []
    _tableData = $scope.ui.tableData.slice ($scope.ui.currentPage - 1) * $scope.ui.perPage, ($scope.ui.currentPage - 1) * $scope.ui.perPage + $scope.ui.perPage
    _index = 0
    _.each _tableData, (data, i) ->
      $scope.ui.activeTableData.push data
      _.each data.titleArray, (obj, j) ->
        if j == 0
          $scope.ui.activeTableData[_index].sub_sequence_number = obj.sequence_number
          $scope.ui.activeTableData[_index].sub_title = obj.title
          $scope.ui.activeTableData[_index].sub_target_user = obj.target_user
        else
          $scope.ui.activeTableData.push {sub_sequence_number: obj.sequence_number, sub_title: obj.title, sub_target_user: obj.target_user}
        _index++
    if $scope.ui.activeSort != _name
      $scope.ui.activeSort = _name
    else
      $scope.ui.activeSort = ''

  # 阅读来源分布
  getSourceAnalyse = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/once_source_analyse?access_token=" + Storage.get('access_token'),
      params:
        attach: $scope.ui.activeData.attach
    .then (res) ->
      _totalUser = 0
      _totalCount = 0
      $scope.OneShotPieChart.series[1].data = []
      $scope.OneShotPieChart.legend.data = _.pluck res.data, 'source_name'
      _.each res.data, (data) ->
        $scope.OneShotPieChart.series[1].data.push {name: data.source_name, value: data.int_page_read_user}
        _totalUser += data.int_page_read_user
        _totalCount += data.int_page_read_count
      $scope.OneShotPieChart.series[0].data[0].name = _totalCount + '次'
      $scope.OneShotPieChart.series[0].data[1].name = _totalUser + '人'
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  # 转化率
  getTransEffi = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/once_trans_effi?access_token=" + Storage.get('access_token'),
      params:
        attach: $scope.ui.activeData.attach
    .then (res) ->
      $scope.OneShotBarChart.series[0].data[0].value = res.data.int_page_from_feed_read_user
      $scope.OneShotBarChart.series[0].data[1].value = res.data.feed_share_from_feed_user
      $scope.OneShotBarChart.series[0].data[2].value = res.data.feed_share_from_session_user
      $scope.OneShotBarChart.series[0].data[3].value = res.data.int_page_from_session_read_user
      $scope.OneShotBarChart.series[0].data[4].value = res.data.target_user

      $scope.OneShotBarChart.series[1].data[2].value = res.data.feed_share_from_session_user
      $scope.OneShotBarChart.series[1].data[3].value = res.data.int_page_from_session_read_user
      $scope.OneShotBarChart.series[1].data[4].value = res.data.target_user
      $scope.OneShotBarChart.series[1].data[3].name = if res.data.int_page_from_session_read_user != 0 then ((parseFloat(res.data.feed_share_from_session_user) / parseFloat(res.data.int_page_from_session_read_user)) * 100).toFixed(2) else 0
      $scope.OneShotBarChart.series[1].data[4].name = if res.data.target_user != 0 then ((parseFloat(res.data.int_page_from_session_read_user) / parseFloat(res.data.target_user)) * 100).toFixed(2) else 0

      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  #
  getTendency = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/once_tendency?access_token=" + Storage.get('access_token'),
      params:
        attach: $scope.ui.activeData.attach
    .then (res) ->
      $scope.ui.tendencyData = res.data
      setTendencyData()
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  setTendencyData = ->
    if $scope.ui.tendencyData.length == 0
      $scope.OneShotLineChart.xAxis[0].data = ['']
      $scope.OneShotLineChart.series[0].data = [0]
      $scope.OneShotLineChart.series[1].data = [0]
      return

    _type = 'all_source_int_page_read_' if $scope.ui.radioOne == '0' && $scope.ui.radioTwo == '0'
    _type = 'session_int_page_read_' if $scope.ui.radioOne == '0' && $scope.ui.radioTwo == '1'
    _type = 'friends_int_page_read_' if $scope.ui.radioOne == '0' && $scope.ui.radioTwo == '2'
    _type = 'feed_int_page_read_' if $scope.ui.radioOne == '0' && $scope.ui.radioTwo == '3'
    _type = 'hist_int_page_read_' if $scope.ui.radioOne == '0' && $scope.ui.radioTwo == '4'
    _type = 'other_int_page_read_' if $scope.ui.radioOne == '0' && $scope.ui.radioTwo == '5'
    _type = 'share_' if $scope.ui.radioOne == '1'
    _type = 'add_to_fav_' if $scope.ui.radioOne == '2'
    _type = 'like_num_' if $scope.ui.radioOne == '3'

    $scope.OneShotLineChart.xAxis[0].data = []
    $scope.OneShotLineChart.series[0].data = []
    $scope.OneShotLineChart.series[1].data = []

    $scope.OneShotLineChart.xAxis[0].data = _.pluck $scope.ui.tendencyData, 'statis_time'
    $scope.OneShotLineChart.series[0].data = _.pluck $scope.ui.tendencyData, _type + 'count'
    $scope.OneShotLineChart.series[1].data = _.pluck $scope.ui.tendencyData, _type + 'user'

  #
  getLocationReads = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/once_location_reads?access_token=" + Storage.get('access_token'),
      params:
        attach: $scope.ui.activeData.attach
    .then (res) ->
      $scope.OneShotPie2Chart.legend.data = ['']
      $scope.OneShotPie2Chart.series[0].data = []
      _.each res.data, (data) ->
        $scope.OneShotPie2Chart.legend.data.push data.name + '\t-\t' + data.title
        $scope.OneShotPie2Chart.series[0].data.push {name: data.name + '\t-\t' + data.title, value: data.int_page_read_count}
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  #
  getLocationTendency = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/once_locat_tendency?access_token=" + Storage.get('access_token'),
      params:
        attach: $scope.ui.activeData.attach
    .then (res) ->
      $scope.OneShotLine2Chart.xAxis[0].data = []
      $scope.OneShotLine2Chart.legend.data = []
      $scope.OneShotLine2Chart.series = []
      _.each res.data, (data, i) ->
        $scope.OneShotLine2Chart.xAxis[0].data.push data.statis_time
        data.data.sort (a, b) ->
          return a.number - b.number
        _.each data.data, (obj, j) ->
          $scope.OneShotLine2Chart.legend.data.push obj.name if i == 0
          $scope.OneShotLine2Chart.series.push {name: obj.name, type: 'line', data: []} if i == 0
          $scope.OneShotLine2Chart.series[j].data[i] = obj.int_page_read_count
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  #
  getArticleSelf = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/once_article_self?access_token=" + Storage.get('access_token'),
      params:
        attach: $scope.ui.activeData.attach
    .then (res) ->
      res.data.data.sort (a, b) ->
        return a.sequence_number - b.sequence_number
      _.each res.data.data, (data) ->
        data.release_date = moment(data.release_date).format('YYYY-MM-DD')
      $scope.ui.subTableData = res.data.data
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  $scope.sortSubTable = (_index) ->
    return if $scope.ui.subTableData.length == 0

    $scope.ui.subTableData.sort (a, b) ->
      if $scope.ui.activeTableSort != _index
        return b[_index] - a[_index]
      else
        return a[_index] - b[_index]

    if $scope.ui.activeTableSort != _index
      $scope.ui.activeTableSort = _index
    else
      $scope.ui.activeTableSort = ''

  # 查看/返回  详情
  $scope.toggleDetails = (data) ->
    $scope.ui.showDetails = !$scope.ui.showDetails
    $scope.ui.activeData = data || {}
    getAllData() if $scope.ui.showDetails == false
    getSourceAnalyse() if $scope.ui.showDetails == true
    getTransEffi() if $scope.ui.showDetails == true
    getTendency() if $scope.ui.showDetails == true
    getLocationReads() if $scope.ui.showDetails == true
    getLocationTendency() if $scope.ui.showDetails == true
    getArticleSelf() if $scope.ui.showDetails == true

  # 阅读来源分布时间控件
  $scope.$watch 'ui.date', (newValue, oldValue) ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')

    return if newValue == oldValue
    getAllData()

  #
  $scope.$watch 'ui.radioIndex', (newValue, oldValue) ->
    return if newValue == oldValue
    getAllData()

  #
  $scope.$watch 'ui.radioOne', (newValue, oldValue) ->
    return if newValue == oldValue
    setTendencyData()

  #
  $scope.$watch 'ui.radioTwo', (newValue, oldValue) ->
    return if newValue == oldValue
    setTendencyData()

  getAllData()

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadData = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/once_art_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: if $scope.ui.radioIndex is '0' then 'count_data' else 'average_data'
        start_release_date: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_release_date: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error
