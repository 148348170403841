angular.module 'app'
.controller 'MaterialManageCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, CreateDateText, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').manage.material.is_use == false

  $scope.ui =
    dangerList: []

    records: []
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    isLoading: false

    news:
      total: 0
      limit: 5000
      oldest_date: '---'
    image:
      total: 0
      limit: 5000
      oldest_date: '---'
    video:
      total: 0
      limit: 1000
      oldest_date: '---'

  ### 获取数据 ###
  do getData = ->
    $scope.ui.dangerList = []
    $http.get "#{config.api.baseUrl}/material/official/usage?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.news.total = res.data.news.total || 0
      $scope.ui.image.total = res.data.image.total || 0
      $scope.ui.video.total = res.data.video.total || 0

      $scope.ui.news.limit = res.data.news.limit || 5000
      $scope.ui.image.limit = res.data.image.limit || 5000
      $scope.ui.video.limit = res.data.video.limit || 1000

      $scope.ui.news.oldest_date = moment(res.data.news.oldest_date).format('YYYY-MM-DD HH:mm') || '---'
      $scope.ui.image.oldest_date = moment(res.data.image.oldest_date).format('YYYY-MM-DD HH:mm') || '---'
      $scope.ui.video.oldest_date = moment(res.data.video.oldest_date).format('YYYY-MM-DD HH:mm') || '---'

      $scope.ui.dangerList.push '圖文' if ($scope.ui.news.total / $scope.ui.news.limit) >= 0.9
      $scope.ui.dangerList.push '圖片' if ($scope.ui.image.total / $scope.ui.image.limit) >= 0.9
      $scope.ui.dangerList.push '視頻' if ($scope.ui.video.total / $scope.ui.video.limit) >= 0.9
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

    # $scope.ui.news.total = 3883
    # $scope.ui.image.total = 4615
    # $scope.ui.video.total = 352

    # $scope.ui.news.oldest_date = '2016-03-22 11:28'
    # $scope.ui.image.oldest_date = '2016-03-22 10:56'
    # $scope.ui.video.oldest_date = '2016-08-05 16:21'

    # $scope.ui.dangerList.push '圖文' if ($scope.ui.news.total / $scope.ui.news.limit) >= 0.9
    # $scope.ui.dangerList.push '圖片' if ($scope.ui.image.total / $scope.ui.image.limit) >= 0.9
    # $scope.ui.dangerList.push '視頻' if ($scope.ui.video.total / $scope.ui.video.limit) >= 0.9

  do fetchRecord = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/material/official/searchLog?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.records = res.data.data
      $scope.ui.isLoading = false
      _.each $scope.ui.records, (record) ->
        record.created_at_text = CreateDateText.format record.created_at
        record.end_date_text = CreateDateText.format record.end_date
        record.type_name = switch record.type
          when 'image' then '圖片素材'
          when 'news' then '圖文素材'
          when 'video' then '視頻素材'
          else ''
        record.is_end = if record.is_end then "已完成" else "處理中"
        record.delete_num = if !record.is_end then "--" else record.delete_num

  ### 获取颜色 ###
  $scope.getColor = (type) ->
    _total = $scope.ui[type].total
    _limit = $scope.ui[type].limit

    if (_total / _limit) < 0.5
      return 'success'
    else if (_total / _limit) >= 0.5 && (_total / _limit) < 0.9
      return 'warning'
    else
      return 'danger'

  ### 操作 ###
  $scope.showModal = (category, type) ->
    dlg = dialogs.create('apps/material/manage/modal/modal', 'MaterialManageModalCtrl', {category: category, type: type}, {size: 'md'})
    dlg.result.then ((option) ->
      getData()
      fetchRecord()
    ), ->
