angular.module 'app'
.controller 'SingleArticleCtrl', ($scope, $state, $http, $q, Storage, FileUploader, $stateParams, ueditorConfig, config, SweetAlert, growl, dialogs) ->
  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')
    column: {}
    baseUrl: config.api.baseUrl
    article:
      group_id: Storage.get('login_user').group_id
      programa_id: $stateParams._id
      type: 'article'
      release_time: moment().format('YYYY-MM-DD')
      data:
        is_head: false
        cover_img: ''
        is_show: true
        content: ''
    opts:
      autoApply: true
      parentEl: 'div#view-fans-message'
      # minDate: moment().format('YYYY-MM-DD')
      opens: 'center'
      timePicker: false
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

    config:
      wordCount: true
      maximumWords: 20000
      wordCountMsg: '&nbsp;當前您已輸入&nbsp;{#count}&nbsp;個字符。'
      elementPathEnabled: false
      autoHeightEnabled: false
      autoFloatEnabled: false
      autoTransWordToList: true
      imagePopup: true
      insertorderedlist:
        'decimal': ''
        'lower-alpha': ''
        'lower-roman': ''
        'upper-alpha': ''
        'upper-roman': ''
      insertunorderedlist:
        circle: ''
        disc: ''
        square: ''
      lang: "zh-tw"
      initialFrameHeight: 300
      toolbars: ueditorConfig.ue.config.toolbars

  $scope.$on '$destroy', ->
    editor = UE.getEditor 'uecontainer'
    editor.destroy()

  ### 加载编辑器设置 ###
  _.defer ->
    uex('uecontainer', ->)

  $scope.startOpen = ($event) ->
    $event.preventDefault()
    $event.stopPropagation()
    $scope.ui.startOpened = !$scope.ui.startOpened

  do findColumn = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/#{$stateParams._id}", {}
    .then (res) ->
      $scope.ui.column = res.data
    .catch (error) ->

  do findData = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_content: true
        programa_id: $stateParams._id
        group_id: $scope.ui.user.group_id
        sort: 'sort'
    .then (res) ->
      if res.data.data.length > 0
        $scope.ui.article = res.data.data[0]
    .catch (error) ->
      growl.error '查詢錯誤!', {ttl: 2000}

  $scope.goBack = ->
    $state.go("app.column-manage", {})

  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    url: "#{config.api.baseUrl}/cumpus/upload/image?width=1024&height=683"
  )
  # FILTERS
  uploader.filters.push
    name: 'imageFilter'
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 0.5
        growl.error '文件大小超過限制!請選擇小於0.5M的圖片上傳。', {ttl: 3000}
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.article.data.cover_img = response.url

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error '上傳失敗', ttl: 2000

  $scope.previewQrcode = ->
    dlg = dialogs.create("apps/campus/column-manage/modal/preview-modal/preview-modal", "PreviewModalCtrl", {type: 'article', data: $scope.ui.article}, {size: 'lg'})
    dlg.result.then ((option) ->
    ), ->

  $scope.openPic = ->
    dlg = dialogs.create('apps/material/image/choose/images', 'ImagesChooseCtrl', {radio: true, type: 'graphic'}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        _.each materials, (valMaterial) ->
          ue.execCommand('inserthtml', "<img style='width:100%;max-width:100%;' src=" + $scope.ui.baseUrl + valMaterial.file.path + "></img>")
    ), ->

  # $scope.openVoice = ->
  #   modalInstance = $modal.open
  #     templateUrl: "modules/library/graphic/add/voice/voice"
  #     windowClass: "new-task-win"
  #     controller: "SelectVoiceCtrl"

  #   modalInstance.dismiss = (path) ->
  #     ue = UE.getEditor("container")
  #     _.defer ->
  #       ue.execCommand('inserthtml', "<p><audio style='width:100%;' src=" + path + " controls='controls'></audio><br/></p>")

  $scope.openVideo = ->
    dlg = dialogs.create('apps/material/video/choose/choose', 'VideoChooseCtrl', {type: 'graphic', radio: true}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        ue.execCommand('inserthtml', '<iframe class="video_iframe" data-vidtype="2" style="z-index:1;" height="375" width="100%" frameborder="0" src="' + materials[0].file.wechat_url + '" allowfullscreen=""></iframe>')
    ), ->

  $scope.save = ->
    if _.isUndefined $scope.ui.article._id
      $http.post "#{config.api.baseUrl}/cumpus/programa/list", $scope.ui.article
      .then (res) ->
        $scope.ui.article = res.data
        growl.success '保存成功！', ttl: 2000
      .catch (error) ->
        growl.error '保存失敗！', ttl: 2000
    else
      $http.put "#{config.api.baseUrl}/cumpus/programa/list/#{$scope.ui.article._id}", $scope.ui.article
      .then (res) ->
        growl.success '保存成功！', ttl: 2000
      .catch (error) ->
        growl.error '保存失敗！', ttl: 2000