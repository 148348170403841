angular.module 'app'
.controller 'UserPasswordCtrl', ($uibModalInstance, config, $scope, $stateParams, growl, $http, data, Storage) ->
  $scope.ui =
    user: Storage.get('login_user')
    password: ''
    eadminDisplay: config.eadmin.display

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.confirm = ->
    return growl.error "兩次密碼輸入不一致" if $scope.ui.user.password != $scope.ui.password
    if $scope.ui.eadminDisplay == true
      $http.post "#{config.eadmin.newBaseUrl}/account/update_me?access_token=" + Storage.get('access_token'),
        password: $scope.ui.password
      .then (res) ->
        growl.success "保存成功！"
        $uibModalInstance.close 'Save'
      .catch (error) ->
        growl.error "保存失敗！"
    else
      $http.put "#{config.eadmin.baseUrl}/account/groups/#{$scope.ui.user.group_id}/users/#{$scope.ui.user.id}?access_token=" + Storage.get('access_token'), $scope.ui.user
      .then (res) ->
        growl.success "保存成功！"
        $uibModalInstance.close 'Save'
      .catch (error) ->
        growl.error "保存失敗！"
