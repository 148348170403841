angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.card-coupons',
      url: '/card-coupons'
      templateUrl: 'apps/card-coupons/layout/layout'
      controller: 'CardCouponsLayoutCtrl'

    # 基本信息
    .state 'app.card-coupons.basic-information',
      url: '/basic-information'
      templateUrl: 'apps/card-coupons/basic-information/basic-information'
      controller: 'CardCouponsBasicInformationCtrl'
