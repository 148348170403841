angular.module 'app'
.controller 'AddSynonymCtrl', ($scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data) ->

  $scope.ui =
    appmsg: {}

  do findData = ->
    if data.appmsg._id
      $scope.ui.appmsg.synonyms = data.appmsg.synonym
      $scope.ui.appmsg._id = data.appmsg._id
    else
      $scope.ui.appmsg.synonyms = ''
      $scope.ui.appmsg._id = ''

  $scope.SynonymMatchKeyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      if !_.contains $scope.ui.appmsg.synonyms, $scope.ui.appmsg.synonym
        return growl.error '請輸入內容' if !$scope.ui.appmsg.synonym
        $scope.ui.appmsg.synonyms = [] if !$scope.ui.appmsg.synonyms
        $scope.ui.appmsg.synonyms.push $scope.ui.appmsg.synonym
        $scope.ui.appmsg.synonym = ''
      else
        growl.error "同義詞已存在"

  # 删除已选同義詞
  $scope.deleteWord = (type, _index) ->
    $scope.ui.appmsg.synonyms.splice _index, 1 if type == 'synonym'

  $scope.save = ->
    return growl.error "請輸入同義詞" if $scope.ui.appmsg.synonyms.length == 0
    if data.appmsg._id
      $http.put "#{config.api.baseUrl}/dsi/synonym/#{data.appmsg._id}?access_token=" + Storage.get('access_token'),
        synonym: $scope.ui.appmsg.synonyms
      .then (res) ->
        growl.success '修改成功'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    else
      $http.post "#{config.api.baseUrl}/dsi/synonym?access_token=" + Storage.get('access_token'),
        synonym: $scope.ui.appmsg.synonyms
      .then (res) ->
        growl.success '創建成功'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'