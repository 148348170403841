angular.module 'app'
.controller 'ClearImgCtrl', ($scope, $http, $state, $stateParams, $uibModalInstance, config, Storage, growl, data) ->
  $scope.ui =
    date: ''
    opts:
      autoApply: true
      parentEl: 'div#material-clear-img'
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']


  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $uibModalInstance.close $scope.ui.date
