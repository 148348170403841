angular.module 'app'
.controller 'CemMakingCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert) ->

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    cemListData: []


  findDatas = ->
    $http.get "#{config.api.baseUrl}/cem/pbc?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
      $scope.ui.totalItems = res.data.count
      $scope.ui.cemListData = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # # 修改圖文
  $scope.editCem = (cemdata) ->
    dialogs.create('apps/cem/addCem/addCem', 'AddCemVideoCtrl', {cemdata: cemdata, title: 'edit'}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      findDatas()
    ), ->

  #  刪除
  $scope.delCem = (cemdata) ->
    SweetAlert.swal {
      title: "你確定嗎？"
      text: "是否確定刪除此視頻？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cem/pbc/#{cemdata._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findDatas()
          growl.success "刪除成功"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  ## 添加圖文
  $scope.addCem = ->
    dialogs.create('apps/cem/addCem/addCem', 'AddCemVideoCtrl', {title: 'add'}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      findDatas()
    ), ->

  $scope.toUp = (index,id) ->
    return growl.error '已是第一位' if index is 0
    $http.post "#{config.api.baseUrl}/cem/pbc/sort?access_token=" + Storage.get('access_token'),
      _id: id
      state: 'up'
      type: 'sort'
    .then (res) ->
      growl.success '排序成功'
      findDatas()
    .catch (res) ->
      growl.error '排序失敗'

  $scope.toDown = (index,id) ->
    return growl.error '已是最後一位' if (index + 1) is $scope.ui.totalItems
    $http.post "#{config.api.baseUrl}/cem/pbc/sort?access_token=" + Storage.get('access_token'),
      _id: id
      state: 'down'
      type: 'sort'
    .then (res) ->
      growl.success '排序成功'
      findDatas()
    .catch (res) ->
      growl.error '排序失敗'

  $scope.top = (data) ->
    $http.post "#{config.api.baseUrl}/cem/pbc/sort?access_token=" + Storage.get('access_token'),
      _id: data._id
      type: 'top'
    .then (res) ->
      growl.success '置頂成功'
      findDatas()
    .catch (res) ->
      growl.error '置頂失敗'

  $scope.playVideo = (linkdata) ->
    dialogs.create('apps/cem/play-video/play-video', 'PlayVideoCtrl', {link: linkdata.link}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
    ), ->

  $scope.$watch 'ui.currentPage', findDatas
