angular.module 'app'
.controller 'MassInformationCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert, $timeout) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.comment.is_use == false

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    totalItems: 0
    currentPage: 1
    perPage: 7
    maxSize: 8
    dataArr: []
    isLoading: false
    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#mass-information'
  $scope.ui.opts.opens = 'right'

  $scope.findData = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/send?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        type: 'news'
        status: 'issue'
        user_id: $scope.ui.user.id
        show_comment_count: true
        start_release_time: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_release_time: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        data.send_time = moment(data.send_time).format('YYYY-MM-DD HH:mm:ss')
        data.typeText = '圖文' if data.type == 'news'

        if data.content != 'null'
          data.content = JSON.parse(data.content) if data.type != 'text'

        _.each data.content.articles, (article, i) ->
          article.openShow = if _.contains(data.open_comment_indexs, i) then false else true

      $scope.ui.dataArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查看
  $scope.toView = (id,data,index) ->
    dialogs.create('apps/message-management/find-information/find-information', 'FindInformationCtrl', {_id: id,articledata: data,index: index}, {size: 'lg'})
    .result.then ((option) ->
     ), ->

  # 開啟/關閉圖文評論
  $scope.openMessage = (status,messageid,data,statusValue,index) ->
    data.openShow = status
    $scope.ui.dataArr = angular.copy $scope.ui.dataArr
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/comment/status?access_token=" + Storage.get('access_token'),
        mass_send_id: messageid
        index: index
        status: statusValue
    .then (res) ->
      growl.success "開啟圖文評論成功！" if statusValue == 'open'
      growl.success "關閉圖文評論成功！" if statusValue == 'close'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.dataArr = []
      $scope.ui.currentPage = 1
      $scope.findData()


  $scope.$watch 'ui.currentPage', $scope.findData
