angular.module 'app'
.controller 'AddCemVideoCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, FileUploader,ueditorConfig, $uibModalInstance, data) ->

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    isLoading: false
    title: data.title
    activeArticle:
      description: ''
      link: ''
      thumb_url: ''


  #傳圖片
  uploaderImage = $scope.uploaderImage = new FileUploader(
    autoUpload: true
    removeAfterUpload: true
    url: "#{config.api.baseUrl}/cem/pbc/upload?access_token=#{Storage.get('access_token')}"
  )

  uploaderImage.onBeforeUploadItem = (item) ->

  uploaderImage.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.activeArticle.thumb_url = response.path
    growl.success("上傳成功")


  uploaderImage.onErrorItem = (fileItem, response, status, headers) ->
    growl.error("上傳失敗")

  ### 初始化 ###
  if data.cemdata
    $scope.ui.activeArticle.description = data.cemdata.description
    $scope.ui.activeArticle.link = data.cemdata.link
    $scope.ui.activeArticle.thumb_url = data.cemdata.cover


  #保存
  $scope.save = ->
    return growl.error('封面不能為空') if $scope.ui.activeArticle.thumb_url == ''
    return growl.error('視頻鏈接不能為空') if $scope.ui.activeArticle.link == ''
    if data.cemdata
      obj =
        description: $scope.ui.activeArticle.description
        cover: $scope.ui.activeArticle.thumb_url
        link: $scope.ui.activeArticle.link
      $http.put "#{config.api.baseUrl}/cem/pbc/#{data.cemdata._id}?access_token=" + Storage.get('access_token'), obj
      .then (res) ->
        growl.success "保存成功"
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    else
      obj =
        description: $scope.ui.activeArticle.description
        cover: $scope.ui.activeArticle.thumb_url
        link: $scope.ui.activeArticle.link
      $http.post "#{config.api.baseUrl}/cem/pbc/?access_token=" + Storage.get('access_token'), obj
      .then (res) ->
        growl.success "保存成功"
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')


  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
