angular.module 'app'
.controller 'FansModalCtrl', ($uibModalInstance, $scope, $http, $stateParams, $state, config, Storage, growl, data) ->
  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl

    name: data.name

    # tagData: []
    # activeTag: ''

    sex: ''

    countryData: []
    activeCountry: ''
    activeProvince: ''
    activeCity: ''

    languageData: []
    language: ''

    followType: ''

    compare: true
    dayNumber: ''

    remark: ''

    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment().subtract(1, 'days')

    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#user-grow'
  $scope.ui.opts.opens = 'right'

  # 获取条件
  getMatchrule = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/attribute?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $scope.ui.countryData = res.data.country.Val
      # $scope.ui.tagData = res.data.tag.Val
      $scope.ui.languageData = res.data.language.Val
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    _country = if $scope.ui.activeCountry is '' then '' else $scope.ui.countryData[$scope.ui.activeCountry].key
    _province = if $scope.ui.activeCountry is '' or $scope.ui.activeProvince is ''  then '' else $scope.ui.countryData[$scope.ui.activeCountry].value[$scope.ui.activeProvince].key
    _city = if $scope.ui.activeCountry is '' or $scope.ui.activeProvince is '' or $scope.ui.activeCity is '' then '' else $scope.ui.countryData[$scope.ui.activeCountry].value[$scope.ui.activeProvince].value[$scope.ui.activeCity]

    $uibModalInstance.close {
      sex: $scope.ui.sex
      language: $scope.ui.language
      country: _country
      province: _province
      city: _city
      compare: if $scope.ui.followType is '1' and $scope.ui.compare is true then '>=' else if $scope.ui.followType is '1' and $scope.ui.compare is false then '<=' else ''
      dayNumber: if $scope.ui.followType is '1' then $scope.ui.dayNumber else ''
      remark: $scope.ui.remark
      start_subscribe_time: if $scope.ui.followType is '0' then moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00') else ''
      end_subscribe_time: if $scope.ui.followType is '0' then moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59') else ''
    }

  #
  $scope.$watch 'ui.activeCountry', (newValue, oldValue) ->
    return if newValue == oldValue
    $scope.ui.activeProvince = ''
    $scope.ui.activeCity = ''

  #
  $scope.$watch 'ui.activeProvince', (newValue, oldValue) ->
    return if newValue == oldValue
    $scope.ui.activeCity = ''

  getMatchrule()
