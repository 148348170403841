angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.user-management',
      url: '/user-management'
      templateUrl: 'apps/user/layout/layout'
      controller: 'UserLayoutCtrl'

    .state 'app.user-management.member',
      url: '/member'
      templateUrl: 'apps/user/member/member'
      controller: 'MemberListCtrl'

    .state 'app.user-management.role',
      url: '/role'
      templateUrl: 'apps/user/role/role'
      controller: 'UserRoleCtrl'

    .state 'app.user-management.group',
      url: '/group'
      templateUrl: 'apps/user/group/group'
      controller: 'UserGroupCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'lightgallery/dist/css/lightbox.css'
              ]
          ])

    .state 'app.log',
      url: '/log'
      templateUrl: 'apps/user/log/log'
      controller: 'UserLogCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    .state 'app.wechat-log',
      url: '/wechat-log'
      templateUrl: 'apps/user/wechat-log/wechat-log'
      controller: 'WechatLogCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
