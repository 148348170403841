angular.module 'app'
.controller 'UserRoleCtrl', ($scope, $stateParams, $rootScope, growl, config, $http, Storage) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').system.user.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    product_id: config.eadmin.clientId
    eadminDisplay: config.eadmin.display

    roles: []
    radioModel: -1
    activeRole: {}
    campus: {
      head:
        disabled: false
      websiteSet:
        disabled: false
      columnManage:
        disabled: false
      schoolInformation:
        disabled: false
    }
    smartService: {
      disabled: false
    }

  findRole = ->
    if $scope.ui.eadminDisplay == true
      roles = """
        query(
          $query: RoleQueryInput
        ){
          role(
            query: $query
          ){
            count
            data{
              id
              groupId
              productId
              name
              groupName
              productName
              data
              createdAt
              updatedBy
            }
          }
        }
      """
      variables =
        query:
          groupId: String($scope.ui.user.group_id)
          productId: String($scope.ui.product_id)
      $http.post "#{config.eadmin.newBaseUrl}/graphql?access_token=" + Storage.get('access_token'),
        query: roles
        variables: variables
      .then (res) ->
        _.each res.data.data.role.data, (data) ->
          data.data.interaction.smartService = $scope.ui.smartService if data.data.interaction.smartService == undefined
          data.data.campus = $scope.ui.campus if data.data.campus == undefined
        $scope.ui.roles = res.data.data.role.data
        _.each $scope.ui.roles, (val, i) ->
          val.permission_obj = val.data
        if $scope.ui.roles.length > 0
          $scope.ui.radioModel = 0
          $scope.ui.activeRole = $scope.ui.roles[0]
      .catch (error) ->
        growl.error('網絡連接失敗，請稍後重試')
    else
      $http.get "#{config.eadmin.baseUrl}/account/groups/#{$scope.ui.user.group_id}/roles?access_token=" + Storage.get('access_token') + "&product_id=#{$scope.ui.product_id}",{}
      .then (res) ->
        $scope.ui.roles = res.data.data
        _.each $scope.ui.roles, (role, i) ->
          role.permission_obj = eval("(" + JSON.parse JSON.stringify role.permission + ")")
        if $scope.ui.roles.length > 0
          $scope.ui.radioModel = 0
          $scope.ui.activeRole = $scope.ui.roles[0]

  $scope.changeType = ->
    $scope.ui.activeRole = $scope.ui.roles[$scope.ui.radioModel]

  $scope.save = ->
    if $scope.ui.eadminDisplay == true
      upsertRoles = """
        mutation(
          $data: [RoleInput]
        ){
          upsertRole(
            data: $data
          ){
            id
            groupId
            productId
            name
            groupName
            productName
            data
            createdAt
            updatedAt
            updatedBy
          }
        }
      """
      variables =
        data:
          id: $scope.ui.activeRole.id
          groupId: String($scope.ui.user.group_id)
          productId: String($scope.ui.product_id)
          data: $scope.ui.activeRole.data

      $http.post "#{config.eadmin.newBaseUrl}/graphql?access_token=" + Storage.get('access_token'),
        query: upsertRoles
        variables: variables
      .then (res) ->
        $scope.$emit "sidebarWechat"
        $scope.$emit "headerWechat"
        growl.success "修改成功"
      .catch (error) ->
        growl.error "修改失敗！"
    else
      $scope.ui.activeRole.permission = JSON.stringify $scope.ui.activeRole.permission_obj
      $http.put "#{config.eadmin.baseUrl}/account/groups/#{$scope.ui.user.group_id}/roles/#{$scope.ui.activeRole.id}?access_token=" + Storage.get('access_token'), $scope.ui.activeRole
      .then (res) ->
        $scope.$emit "sidebarWechat"
        $scope.$emit "headerWechat"
        growl.success "修改成功"
      .catch (error) ->
        growl.error "修改失敗！"

  # --- 系统管理 ---
  $scope.systemChange = ->
    flag = $scope.ui.activeRole.permission_obj.system.head.is_use

    if $scope.ui.activeRole.permission_obj.system.wechat.disabled isnt true
      $scope.ui.activeRole.permission_obj.system.wechat.is_use = flag

    if $scope.ui.activeRole.permission_obj.system.user.disabled isnt true
      $scope.ui.activeRole.permission_obj.system.user.is_use = flag

    if $scope.ui.activeRole.permission_obj.system.log.disabled isnt true
      $scope.ui.activeRole.permission_obj.system.log.is_use = flag

  $scope.systemSelect = ->
    wechatSel = $scope.ui.activeRole.permission_obj.system.wechat.is_use
    userSel = $scope.ui.activeRole.permission_obj.system.user.is_use
    logSel = $scope.ui.activeRole.permission_obj.system.log.is_use

    if wechatSel is false && userSel is false && logSel is false
      $scope.ui.activeRole.permission_obj.system.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.system.head.is_use = true


  # --- 管理 ---
  $scope.manageChange = ->
    flag = $scope.ui.activeRole.permission_obj.manage.head.is_use

    if $scope.ui.activeRole.permission_obj.manage.fans.disabled isnt true
      $scope.ui.activeRole.permission_obj.manage.fans.is_use = flag

    if $scope.ui.activeRole.permission_obj.manage.material.disabled isnt true
      $scope.ui.activeRole.permission_obj.manage.material.is_use = flag

    if $scope.ui.activeRole.permission_obj.manage.materialTemplate.disabled isnt true
      $scope.ui.activeRole.permission_obj.manage.materialTemplate.is_use = flag

    if $scope.ui.activeRole.permission_obj.manage.template.head.disabled isnt true
      $scope.ui.activeRole.permission_obj.manage.template.head.is_use = flag

      if $scope.ui.activeRole.permission_obj.manage.template.templateLibrary.disabled isnt true
        $scope.ui.activeRole.permission_obj.manage.template.templateLibrary.is_use = flag

      if $scope.ui.activeRole.permission_obj.manage.template.templateUesr.disabled isnt true
        $scope.ui.activeRole.permission_obj.manage.template.templateUesr.is_use = flag

    if $scope.ui.activeRole.permission_obj.manage.mobile.disabled isnt true
      $scope.ui.activeRole.permission_obj.manage.mobile.is_use = flag

  $scope.manageSelect = (type) ->
    fansSel = $scope.ui.activeRole.permission_obj.manage.fans.is_use
    materialSel = $scope.ui.activeRole.permission_obj.manage.material.is_use
    materialTemplateSel = $scope.ui.activeRole.permission_obj.manage.materialTemplate.is_use
    templateSel = $scope.ui.activeRole.permission_obj.manage.template.head.is_use
    mobileSel = $scope.ui.activeRole.permission_obj.manage.mobile.is_use

    if fansSel is false && materialSel is false && materialTemplateSel is false && templateSel is false && mobileSel is false
      $scope.ui.activeRole.permission_obj.manage.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.manage.head.is_use = true

    if type is 'template' && $scope.ui.activeRole.permission_obj.manage.template.head.disabled isnt true
      flag = $scope.ui.activeRole.permission_obj.manage.template.head.is_use

      if $scope.ui.activeRole.permission_obj.manage.template.templateLibrary.disabled isnt true
        $scope.ui.activeRole.permission_obj.manage.template.templateLibrary.is_use = flag

      if $scope.ui.activeRole.permission_obj.manage.template.templateUesr.disabled isnt true
        $scope.ui.activeRole.permission_obj.manage.template.templateUesr.is_use = flag

  $scope.manageTemplateSelect = ->
    librarySel = $scope.ui.activeRole.permission_obj.manage.template.templateLibrary.is_use
    uesrSel = $scope.ui.activeRole.permission_obj.manage.template.templateUesr.is_use

    if librarySel is false && uesrSel is false
      $scope.ui.activeRole.permission_obj.manage.template.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.manage.template.head.is_use = true

    fansSel = $scope.ui.activeRole.permission_obj.manage.fans.is_use
    materialSel = $scope.ui.activeRole.permission_obj.manage.material.is_use
    templateSel = $scope.ui.activeRole.permission_obj.manage.template.head.is_use
    mobileSel = $scope.ui.activeRole.permission_obj.manage.mobile.is_use

    if fansSel is false && materialSel is false && templateSel is false && mobileSel is false
      $scope.ui.activeRole.permission_obj.manage.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.manage.head.is_use = true


  # --- 发布 ---
  $scope.issueChange = ->
    flag = $scope.ui.activeRole.permission_obj.issue.head.is_use

    if $scope.ui.activeRole.permission_obj.issue.information.head.disabled isnt true
      $scope.ui.activeRole.permission_obj.issue.information.head.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.information.informationNew.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.information.informationNew.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.information.informationExamine.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.information.informationExamine.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.information.informationRecord.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.information.informationRecord.is_use = flag

    if $scope.ui.activeRole.permission_obj.issue.template.head.disabled isnt true
      $scope.ui.activeRole.permission_obj.issue.template.head.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.template.templateMsg.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.template.templateMsg.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.template.templateExamine.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.template.templateExamine.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.template.templateRecord.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.template.templateRecord.is_use = flag

  $scope.issueSelect = (type) ->
    informationSel = $scope.ui.activeRole.permission_obj.issue.information.head.is_use
    templateSel = $scope.ui.activeRole.permission_obj.issue.template.head.is_use

    if informationSel is false && templateSel is false
      $scope.ui.activeRole.permission_obj.issue.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.issue.head.is_use = true

    if type is 'information' && $scope.ui.activeRole.permission_obj.issue.information.head.disabled isnt true
      flag = $scope.ui.activeRole.permission_obj.issue.information.head.is_use

      if $scope.ui.activeRole.permission_obj.issue.information.informationNew.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.information.informationNew.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.information.informationExamine.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.information.informationExamine.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.information.informationRecord.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.information.informationRecord.is_use = flag

    if type is 'template' && $scope.ui.activeRole.permission_obj.issue.template.head.disabled isnt true
      flag = $scope.ui.activeRole.permission_obj.issue.template.head.is_use

      if $scope.ui.activeRole.permission_obj.issue.template.templateMsg.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.template.templateMsg.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.template.templateExamine.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.template.templateExamine.is_use = flag

      if $scope.ui.activeRole.permission_obj.issue.template.templateRecord.disabled isnt true
        $scope.ui.activeRole.permission_obj.issue.template.templateRecord.is_use = flag

  $scope.issueInformationSelect = ->
    newSel = $scope.ui.activeRole.permission_obj.issue.information.informationNew.is_use
    examineSel = $scope.ui.activeRole.permission_obj.issue.information.informationExamine.is_use
    recordSel = $scope.ui.activeRole.permission_obj.issue.information.informationRecord.is_use

    if newSel is false && examineSel is false && recordSel is false
      $scope.ui.activeRole.permission_obj.issue.information.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.issue.information.head.is_use = true

    informationSel = $scope.ui.activeRole.permission_obj.issue.information.head.is_use
    templateSel = $scope.ui.activeRole.permission_obj.issue.template.head.is_use

    if informationSel is false && templateSel is false
      $scope.ui.activeRole.permission_obj.issue.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.issue.head.is_use = true

  $scope.issueTemplateSelect = ->
    msgSel = $scope.ui.activeRole.permission_obj.issue.template.templateMsg.is_use
    examineSel = $scope.ui.activeRole.permission_obj.issue.template.templateExamine.is_use
    recordSel = $scope.ui.activeRole.permission_obj.issue.template.templateRecord.is_use

    if msgSel is false && examineSel is false && recordSel is false
      $scope.ui.activeRole.permission_obj.issue.template.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.issue.template.head.is_use = true

    informationSel = $scope.ui.activeRole.permission_obj.issue.information.head.is_use
    templateSel = $scope.ui.activeRole.permission_obj.issue.template.head.is_use

    if informationSel is false && templateSel is false
      $scope.ui.activeRole.permission_obj.issue.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.issue.head.is_use = true

  $scope.issuePageTemplateSelect = ->
    pageTemplateSel = $scope.ui.activeRole.permission_obj.issue.pageTemplate.is_use

    informationSel = $scope.ui.activeRole.permission_obj.issue.information.head.is_use
    templateSel = $scope.ui.activeRole.permission_obj.issue.template.head.is_use

    if pageTemplateSel is false && informationSel is false && templateSel is false
      $scope.ui.activeRole.permission_obj.issue.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.issue.head.is_use = true

  # --- 互动 ---
  $scope.interactionChange = ->
    flag = $scope.ui.activeRole.permission_obj.interaction.head.is_use

    if $scope.ui.activeRole.permission_obj.interaction.message.disabled isnt true
      $scope.ui.activeRole.permission_obj.interaction.message.is_use = flag

    if $scope.ui.activeRole.permission_obj.interaction.response.disabled isnt true
      $scope.ui.activeRole.permission_obj.interaction.response.is_use = flag

    if $scope.ui.activeRole.permission_obj.interaction.smartService.disabled isnt true
      $scope.ui.activeRole.permission_obj.interaction.smartService.is_use = flag

    if $scope.ui.activeRole.permission_obj.interaction.menu.disabled isnt true
      $scope.ui.activeRole.permission_obj.interaction.menu.is_use = flag

    if $scope.ui.activeRole.permission_obj.interaction.qrcode.disabled isnt true
      $scope.ui.activeRole.permission_obj.interaction.qrcode.is_use = flag

  $scope.interactionSelect = ->
    messageSel = $scope.ui.activeRole.permission_obj.interaction.message.is_use
    responseSel = $scope.ui.activeRole.permission_obj.interaction.response.is_use
    smartServiceSel = $scope.ui.activeRole.permission_obj.interaction.smartService.is_use
    menuSel = $scope.ui.activeRole.permission_obj.interaction.menu.is_use
    qrcodeSel = $scope.ui.activeRole.permission_obj.interaction.qrcode.is_use

    if messageSel is false && responseSel is false && smartServiceSel is false && menuSel is false && qrcodeSel is false
      $scope.ui.activeRole.permission_obj.interaction.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.interaction.head.is_use = true


  # --- 诊断 ---
  $scope.analysisChange = ->
    flag = $scope.ui.activeRole.permission_obj.analysis.head.is_use

    if $scope.ui.activeRole.permission_obj.analysis.fans.disabled isnt true
      $scope.ui.activeRole.permission_obj.analysis.fans.is_use = flag

    if $scope.ui.activeRole.permission_obj.analysis.graphic.disabled isnt true
      $scope.ui.activeRole.permission_obj.analysis.graphic.is_use = flag

    if $scope.ui.activeRole.permission_obj.analysis.menu.disabled isnt true
      $scope.ui.activeRole.permission_obj.analysis.menu.is_use = flag

    if $scope.ui.activeRole.permission_obj.analysis.news.disabled isnt true
      $scope.ui.activeRole.permission_obj.analysis.news.is_use = flag

  $scope.analysisSelect = ->
    fansSel = $scope.ui.activeRole.permission_obj.analysis.fans.is_use
    graphicSel = $scope.ui.activeRole.permission_obj.analysis.graphic.is_use
    menuSel = $scope.ui.activeRole.permission_obj.analysis.menu.is_use
    newsSel = $scope.ui.activeRole.permission_obj.analysis.news.is_use

    if fansSel is false && graphicSel is false && menuSel is false && newsSel is false
      $scope.ui.activeRole.permission_obj.analysis.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.analysis.head.is_use = true


  # --- 监测 ---
  $scope.monitorChange = ->
    flag = $scope.ui.activeRole.permission_obj.monitor.head.is_use

    if $scope.ui.activeRole.permission_obj.monitor.wechat.disabled isnt true
      $scope.ui.activeRole.permission_obj.monitor.wechat.is_use = flag

    if $scope.ui.activeRole.permission_obj.monitor.top.disabled isnt true
      $scope.ui.activeRole.permission_obj.monitor.top.is_use = flag

    if $scope.ui.activeRole.permission_obj.monitor.article.disabled isnt true
      $scope.ui.activeRole.permission_obj.monitor.article.is_use = flag

  $scope.monitorSelect = ->
    wechatSel = $scope.ui.activeRole.permission_obj.monitor.wechat.is_use
    topSel = $scope.ui.activeRole.permission_obj.monitor.top.is_use
    articleSel = $scope.ui.activeRole.permission_obj.monitor.article.is_use

    if wechatSel is false && topSel is false && articleSel is false
      $scope.ui.activeRole.permission_obj.monitor.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.monitor.head.is_use = true

  $scope.campusChange = ->
    flag = $scope.ui.activeRole.permission_obj.campus.head.is_use
    if $scope.ui.activeRole.permission_obj.campus.websiteSet.disabled isnt true
      $scope.ui.activeRole.permission_obj.campus.websiteSet.is_use = flag
    if $scope.ui.activeRole.permission_obj.campus.columnManage.disabled isnt true
      $scope.ui.activeRole.permission_obj.campus.columnManage.is_use = flag
    if $scope.ui.activeRole.permission_obj.campus.schoolInformation.disabled isnt true
      $scope.ui.activeRole.permission_obj.campus.schoolInformation.is_use = flag

  $scope.campusSelect = ->
    campusSel = $scope.ui.activeRole.permission_obj.campus.websiteSet.is_use
    columnManageSel = $scope.ui.activeRole.permission_obj.campus.columnManage.is_use
    schoolInformationSel = $scope.ui.activeRole.permission_obj.campus.schoolInformation.is_use

    if campusSel is false && columnManageSel is false && schoolInformationSel is false
      $scope.ui.activeRole.permission_obj.campus.head.is_use = false
    else
      $scope.ui.activeRole.permission_obj.campus.head.is_use = true

  findRole()
