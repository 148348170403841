angular.module 'app'
.controller 'WechatDetailCtrl', ($scope, $http, $state, $uibModalInstance, config, Storage, FileUploader, growl, data, dialogs) ->
  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    wechat:
      appid: null
      appsecret: null
      headimgurl: null
      name: null
      original_id: null
      type: '0'
      version: '0'
      weixin_id: null
      user_group_id: Storage.get('login_user').group_id
    isDisableSave: false

  if data.wechat
    $scope.ui.wechat = angular.copy data.wechat


  $scope.cancel = ->
    $uibModalInstance.dismiss 'Canceled'

  $scope.save = ->
    $scope.ui.isDisableSave = true
    if _.isUndefined $scope.ui.wechat._id
      $http.post "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'), $scope.ui.wechat
      .then (res) ->
        $uibModalInstance.close 'Save'
        growl.success "保存成功！"
        $scope.ui.isDisableSave = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.isDisableSave = false

    else
      $http.put "#{config.api.baseUrl}/wechat/#{$scope.ui.wechat._id}?access_token=" + Storage.get('access_token'), $scope.ui.wechat
      .then (res) ->
        $uibModalInstance.close 'Save'
        growl.success "保存成功！"
        $scope.ui.isDisableSave = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.isDisableSave = false


  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    url: config.api.baseUrl + "/upload?access_token=" + Storage.get('access_token')
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 1
        growl.error("圖片文件不能大於1MB!", {referenceId: 1})
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.wechat.headimgurl = response.url_path
    growl.success '上傳成功'
    uploader.clearQueue()

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error("上傳圖片失敗!", {referenceId: 1})
    uploader.clearQueue()
