angular.module 'app'
.controller 'EditLinkManageCtrl', ($uibModalInstance, $scope, Storage, $state, $http, $stateParams, ueditorConfig, config, growl, FileUploader, dialogs, data) ->

  $scope.ui =
    user: Storage.get('login_user')
    baseUrl: config.api.baseUrl
    resourcesUrl: config.resources.baseUrl

    data: data
    column: {}
    article:
      group_id: Storage.get('login_user').group_id
      programa_id: data._id
      type: 'link'
      release_time: moment().format('YYYY-MM-DD')
      data:
        is_head: false
        cover_img: ''
        is_show: true
        content: ''

    opts:
      autoApply: true
      parentEl: 'div#view-fans-message'
      # minDate: moment().format('YYYY-MM-DD')
      opens: 'center'
      timePicker: false
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

  if $scope.ui.data.type == 'edit'
    $scope.ui.article = data.link

  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    url: "#{config.api.baseUrl}/cumpus/upload/image?width=1024&height=683"
  )
  # FILTERS
  uploader.filters.push
    name: 'imageFilter'
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 0.5
        growl.error '文件大小超過限制!請選擇小於0.5M的圖片上傳。'
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.article.data.cover_img = response.url

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error '上傳失敗'

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    if $scope.ui.data.type == 'add'
      $http.post "#{config.api.baseUrl}/cumpus/programa/list", $scope.ui.article
      .then (res) ->
        growl.success '保存成功'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        growl.error '保存失敗'
    else
      $http.put "#{config.api.baseUrl}/cumpus/programa/list/#{$scope.ui.article._id}", $scope.ui.article
      .then (res) ->
        growl.success '保存成功'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        growl.error '保存失敗'
