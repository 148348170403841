angular.module 'app'
.controller 'FansRankingCtrl', ($scope, $http, $stateParams, $state, config, Storage, growl, $timeout, dialogs, $rootScope) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.news.is_use == false

  $scope.ui =
    msg_rank: []
    word_rank: []

    index: '10'

    ungroups: [
      name: '全部'
      tag_id: 'all'
    ]

    tagsPopoverDisabled: false
    tagsPopoverDisabled2: false

    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()

    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#word-cloud'
  $scope.ui.opts.opens = 'right'

  fetchFans = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/follower_ranking?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.index
        start_created_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
        trim: 0
    .then (res) ->
      $scope.ui.msg_rank = res.data.MsgCountRank.data
      $scope.ui.word_rank = res.data.MsgWordRank.data
      _.each $scope.ui.msg_rank, (val) ->
        val.created_at = moment(val.created_at).format('YYYY-MM-DD')
      _.each $scope.ui.word_rank, (val) ->
        val.created_at = moment(val.created_at).format('YYYY-MM-DD')
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.tagsPopover.groups = []
      $scope.tagsPopover.groups = _.union($scope.ui.ungroups, res.data.data)
      $scope.tagsPopover2.groups = []
      $scope.tagsPopover2.groups = _.union($scope.ui.ungroups, res.data.data)
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 打標簽
  $scope.tagsPopover =
    title: "打標籤"
    templateUrl: 'tagsPopoverTemplate.html'
    isOpen: false
    open: ->
      $scope.tagsPopover.isOpen = true
      $scope.ui.tagsPopoverDisabled = false
    close: ->
      $scope.tagsPopover.isOpen = false
      $scope.ui.tagsPopoverDisabled = false
    save: ->
      openid_list = []
      _.each $scope.ui.msg_rank, (data) ->
        openid_list.push(data.openid)

      tagid_list = []
      _.each $scope.tagsPopover.groups, (group) ->
        if group.isChecked is true
          tagid_list.push parseInt(group.tag_id)

      return growl.error "請至少選擇一個標籤" if tagid_list.length == 0
      return growl.error "最多可選三個標籤" if tagid_list.length > 3

      $scope.ui.tagsPopoverDisabled = true

      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/members_tag?access_token=" + Storage.get('access_token'),
        openid_list: openid_list
        tagid_list: tagid_list
      .then (res) ->
        $scope.tagsPopover.isOpen = false
        growl.success "打標籤成功！"
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.tagsPopoverDisabled = false

  # 打標簽
  $scope.tagsPopover2 =
    title: "打標籤"
    templateUrl: 'tagsPopoverTemplate2.html'
    isOpen: false
    open: ->
      $scope.tagsPopover2.isOpen = true
      $scope.ui.tagsPopoverDisabled2 = false
    close: ->
      $scope.tagsPopover2.isOpen = false
      $scope.ui.tagsPopoverDisabled2 = false
    save: ->
      openid_list = []
      _.each $scope.ui.word_rank, (data) ->
        openid_list.push(data.openid)

      tagid_list = []
      _.each $scope.tagsPopover2.groups, (group) ->
        if group.isChecked is true
          tagid_list.push parseInt(group.tag_id)

      return growl.error "請至少選擇一個標籤" if tagid_list.length == 0
      return growl.error "最多可選三個標籤" if tagid_list.length > 3

      $scope.ui.tagsPopoverDisabled2 = true
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/members_tag?access_token=" + Storage.get('access_token'),
        openid_list: openid_list
        tagid_list: tagid_list
      .then (res) ->
        $scope.tagsPopover2.isOpen = false
        growl.success "打標籤成功！"
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.tagsPopoverDisabled2 = false


  $scope.$watch 'ui.date', (newDate) ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')

    fetchFans()

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadOne = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/fol_rank_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        limit: $scope.ui.index
        type: 'MsgCount'
        start_created_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.downloadTwo = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/fol_rank_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        limit: $scope.ui.index
        type: 'WordCount'
        start_created_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.fansClick = (id) ->
    $state.go 'app.msg-manage', {id: id}
