angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.menumanagement',
      url: '/menu-management'
      templateUrl: 'apps/features/layout/layout'
      controller: 'MenuManagementCtrl'

    # 自定義菜單
    .state 'app.menumanagement.wechat-menu',
      url: '/wechat-menu'
      templateUrl: 'apps/features/wechat-menu/wechat-menu'
      controller: 'WechatMenuCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'lightgallery/dist/css/lightbox.css'
              ]
          ])

    .state 'app.menumanagement.personalized-menu',
      url: '/personalized-menu'
      templateUrl: 'apps/features/personalized-menu/personalized-menu'
      controller: 'PersonalizedMenuCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'lightgallery/dist/css/lightbox.css'
              ]
          ])

    .state 'app.menumanagement.menu-matching',
      url: '/menu-matching/:_id'
      templateUrl: 'apps/features/menu-matching/menu-matching'
      controller: 'MenuMatchingCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'lightgallery/dist/css/lightbox.css'
              ]
          ])

    .state 'app.menumanagement.menu-test',
      url: '/menu-test/:openid/:menu_id'
      templateUrl: 'apps/features/menu-test/menu-test'
      controller: 'MenuTestCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'lightgallery/dist/css/lightbox.css'
              ]
          ])
