angular.module 'app'
.controller 'ActivityModalCtrl', ($scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data) ->
  $scope.ui =
    data: data
    resourcesUrl: config.resources.baseUrl
    saveDisabled: false

    prizesType: [
        name: '實物'
        val: 'goods'
      ,
        name: '虛擬'
        val: 'virtual'
      ,
        name: '現金'
        val: 'money'
    ]

    currencyArr: [
        name: '￥'
        val: 'RMB'
      ,
        name: '$'
        val: 'USD'
      ,
        name: '€'
        val: 'EUR'
      ,
        name: '￡'
        val: 'GBP'
      ,
        name: 'MOP'
        val: 'MOP'
      ,
        name: 'HKD'
        val: 'HKD'
    ]

    startGraphic: []  # 开始图文
    endGraphic: []  # 结束图文
    title: '新增活動'  # 活动名称
    description: ''  # 活动说明
    prizes: []  # 奖品

    date:
      startDate: moment()
      endDate: moment().add(29, 'days')
    opts: angular.copy config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#activity-modal'
  $scope.ui.opts.opens = 'right'
  delete $scope.ui.opts.maxDate

  console.log $scope.ui.data

  getData = ->

  # 选择图文
  $scope.selectGraphic = (type) ->
    dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true}, {size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.startGraphic = option if type == 'start'
      $scope.ui.endGraphic = option if type == 'end'
    ), ->

  # 删除图文
  $scope.delGraphic = (type) ->
    $scope.ui.startGraphic = [] if type == 'start'
    $scope.ui.endGraphic = [] if type == 'end'

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    console.log $scope.ui
