angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.activity',
      url: '/activity'
      templateUrl: 'apps/activity/layout/layout'
      controller: 'ActivityCtrl'

    # 活动管理
    .state 'app.activity.list',
      url: '/activity-list'
      templateUrl: 'apps/activity/activity-list/activity-list'
      controller: 'ActivityListCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
