angular.module 'app'
.controller 'UploadCuttingFileCtrl', ($scope, $stateParams, $uibModalInstance, data, growl, config, FileUploader, Storage, $http, dialogs) ->

  $scope.ui =
    type: angular.copy data.type
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl
    obj: {
      # src: config.resources.baseUrl + angular.copy data.cover_pic_url if data.cover_pic_url
      # src: angular.copy data.cover_pic_url if data.cover_pic_url
      src: ''
      selection: [0, 0, 210, 210, 200, 100]
      coords: []
      thumbnail: true
      thumbnail2: true
    }
    waterMark: false
    material_id: ''
    activeIndex: data.activeIndex

  if data.cover_pic_url
    $scope.ui.obj.src = data.cover_pic_url
    $scope.ui.material_id = data.material_id

  $scope.uploaderClick = ->
    $scope.uploader.url = data.url if $scope.ui.type == 'graphic'

  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    removeAfterUpload: true
    queueLimit: if $scope.ui.type == 'graphic' && data.location == 0 then 1 else 4
    url: ""
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 2
        growl.error "圖片大小不能超過2M"
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->
    growl.success("上傳中！", {referenceId: 1})

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.obj.src = $scope.ui.resourcesUrl + response.path
    $scope.ui.material_id = response.material_id

    _tip = "上傳成功！"

    if $scope.ui.type == 'graphic'
      if $scope.ui.waterMark
        _tip = _tip + "正在打水印，請勿關閉..."
      else
        _tip = _tip
      growl.success(_tip)

      _url = "coverImg"
      _url = "uploadimg" if data.location == 1

      _params =
        original_id: $stateParams.original_id
        is_wm: $scope.ui.waterMark

      _params.material_id = response.material_id if data.location == 0
      _params.url = response.path if data.location == 1

      $http.post "#{config.api.baseUrl}/material/article/" + _url + "/wm?access_token=" + Storage.get('access_token'), _params
      .then (res) ->
        growl.success('打水印成功！') if $scope.ui.waterMark
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error('微信連接失敗：' + err.errmsg)
        else
          err = JSON.parse error.data.error
          growl.error('網絡連接失敗，請稍後重試')

    else if $scope.ui.type == 'image'
      _tip = _tip + "正在打水印..." if $scope.ui.waterMark
      growl.success(_tip)

      if $scope.ui.waterMark
        $http.post "#{config.api.baseUrl}/material/article/wm?access_token=" + Storage.get('access_token'),
          material_id: response.material_id
          original_id: $stateParams.original_id
        .then (res) ->
          growl.success('打水印成功！')
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error("上傳失敗！")

  ### 从图片库选择 封面 图片 ###
  $scope.chooseImgage = ->
    dlg = dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true, sizeTips: true}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      $scope.ui.obj.src = $scope.ui.resourcesUrl + materials[0].file.path
      $scope.ui.material_id = materials[0]._id
    ), ->

  $scope.save = ->
    return growl.error('請選擇封面圖片或者取消') if $scope.ui.obj.src == ''
    growl.success "封面正在裁剪，請勿關閉..."

    $http.post "#{config.api.baseUrl}/material/crop-image?access_token=" + Storage.get('access_token'),
      original_id: $stateParams.original_id
      material_id: $scope.ui.material_id
      width: $scope.ui.obj.selection[4]
      height: $scope.ui.obj.selection[5]
      x: $scope.ui.obj.selection[0]
      y: $scope.ui.obj.selection[1]
      created_by: $scope.ui.user.id
    .then (res) ->
      $uibModalInstance.close ({originalImg_id: $scope.ui.material_id; originalImg_url: $scope.ui.obj.src; data: res.data})
      growl.success "保存成功！"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # end cancel
  $scope.cancel = ->
    $uibModalInstance.close 'Cancle'
