angular.module 'app'
.factory 'Storage',  ->
  set: (key, data) ->
      return window.sessionStorage.setItem(key, window.JSON.stringify(data))
  get: (key) ->
      if window.sessionStorage.getItem(key) == null || window.sessionStorage.getItem(key) == 'undefined'
        return null
      else
        return window.JSON.parse(window.sessionStorage.getItem(key))

  remove: (key) ->
      return window.sessionStorage.removeItem(key)

.factory 'CreateDateText', ->
  format: (date) ->
    dateText = moment(date).format('YYYY/MM/DD ahh:mm')
    dateText = dateText.replace('am', '上午')
    dateText = dateText.replace('pm', '下午')
    return dateText

.factory 'CreateHtml', ($sce) ->
  toHtml: (text) ->
    return $sce.trustAsHtml(text)

.factory 'SocketIO', (socketFactory, config) ->
  return socketFactory(
    ioSocket: io.connect(config.esocket.baseUrl)
  )
.factory 'findRules', ($http, Storage, $stateParams, config) ->
  return ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/menu-term?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      return res.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

.factory 'Emoji', ($sce) ->
  Keywords = [
    key: "[微笑]"
    val: '<img src="../images/wechat/100.gif">'
  ,
    key: "[撇嘴]"
    val: '<img src="../images/wechat/101.gif">'
  ,
    key: "[色]"
    val: '<img src="../images/wechat/102.gif">'
  ,
    key: "[发呆]"
    val: '<img src="../images/wechat/103.gif">'
  ,
    key: "[得意]"
    val: '<img src="../images/wechat/104.gif">'
  ,
    key: "[流泪]"
    val: '<img src="../images/wechat/105.gif">'
  ,
    key: "[害羞]"
    val: '<img src="../images/wechat/106.gif">'
  ,
    key: "[闭嘴]"
    val: '<img src="../images/wechat/107.gif">'
  ,
    key: "[睡]"
    val: '<img src="../images/wechat/108.gif">'
  ,
    key: "[大哭]"
    val: '<img src="../images/wechat/109.gif">'
  ,
    key: "[尴尬]"
    val: '<img src="../images/wechat/110.gif">'
  ,
    key: "[发怒]"
    val: '<img src="../images/wechat/111.gif">'
  ,
    key: "[调皮]"
    val: '<img src="../images/wechat/112.gif">'
  ,
    key: "[呲牙]"
    val: '<img src="../images/wechat/113.gif">'
  ,
    key: "[惊讶]"
    val: '<img src="../images/wechat/114.gif">'
  ,
    key: "[难过]"
    val: '<img src="../images/wechat/115.gif">'
  ,
    key: "[冷汗]"
    val: '<img src="../images/wechat/117.gif">'
  ,
    key: "[抓狂]"
    val: '<img src="../images/wechat/118.gif">'
  ,
    key: "[吐]"
    val: '<img src="../images/wechat/119.gif">'
  ,
    key: "[偷笑]"
    val: '<img src="../images/wechat/120.gif">'
  ,
    key: "[愉快]"
    val: '<img src="../images/wechat/121.gif">'
  ,
    key: "[白眼]"
    val: '<img src="../images/wechat/122.gif">'
  ,
    key: "[傲慢]"
    val: '<img src="../images/wechat/123.gif">'
  ,
    key: "[困]"
    val: '<img src="../images/wechat/125.gif">'
  ,
    key: "[惊恐]"
    val: '<img src="../images/wechat/126.gif">'
  ,
    key: "[流汗]"
    val: '<img src="../images/wechat/127.gif">'
  ,
    key: "[憨笑]"
    val: '<img src="../images/wechat/128.gif">'
  ,
    key: "[悠闲]"
    val: '<img src="../images/wechat/129.gif">'
  ,
    key: "[奋斗]"
    val: '<img src="../images/wechat/130.gif">'
  ,
    key: "[咒骂]"
    val: '<img src="../images/wechat/131.gif">'
  ,
    key: "[疑问]"
    val: '<img src="../images/wechat/132.gif">'
  ,
    key: "[嘘]"
    val: '<img src="../images/wechat/133.gif">'
  ,
    key: "[晕]"
    val: '<img src="../images/wechat/134.gif">'
  ,
    key: "[衰]"
    val: '<img src="../images/wechat/136.gif">'
  ,
    key: "[骷髅]"
    val: '<img src="../images/wechat/137.gif">'
  ,
    key: "[敲打]"
    val: '<img src="../images/wechat/138.gif">'
  ,
    key: "[再见]"
    val: '<img src="../images/wechat/139.gif">'
  ,
    key: "[擦汗]"
    val: '<img src="../images/wechat/140.gif">'
  ,
    key: "[抠鼻]"
    val: '<img src="../images/wechat/141.gif">'
  ,
    key: "[鼓掌]"
    val: '<img src="../images/wechat/142.gif">'
  ,
    key: "[坏笑]"
    val: '<img src="../images/wechat/144.gif">'
  ,
    key: "[左哼哼]"
    val: '<img src="../images/wechat/145.gif">'
  ,
    key: "[右哼哼]"
    val: '<img src="../images/wechat/146.gif">'
  ,
    key: "[哈欠]"
    val: '<img src="../images/wechat/147.gif">'
  ,
    key: "[鄙视]"
    val: '<img src="../images/wechat/148.gif">'
  ,
    key: "[委屈]"
    val: '<img src="../images/wechat/149.gif">'
  ,
    key: "[快哭了]"
    val: '<img src="../images/wechat/150.gif">'
  ,
    key: "[阴险]"
    val: '<img src="../images/wechat/151.gif">'
  ,
    key: "[亲亲]"
    val: '<img src="../images/wechat/152.gif">'
  ,
    key: "[可怜]"
    val: '<img src="../images/wechat/154.gif">'
  ,
    key: "[菜刀]"
    val: '<img src="../images/wechat/155.gif">'
  ,
    key: "[西瓜]"
    val: '<img src="../images/wechat/156.gif">'
  ,
    key: "[啤酒]"
    val: '<img src="../images/wechat/157.gif">'
  ,
    key: "[咖啡]"
    val: '<img src="../images/wechat/160.gif">'
  ,
    key: "[猪头]"
    val: '<img src="../images/wechat/162.gif">'
  ,
    key: "[玫瑰]"
    val: '<img src="../images/wechat/163.gif">'
  ,
    key: "[凋谢]"
    val: '<img src="../images/wechat/164.gif">'
  ,
    key: "[嘴唇]"
    val: '<img src="../images/wechat/165.gif">'
  ,
    key: "[爱心]"
    val: '<img src="../images/wechat/166.gif">'
  ,
    key: "[心碎]"
    val: '<img src="../images/wechat/167.gif">'
  ,
    key: "[蛋糕]"
    val: '<img src="../images/wechat/168.gif">'
  ,
    key: "[炸弹]"
    val: '<img src="../images/wechat/170.gif">'
  ,
    key: "[便便]"
    val: '<img src="../images/wechat/174.gif">'
  ,
    key: "[月亮]"
    val: '<img src="../images/wechat/175.gif">'
  ,
    key: "[太阳]"
    val: '<img src="../images/wechat/176.gif">'
  ,
    key: "[拥抱]"
    val: '<img src="../images/wechat/178.gif">'
  ,
    key: "[强]"
    val: '<img src="../images/wechat/179.gif">'
  ,
    key: "[弱]"
    val: '<img src="../images/wechat/180.gif">'
  ,
    key: "[握手]"
    val: '<img src="../images/wechat/181.gif">'
  ,
    key: "[胜利]"
    val: '<img src="../images/wechat/182.gif">'
  ,
    key: "[抱拳]"
    val: '<img src="../images/wechat/183.gif">'
  ,
    key: "[勾引]"
    val: '<img src="../images/wechat/184.gif">'
  ,
    key: "[拳头]"
    val: '<img src="../images/wechat/185.gif">'
  ,
    key: "[OK]"
    val: '<img src="../images/wechat/189.gif">'
  ,
    key: "[跳跳]"
    val: '<img src="../images/wechat/192.gif">'
  ,
    key: "[发抖]"
    val: '<img src="../images/wechat/193.gif">'
  ,
    key: "[怄火]"
    val: '<img src="../images/wechat/donh1.gif">'
  ,
    key: "[转圈]"
    val: '<img src="../images/wechat/195.gif">'
  ,
    key: "[笑脸]"
    val: '<img src="../images/wechat/200.png">'
  ,
    key: "[生病]"
    val: '<img src="../images/wechat/201.png">'
  ,
    key: "[破涕为笑]"
    val: '<img src="../images/wechat/202.png">'
  ,
    key: "[吐舌]"
    val: '<img src="../images/wechat/203.png">'
  ,
    key: "[脸红]"
    val: '<img src="../images/wechat/205.png">'
  ,
    key: "[恐惧]"
    val: '<img src="../images/wechat/206.png">'
  ,
    key: "[失望]"
    val: '<img src="../images/wechat/207.png">'
  ,
    key: "[无语]"
    val: '<img src="../images/wechat/210.png">'
  ,
    key: "[嘿哈]"
    val: '<img src="../images/wechat/01xx.png">'
  ,
    key: "[捂脸]"
    val: '<img src="../images/wechat/01xx-2.png">'
  ,
    key: "[奸笑]"
    val: '<img src="../images/wechat/01xx-3.png">'
  ,
    key: "[机智]"
    val: '<img src="../images/wechat/01xx-4.png">'
  ,
    key: "[皱眉]"
    val: '<img src="../images/wechat/01xx-5.png">'
  ,
    key: "[耶]"
    val: '<img src="../images/wechat/01xx-6.png">'
  ,
    key: "[鬼魂]"
    val: '<img src="../images/wechat/212.png">'
  ,
    key: "[合十]"
    val: '<img src="../images/wechat/214.png">'
  ,
    key: "[强壮]"
    val: '<img src="../images/wechat/215.png">'
  ,
    key: "[庆祝]"
    val: '<img src="../images/wechat/218.png">'
  ,
    key: "[礼物]"
    val: '<img src="../images/wechat/219.png">'
  ,
    key: "[红包]"
    val: '<img src="../images/wechat/01xx-7.png">'
  ,
    key: "[鸡]"
    val: '<img src="../images/wechat/01xx-8.png">'
  ,
  ]

  keywords1 = _.chain(Keywords).map((d) -> [d.key, d.val]).object().value()
  keywords2 = _.chain(Keywords).map((d) -> [d.val, d.key]).object().value()

  reg1 = /\[(.*?)\]/g
  reg2 = /<img src=.*?\/?>/g

  Keywords: Keywords
  replaceFace: (content) ->
    content = content.replace reg1, (key) -> keywords1[key] or key
    return $sce.trustAsHtml(content)

  replaceText: (content, isLength) ->
    content = content.replace reg2, (key) -> if isLength then '    ' else (keywords2[key] or key)
    return content
