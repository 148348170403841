angular.module 'app'
.directive 'wordcloud', (echartsThemeShine) ->

    restrict: 'E'
    scope:
      options: '='
      onClick: '='
      height: '='
    template: "<canvas id='wordcloud'></canvas>"
    replace: true

    link: (scope, el, attrs) ->
      el.attr 'width', el.parent().width()
      element = document.getElementById('wordcloud')
      WordCloud element, scope.options

      scope.$watch 'options', (newValue, oldValue) ->
        # return if newValue is oldValue
        WordCloud element, newValue
      , true

