angular.module 'app'
.controller 'ActivityListCtrl', ($rootScope, $stateParams, $scope, $http, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').activity == undefined

  $scope.ui =
    isGroupLoading: false
    isDataLoading: false

    totalItems: 0   # 总条数
    currentPage: 1   # 当前页码
    perPage: 10   # 每页条数
    maxSize: 8   # 页码最大数量

    date:
      startDate: moment().subtract(29, 'days')
      endDate: moment()
    opts: angular.copy config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#activity-list'
  $scope.ui.opts.opens = 'right'

  # 活动新增/修改
  $scope.showDetail = (type, id) ->
    dlg = dialogs.create('apps/activity/activity-modal/activity-modal', 'ActivityModalCtrl', {type: type, id: id}, {size: 'md'})
    dlg.result.then ((option) ->
      console.log option
    ), ->
