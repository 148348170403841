angular.module 'app'

  .directive 'echarts', (echartsThemeShine) ->

    restrict: 'E'
    scope:
      options: '='
      height: '='
      onClick: '='
    templateUrl: 'directives/echarts/echarts'
    # replace: true

    link: (scope, el, attrs) ->
      el.find('.echarts').height scope.height
      echarts.registerMap('china', mapjson)
      chart = echarts.init el.find('.echarts')[0], 'macarons'
      chart.setOption({
        series: [{
            type: 'map',
            map: 'china'
        }]
      })
      if scope.onClick
        chart.on 'click', scope.onClick

      scope.$watch 'options', ((newValue, oldValue) ->
        # return if newValue is oldValue
        chart.hideLoading()
        chart.setOption scope.options, true), true

