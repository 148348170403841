angular.module 'app'
.controller 'AdvisoryModalCtrl', ($scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data, FileUploader) ->
  $scope.ui =
    data: data
    newKey: ''
    newVal: ''

    saveDisabled: false

  if $scope.ui.data.type == 'tour'
    $scope.ui.data.data.bus_line_text = ''
    _.each $scope.ui.data.data.bus_line, (data, index) ->
      $scope.ui.data.data.bus_line_text += data
      $scope.ui.data.data.bus_line_text += '，' if index + 1 != $scope.ui.data.data.bus_line.length

  $scope.removeImg = (index) ->
    $scope.ui.data.data.imgs.splice index, 1

  $scope.minusParam = (index) ->
    $scope.ui.data.data.contents.splice index, 1

  $scope.plusParam = ->
    return growl.error '數據不可為空' if $scope.ui.newKey == '' || $scope.ui.newVal == ''
    $scope.ui.data.data.contents.push {key: $scope.ui.newKey, val: $scope.ui.newVal}
    $scope.ui.newKey = ''
    $scope.ui.newVal = ''

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    console.log $scope.ui.data

  $scope.uploaderClick = ->
    $scope.uploader.url = "#{config.api.baseUrl}/material/fileupload?access_token=#{Storage.get('access_token')}"

  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    removeAfterUpload: true
    url: ""
  )

  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 2
        growl.error("不能上傳大於2MB的圖片！", {referenceId: 1})
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->
    growl.success("上傳中！", {referenceId: 1})

  uploader.onSuccessItem = (fileItem, response, status, headers) ->

  uploader.onErrorItem = (fileItem, response, status, headers) ->
