angular.module 'app'
.config (dialogsProvider,$translateProvider) ->
  # dialogsProvider.useBackdrop('static')
  dialogsProvider.useBackdrop(true)
  dialogsProvider.useEscClose(false)
  dialogsProvider.useCopy(false)
  dialogsProvider.setSize('sm')
  $translateProvider.translations('zh-tw',{
      DIALOGS_ERROR: "錯誤",
      DIALOGS_ERROR_MSG: "出現未知錯誤。",
      DIALOGS_CLOSE: "關閉",
      DIALOGS_PLEASE_WAIT: "請稍候",
      DIALOGS_PLEASE_WAIT_ELIPS: "請稍候...",
      DIALOGS_PLEASE_WAIT_MSG: "等待操作完成。",
      DIALOGS_PERCENT_COMPLETE: "% 完成",
      DIALOGS_NOTIFICATION: "通知",
      DIALOGS_NOTIFICATION_MSG: "未知的應用程序的通知。",
      DIALOGS_CONFIRMATION: "確認",
      DIALOGS_CONFIRMATION_MSG: "確認要求。",
      DIALOGS_OK: "OK",
      DIALOGS_YES: "確定",
      DIALOGS_NO: "取消"
  })

  $translateProvider.preferredLanguage('zh-tw')
