angular.module 'app'
.controller 'CoverMakingCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert) ->

  $scope.ui =

  # 新增圖文
  $scope.newFigure = ->
    dlg = dialogs.create('apps/cover-making/figure/figure', 'GraphicDetailCtrl', {}, {size: 'lg'})
    dlg.result.then ((option) ->
      # findDatas()
    ), ->
