angular.module 'app'
.controller 'RecorddialogsCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert, $timeout,data,$uibModalInstance) ->
  $scope.ui =
    dataArr: []



  ### 初始化 ###
  if data.recorddata
    $scope.ui.dataArr = angular.copy data.recorddata

  # 刪除單篇歷史文章
  $scope.deleterecord = (index) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除該歷史文章？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/delete/#{$scope.ui.dataArr._id}?access_token=" + Storage.get('access_token') + "&article_idx=#{index + 1}",{}
        .then (res) ->
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.deleteAll = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除全部歷史文章？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/delete/#{$scope.ui.dataArr._id}?access_token=" + Storage.get('access_token') + "&article_idx=0",{}
        .then (res) ->
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
