angular.module 'app'
.controller 'MsgModalCtrl', ($scope, $http, $state, $sce, $stateParams, $uibModalInstance, config, Storage, dialogs, growl, data) ->
  $scope.ui =
    user: Storage.get('login_user')
    fans: data.user
    activeObj: angular.copy data.data

    sendState: false

    date: moment().format('YYYY-MM-DD HH:mm:ss')
    opts:
      autoApply: true
      parentEl: 'div#msg-modal'
      minDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      drops: 'down'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

  # 處理模板數據
  do getTemplate = ->
    if $scope.ui.activeObj.content != undefined
      html_str = $scope.ui.activeObj.content.replace(new RegExp('\n','g'),'<br>').split('<br>')
      keys = []
      _.each html_str, (val) ->
        _name = _key = _val = ''
        _color = '#173177'
        _name = val.split('{{')[0]
        _key = val.split('{{')[1].split('.DATA}}')[0] if val.split('{{')[1] != undefined
        keys.push {name: _name, key: _key, val: _val, color: _color}
      $scope.ui.activeObj.example_html = $sce.trustAsHtml $scope.ui.activeObj.example.replace(new RegExp('\n','g'),'<br>')
      $scope.ui.activeObj.content_val = keys

  # 套用
  $scope.setValue = ->
    _strs = $scope.ui.activeObj.example.replace(new RegExp('\n','g'),'<br>').split('<br>')

    _.each $scope.ui.activeObj.content_val, (data, i) ->
      if data.name == ''
        data.val = _strs[i]
      else
        data.val = _strs[i].substring(_strs[i].indexOf(data.name) + data.name.length)

  # 编制
  $scope.setExample = ->
    $scope.ui.activeObj.content_edit = angular.copy $scope.ui.activeObj.content_val

    _strs = $scope.ui.activeObj.example.replace(new RegExp('\n','g'),'<br>').split('<br>')

    _.each $scope.ui.activeObj.content_edit, (data, i) ->
      data.color = '#173177'
      if data.name == ''
        data.val = _strs[i]
      else
        data.val = _strs[i].substring(_strs[i].indexOf(data.name) + data.name.length)

  # 保存示例
  $scope.saveExample = ->
    _str = angular.copy $scope.ui.activeObj.content
    _.each $scope.ui.activeObj.content_edit, (data, i) ->
      _str = _str.replace('{{' + data.key + '.DATA}}', data.val)

    $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/template/#{$scope.ui.activeObj._id}?access_token=" + Storage.get('access_token'),
      example: _str
    .then (res) ->
      $scope.ui.activeObj.example = _str
      getTemplate()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 设置模板内容颜色
  $scope.setColor = (_index, _color) ->
    $scope.ui.activeObj.content_val[_index].color = _color

  # 重选模板
  $scope.choose = ->
    dialogs.create('apps/template/template-msg/modal/modal', 'TemplateMsgModalCtrl', {}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.activeObj = option
      getTemplate()
    ), ->

  # 模板驗證
  verificationTemplate = (callback) ->
    _status = false
    _.each $scope.ui.activeObj, (data) ->
      _status = true
    return callback _status, '請選擇模板' if _status == false

    _str = '{'
    _.each $scope.ui.activeObj.content_val, (key) ->
      _str += key.key + ': {value: "' + key.val + '", color: "' + key.color + '"},' if key.key != ''

    _str += '}'
    str = eval("(" + _str + ")")

    _status = true
    _.each str, (e) ->
      _status = false if e.value == ''
    return callback _status, '輸入框不可為空' if _status == false
    return callback _status, str if _status == true

  # 发送
  $scope.send = ->
    verificationTemplate((status, str) ->
      return growl.error str if status == false
      $scope.ui.sendState = true

      template_html = data.data.content.replace(new RegExp('\n','g'),'<br>')

      _.each $scope.ui.activeObj.content_val, (obj) ->
        template_html = template_html.replace(new RegExp('{{' + obj.key + '.DATA}}','g'), '<span style="color:' + obj.color + ';">' + obj.val + '</span>')

      _obj =
        msgtype: 'template'
        template_id: $scope.ui.activeObj.template_id
        content: str
        template:
          name: $scope.ui.activeObj.name
          content: '<div>' + template_html + '</div>'

      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/template/#{$scope.ui.fans._id}?access_token=" + Storage.get('access_token'), _obj
      .then (res) ->
        $scope.ui.sendState = false
        growl.success '發送成功'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        $scope.ui.sendState = false
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    )

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
