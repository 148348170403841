angular.module 'app'
.controller 'QaDetailCtrl', ($scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data, FileUploader, $rootScope) ->

  $scope.ui =
    isLoading: false
    appmsg: {
      first: data.appmsg.first
      second: data.appmsg.second
      question: data.appmsg.question
      answer: data.appmsg.answer
    }

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'