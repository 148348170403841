angular.module 'app'
.controller 'UserMemberEditCtrl', ($uibModalInstance, config, $scope, $stateParams, growl, $http, data, Storage) ->
  $scope.ui =
    user: Storage.get('login_user')
    product_id: config.eadmin.clientId
    eadminDisplay: config.eadmin.display

    member: data.member
    selectRole: null
    roles: []
    title: data.title

  do findRole = ->
    if $scope.ui.eadminDisplay == true
      roles = """
          query(
            $query: RoleQueryInput
          ){
            role(
              query: $query
            ){
              data{
                id
                groupId
                productId
                name
                groupName
                productName
                createdAt
                data
              }
            }
          }
        """
      variables =
        query:
          groupId: String($scope.ui.user.group_id)
          productId: String($scope.ui.product_id)
      $http.post "#{config.eadmin.newBaseUrl}/graphql?access_token=" + Storage.get('access_token'),
        query: roles
        variables: variables
      .then (res) ->
        _.each res.data.data.role.data, (data) ->
          data.id = parseInt(data.id)
        $scope.ui.roles = res.data.data.role.data
        _.each $scope.ui.member.roles, (val) ->
          val.id = parseInt(val.roleId)
          if val.productId is String($scope.ui.product_id)
            $scope.ui.selectRole = val
    else
      $http.get "#{config.eadmin.baseUrl}/account/groups/#{$scope.ui.user.group_id}/roles?access_token=" + Storage.get('access_token') + "&product_id=#{$scope.ui.product_id}",{}
      .then (res) ->
        _.each res.data.data, (res) ->
        $scope.ui.roles = res.data.data
        _.each $scope.ui.member.roles, (val) ->
          if val.product_id is $scope.ui.product_id
            $scope.ui.selectRole = val

  $scope.setRole = (role) ->
    $scope.ui.selectRole = _.clone role
    index = null
    _.each $scope.ui.member.roles, (val, i) ->
      if val.productId is String($scope.ui.product_id)
        index = i
    if index isnt null
      $scope.ui.member.roles[index] = role

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.confirm = ->
    if $scope.ui.selectRole is null
      growl.error '請選擇角色!',{ttl: 2000}
      return
    if $scope.ui.eadminDisplay == true
      upsertRoleRelation = """
        mutation(
          $userId: String!
          $data: [RoleRelationInput]
        ){
          upsertRoleRelation(
            userId: $userId
            data: $data
          )
        }
      """
      variables1 =
        userId: $scope.ui.member.id
        data:
          roleId: String($scope.ui.selectRole.id)
          groupId: String($scope.ui.user.group_id)
          productId: String($scope.ui.product_id)

      upsertUsers = """
        mutation(
          $data: [UserInput]
        ){
          upsertUser(
            data: $data
          ){
            id
            groupId
            groupName
            username
            email
            lang
            data
            status
            createdAt
            updatedAt
            updatedBy
            roles(
              query: {}
            ){
              roleId
              roleName
              groupId
              groupName
              productId
              productName
            }
          }
        }
      """
      variables2 =
        data:
          id: $scope.ui.member.id
          groupId: String($scope.ui.user.group_id)

      $http.post "#{config.eadmin.newBaseUrl}/graphql?access_token=" + Storage.get('access_token'),
        query: upsertRoleRelation
        variables: variables1
      .then ->
        $http.post "#{config.eadmin.newBaseUrl}/graphql?access_token=" + Storage.get('access_token'),
          query: upsertUsers
          variables: variables2
        .then (res) ->
          growl.success "保存成功！"
          $uibModalInstance.close 'Save'
        .catch (error) ->
          growl.error "保存失敗！"
    else
      memberRoles = []
      saveData = _.clone $scope.ui.member
      saveData.userId = saveData?.id
      saveData.group_id = $scope.ui.user.group_id
      saveData.role_id = $scope.ui.selectRole.id
      params =
        q: saveData.email
        access_token: Storage.get('access_token')
      $http.get "http://eadmin-api.ersinfotech.com/account/users",
        params: params
      .then (res) ->
        if res.data.count > 0
          data = res.data.data[0]
          if String(data.group_id) isnt String($scope.ui.user.group_id)
            growl.error '電郵地址已被使用!',{ttl: 2000}
            return
          else if String(data.group_id) is String($scope.ui.user.group_id)
            saveData.userId = data.id
            saveData.id = data.id
            $scope.ui.member.roles = data.roles
        else
          saveData.password = Math.random().toString(36).slice(-8)
      .then ->
        if $scope.ui.member.roles
          memberRoles = []
          _.each $scope.ui.member.roles, (val) ->
            if val.product_id isnt $scope.ui.product_id
              memberRoles.push val
          memberRoles.push {id: $scope.ui.selectRole.id}
        else
          memberRoles.push {id: $scope.ui.selectRole.id}

      .then ->
        saveData.role_id = _.uniq(_.pluck(memberRoles, 'id'))
        _.each saveData.roles, (val) ->
          val.enabled = true
          val.role_id = val.id
        if $scope.ui.title == 'edit'
          $http.put "#{config.eadmin.baseUrl}/account/groups/#{$scope.ui.user.group_id}/users/#{saveData.id}?access_token=" + Storage.get('access_token'), saveData
          .then (res) ->
            growl.success "保存成功！"
            $uibModalInstance.close 'Save'
          .catch (error) ->
            growl.error "保存失敗！"
