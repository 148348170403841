angular.module 'app'
.controller 'TemplateAddCtrl', ($scope, $http, $state, $sce, $uibModalInstance, $stateParams, config, Storage, growl, data) ->
  $scope.ui =
    user: Storage.get('login_user')
    industryNo: []
    activeIndustryNo: ''
    industry: []

    isDisabled: false

    no: ''
    title: ''
    detail: ''
    sample: ''

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  $scope.htmlPopover = $sce.trustAsHtml('<div class="help-popover"">登入微信官方平台，在模板庫中找到相應的模板填充下列信息，則可導入新模板。</div>')

  getIndustryNo = ->
    $http.get "#{config.api.baseUrl}/template/industry?access_token=" + Storage.get('access_token'),
      params:
        sort: "industry_sort"
    .then (res) ->
      $scope.ui.industryNo = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  do fetchIndustry = ->
    $http.get "#{config.api.baseUrl}/template/getindustry?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      _.each res.data, (val) ->
        obj = {}
        obj.key = val.industry_code
        obj.name = "#{val.main_industry} - #{val.vice_industry}"
        $scope.ui.industry.push obj
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')


  $scope.save = ->
    return growl.error('請輸入編號') if $scope.ui.no == ''
    return growl.error('請輸入標題') if $scope.ui.title == ''
    return growl.error('請選擇行業代碼') if $scope.ui.activeIndustryNo == ''
    return growl.error('請輸入詳細內容') if $scope.ui.detail == ''
    return growl.error('請輸入內容示例') if $scope.ui.sample == ''

    $scope.ui.isDisabled = true

    _main = ''
    _vice = ''
    _.each $scope.ui.industryNo, (data) ->
      if data.industry_code == $scope.ui.activeIndustryNo
        _main = data.main_industry
        _vice = data.vice_industry

    $http.post "#{config.api.baseUrl}/template/list?access_token=" + Storage.get('access_token'),
      original_id: $stateParams.original_id
      name: $scope.ui.title
      main_industry: _main
      vice_industry: _vice
      industry_code: $scope.ui.activeIndustryNo
      template_id_short: $scope.ui.no
      example: $scope.ui.sample
      content: $scope.ui.detail
    .then (res) ->
      $uibModalInstance.close 'Save'
      $scope.ui.isDisabled = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isDisabled = false
