
angular.module 'app'

  .directive 'ersPagination', ->

    restrict: 'E'

    scope:
      totalItems: '='
      itemsPerPage: '='
      maxSize: '='
      page: '='

    templateUrl: 'directives/ers-pagination/ers-pagination'

    link: (scope) ->

      scope.goTo = ->
        scope.page = scope.targetPage or 1
        scope.targetPage = null
