angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    #
    .state 'app.mobile',
      url: '/mobile'
      templateUrl: 'apps/mobile/mobile'
      controller: 'MobileCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'socket/socket.io.js'
              ]
          ])

