angular.module 'app'
.controller 'ConcernCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').interaction.response.is_use == false

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    isLoading: false
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    appmsgs: []

  fetchAppmsg = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/appmsg?access_token=" + Storage.get('access_token'),
      params:
        reply_state: "4"
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (val) ->
        val.rule.sex_name = ""
        val.rule.language_name = ""
        if val.rule.sexs.length > 1
          val.rule.sex_name = "全部"
        else if _.contains val.rule.sexs, "1"
          val.rule.sex_name = "男性"
        else
          val.rule.sex_name = "女性"
        if val.rule.languages.length > 2
          val.rule.language_name = "全部"
        else
          if _.contains val.rule.languages, "zh_CN"
            val.rule.language_name = if val.rule.language_name.length > 0 then "#{val.rule.language_name}, 簡體中文" else "簡體中文"
          if _.contains val.rule.languages, "zh_TW"
            val.rule.language_name =  if val.rule.language_name.length > 0 then "#{val.rule.language_name}, 繁體中文" else "繁體中文"
          if _.contains val.rule.languages, "en"
            val.rule.language_name =  if val.rule.language_name.length > 0 then "#{val.rule.language_name}, 英文" else "英文"
      $scope.ui.appmsgs = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  $scope.$watch 'ui.currentPage', fetchAppmsg

  $scope.add = ->
    dialogs.create('apps/response/add/add', 'ResponseAddCtrl', {appmsg: reply_state: 4}, {size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      fetchAppmsg()
    ), ->

  $scope.editDetail = (appmsg) ->
    dlg = dialogs.create('apps/response/add/add', 'ResponseAddCtrl', {appmsg: appmsg}, {size: 'md'})
    dlg.result.then ((option) ->
      fetchAppmsg()
    ), ->

  $scope.delAppmsg = (appmsg) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{appmsg.name}”!"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/appmsg/#{appmsg._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          $scope.ui.appmsgs.splice($scope.ui.appmsgs.indexOf(appmsg), 1)
          growl.success "刪除成功！"
          fetchAppmsg()
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
