angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    # 用戶分析
    .state 'app.user-analysis',
      url: '/user-analysis'
      templateUrl: 'apps/analysis/user-analysis/layout/layout'
      controller: 'UserAnalysisLayoutCtrl'

    # 用戶增長
    .state 'app.user-analysis.user-grow',
      url: '/user-grow'
      templateUrl: 'apps/analysis/user-analysis/user-grow/user-grow'
      controller: 'UserGrowCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 用戶屬性
    .state 'app.user-analysis.user-attribute',
      url: '/user-attribute'
      templateUrl: 'apps/analysis/user-analysis/user-attribute/user-attribute'
      controller: 'UserAttributeCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
              ]
          ])

    # 用戶行為
    .state 'app.user-analysis.user-behavior',
      url: '/user-behavior'
      templateUrl: 'apps/analysis/user-analysis/user-behavior/user-behavior'
      controller: 'UserBehaviorCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 消息分析
    .state 'app.news-analysis',
      url: '/news-analysis'
      templateUrl: 'apps/analysis/news-analysis/layout/layout'
      controller: 'NewsAnalysisLayoutCtrl'

    # 消息分析
    .state 'app.news-analysis.news',
      url: '/news'
      templateUrl: 'apps/analysis/news-analysis/news/news'
      controller: 'NewsCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 詞云圖
    .state 'app.news-analysis.word-cloud',
      url: '/word-cloud'
      templateUrl: 'apps/analysis/news-analysis/word-cloud/word-cloud'
      controller: 'WordCloudCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 粉絲排名
    .state 'app.news-analysis.fans-ranking',
      url: '/fan-ranking'
      templateUrl: 'apps/analysis/news-analysis/fans-ranking/fans-ranking'
      controller: 'FansRankingCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 菜單分析
    .state 'app.menu-analysis',
      url: '/menu-analysis'
      templateUrl: 'apps/analysis/menu-analysis/layout/layout'
      controller: 'MenuAnalysisLayoutCtrl'

    # 菜單分析
    .state 'app.menu-analysis.menu',
      url: '/menu'
      templateUrl: 'apps/analysis/menu-analysis/menu/menu'
      controller: 'MenuCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 傳播分析
    .state 'app.graphic-analysis',
      url: '/graphic-analysis'
      templateUrl: 'apps/analysis/graphic-analysis/layout/layout'
      controller: 'GraphicAnalysisLayoutCtrl'

    # 單篇圖文
    .state 'app.graphic-analysis.one-shot',
      url: '/one-shot'
      templateUrl: 'apps/analysis/graphic-analysis/one-shot/one-shot'
      controller: 'OneShotCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 單次圖文
    .state 'app.graphic-analysis.one-time',
      url: '/one-time'
      templateUrl: 'apps/analysis/graphic-analysis/one-time/one-time'
      controller: 'OneTimeCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 全部圖文
    .state 'app.graphic-analysis.whole',
      url: '/whole'
      templateUrl: 'apps/analysis/graphic-analysis/whole/whole'
      controller: 'WholeCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 發佈分析
    .state 'app.graphic-analysis.release',
      url: '/release'
      templateUrl: 'apps/analysis/graphic-analysis/release-analysis/release-analysis'
      controller: 'ReleaseCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
