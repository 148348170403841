angular.module 'app'
.controller 'LogoManageCtrl', ($scope, $http, $state, $stateParams, dialogs, $uibModalInstance, config, Storage, growl, data) ->
  $scope.ui =
    data: data
    resourcesUrl: config.api.baseUrl
    isDisabled: false

  $scope.upload = ->
    dlg = dialogs.create('apps/material/image/choose/images', 'ImagesChooseCtrl', {radio: true}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      growl.info '正在上傳Logo圖片，請稍後...'
      $http.post "#{config.api.baseUrl}/cards/choose-logo?access_token=" + Storage.get('access_token'),
        original_id: $stateParams.original_id
        material_id: materials[0]._id
      .then (res) ->
        $scope.ui.data.path = res.data.path
        growl.success '上傳成功'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    ), ->

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
