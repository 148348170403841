angular.module 'app'
.controller 'MobileModalCtrl', ($timeout, $uibModalInstance, $stateParams, $scope, $http, config, Storage, growl, data, SocketIO) ->
  $scope.ui =
    user: Storage.get('login_user')
    baseUrl: config.api.baseUrl
    step: 0
    imgurl: ''
    clientID: 'wechat' + Storage.get('login_user').id

  _.each Storage.get('Wechats'), (data) ->
    $scope.ui.wechat = data if data.original_id == $stateParams.original_id

  window.SocketIO = SocketIO

  SocketIO.emit 'clientID', $scope.ui.clientID, 'bind-mobile'
  SocketIO.on $scope.ui.clientID, (data) ->
    return if data == 'bind-mobile'
    if data.step == 4
      $uibModalInstance.close('Cancel')
      return
    $scope.ui.step = data.step
    $scope.ui.imgurl = 'images/step' + (data.step + 1) + '.jpg' if $scope.ui.step != 0

  do getQRCode = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/bind/binding_qrcode?access_token=" + Storage.get('access_token'),
      params:
        user_id: $scope.ui.user.id
        user_group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.imgurl = res.data
      $scope.ui.step = 0
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
