angular.module 'app'
.controller 'LinkContentCtrl', ($scope, $http, $uibModalInstance, config, Storage, growl, data, FileUploader) ->
  $scope.ui =
    user: Storage.get('login_user')
    baseUrl: config.api.baseUrl
    resourcesUrl: config.resources.baseUrl
    data: data

    content:
      group_id: Storage.get('login_user').group_id
      is_show: false
      type: 'link'

  if $scope.ui.data.operationType == 'edit'
    $scope.ui.content = angular.copy $scope.ui.data.content

  _uploadUrl = "#{config.api.baseUrl}/cumpus/upload/image?width=1024&height=683"
  _uploadUrl = "#{config.api.baseUrl}/cumpus/upload/image?width=524&height=140" if $scope.ui.data.type == "ad"
  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    url: _uploadUrl
  )
  # FILTERS
  uploader.filters.push
    name: 'imageFilter'
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 0.5
        growl.error '文件大小超過限制!請選擇小於0.5M的圖片上傳。'
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.content.head_img = response.url

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error '上傳失敗'

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    if $scope.ui.data.operationType == 'add'
      $http.post "#{config.api.baseUrl}/cumpus/#{$scope.ui.data.type}",
        $scope.ui.content
      .then (res) ->
        growl.success '保存成功'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        growl.error '保存失敗'
    else
      $http.put "#{config.api.baseUrl}/cumpus/#{$scope.ui.data.type}/#{$scope.ui.content._id}",
        $scope.ui.content
      .then (res) ->
        growl.success '保存成功'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        growl.error '保存失敗'
