angular.module 'app'
.controller 'MaterialLayoutCtrl', ($scope, $http, $stateParams, $location, $state, config, Storage, growl) ->
  $scope.permission = Storage.get('user_permission')

  $scope.ui =
    isDanger: false

  do $scope.getMaterialManageStatus = ->
    if $scope.permission.manage.materialManage && $scope.permission.manage.materialManage.is_use == true
      $http.get "#{config.api.baseUrl}/material/official/usage?access_token=" + Storage.get('access_token'),
        params:
          original_id: $stateParams.original_id
      .then (res) ->
        $scope.ui.isDanger = true if (res.data.news.total / res.data.news.limit) >= 0.9 || (res.data.image.total / res.data.image.limit) >= 0.9 || (res.data.video.total / res.data.video.limit) >= 0.9
      .catch (error) ->

  $scope.disabled = ->
    growl.error '音頻素材暫未開放'
