angular.module 'app'
.controller 'WordCloudCtrl', ($scope, $http, $stateParams, $state, config, Storage, $sce, growl) ->

  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.news.is_use == false

  $scope.ui =
    currentPage: 1
    perPage: 10
    maxSize: 3
    totalItems: 0

    radioModel: '0'
    search: ''

    keywords: []

    test: 60

    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()

    opts: config.opts

  $scope.wordcloud = {}
  $scope.keyWords = []

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#word-cloud'
  $scope.ui.opts.opens = 'right'

  $scope.htmlPopover = $sce.trustAsHtml('<div class="help-popover" style="width: 300px"><dt>關鍵詞</dt><dd>用戶發送文字中所包含的特殊名詞</dd><dt>自定義關鍵詞</dt><dd>公眾號在編輯模式中預先設置的關鍵詞</dd><dt>非自定義關鍵詞</dt><dd>用戶發送消息中，非公眾號在編輯模式中預先設置的關鍵詞</dd><dd class="border-top-solid padding-top-md">數據從2013年7月1日開始統計。由於服務器緩存，以及指標計算方法和統計時間的差異，數據可能出現微小誤差，壹般在1%以內。</dd></div>')

  $scope.wordcloud =
    list: [['', 0]]
    gridSize: 5
    weightFactor: 6
    fontFamily: 'msyh'
    color: 'random-dark'
    backgroundColor: 'transparent'
    rotateRatio: 0

  createWordcloud = ->
    list = _.clone $scope.keyWords
    options =
      list: []
      gridSize: Math.round(16 * 550 / 1024)
      weightFactor: 2
      fontFamily: 'msyh'
      color: 'random-dark'
      backgroundColor: 'transparent'
      rotateRatio: 0
    list = _.map list, (item) ->
      item[1] = item[1]
      item
    options.list = list
    $scope.wordcloud = options

  fetchKeyword = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')

    type = switch $scope.ui.radioModel
      when '0' then 'all'
      when '1' then 'keyword'
      when '2' then 'nokeyword'
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/keyword?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: $scope.ui.currentPage - 1
        start_created_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
        keyword: $scope.ui.search
        type: type
    .then (res) ->
      $scope.keyWords = []
      max = 0
      _.each res.data.statis, (val, index) ->
        val.val = Math.sqrt(val.count) * 10
        max = val.val if index is 0
        val.val = (val.val * 40) / max

      i = 0
      length = if res.data.wordCloud.length <= 30 then res.data.wordCloud.length else 30
      while i < length
        res.data.wordCloud[i].val = Math.sqrt(res.data.wordCloud[i].count) * 10
        max = res.data.wordCloud[i].val if i is 0
        res.data.wordCloud[i].val = res.data.wordCloud[i].val * 40 / max
        $scope.keyWords.push [res.data.wordCloud[i].msg_word.trim(), res.data.wordCloud[i].val]
        i++

      createWordcloud()
      $scope.ui.keywords = res.data.statis
      $scope.ui.totalItems = res.data.count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', fetchKeyword

  $scope.$watch 'ui.date', fetchKeyword

  $scope.search = ->
    fetchKeyword()
