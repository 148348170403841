angular.module 'app'
.controller 'ReplyInformationCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert, $timeout, $uibModalInstance, data, Emoji) ->

  $scope.ui =
    replyContent: ""
    keywords: Emoji.Keywords
    fontLength: 140
    max: 140

  # 回復圖文評論
  $scope.replyComment = ->
    isLengthValid = checkLength()

    return if !isLengthValid

    text = Emoji.replaceText $scope.ui.replyContent

    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/comment?access_token=" + Storage.get('access_token'),
        mass_send_id: data.replyCommentdata.mass_id
        index: data.replyCommentdata.index
        user_comment_ids: [data.replyCommentdata.user_comment_id]
        status: data.status
        content: text
    .then (res) ->
      growl.success "回復成功！" if data.status == 'reply-add'
      $uibModalInstance.close {}
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 移動分組
  $scope.exchangePopover =
    data: null
    templateUrl: 'exchangePopoverTemplate.html'
    putFace: (face) ->
      $scope.ui.replyContent = $scope.ui.replyContent + face.val

  checkLength = ->
    text = Emoji.replaceText $scope.ui.replyContent, true
    length =  text.length

    if length > $scope.ui.max
      growl.error("最多只能輸入140個字")

      return false

    return true


  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
