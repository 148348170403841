angular.module 'app'

  .controller 'FileManageCtrl', ($scope, $stateParams, $state, $rootScope, SweetAlert, Storage, $http, FileUploader, config, growl) ->
    return $state.go 'app.wechat' if Storage.get('user_permission').manage.file.is_use == false

    $scope.ui =
      user: Storage.get('login_user')
      fileUrl: 'http://www.kaoyip.edu.mo/api'
      search: ''
      files: []

      currentPage: 1
      perPage: 10
      maxSize: 8
      totalItems: 0

    fetchWechat = ->
      $http.get "#{config.api.baseUrl}/cumpus/file-manage?access_token=" + Storage.get('access_token'),
        params:
          limit: $scope.ui.perPage
          offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
          name: $scope.ui.search
          group_id: $scope.ui.user.group_id
      .then (res) ->
        _.each res.data.data, (data) ->
          data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        $scope.ui.files = res.data.data
        $scope.ui.totalItems = res.data.count
      .catch (error) ->
        growl.error('網絡連接失敗，請稍後重試')


    $scope.search = ->
      $scope.ui.totalItems = 0
      $scope.ui.files = []
      $scope.ui.currentPage = 1
      fetchWechat()

    $scope.keyUp = (e) ->
      keycode = if window.event then e.keyCode else e.which
      if keycode == 13
        $scope.ui.totalItems = 0
        $scope.ui.files = []
        $scope.ui.currentPage = 1
        fetchWechat()


    uploader = $scope.uploader = new FileUploader(
      autoUpload: true
      removeAfterUpload: true
      queueLimit: 4
      url: "#{config.api.baseUrl}/cumpus/file-manage/upload?access_token=#{Storage.get('access_token')}&created_by=#{$scope.ui.user.id}&group_id=#{$scope.ui.user.group_id}"
    )
    # FILTERS
    uploader.filters.push
      fn: (item, options) ->
        return true

    uploader.onBeforeUploadItem = (item) ->
      growl.info '上傳中!請稍等.', ttl: 3000

    uploader.onSuccessItem = (fileItem, response, status, headers) ->
      fetchWechat()
      growl.success '上傳成功', ttl: 3000

    uploader.onErrorItem = (fileItem, response, status, headers) ->
      fetchWechat()
      growl.error '上傳失敗', ttl: 3000

    $scope.removeFile = (index) ->
      SweetAlert.swal {
        title: "你確定嗎?"
        text: "是否刪除當前文件？"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          $http.delete "#{config.api.baseUrl}/cumpus/file-manage/#{$scope.ui.files[index]._id}?access_token=" + Storage.get('access_token'), {}
          .then (res) ->
            fetchWechat()
            growl.success '刪除成功'
          .catch (error) ->
            growl.error '刪除失敗'

    $scope.$watch 'ui.currentPage', fetchWechat
