angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.response',
      url: '/response'
      templateUrl: 'apps/response/layout/layout'
      controller: 'ResponseLayoutCtrl'

    .state 'app.response.keyword',
      url: '/keyword'
      templateUrl: 'apps/response/keyword/keyword'
      controller: 'KeywordCtrl'

    .state 'app.response.no-matching',
      url: '/no-matching'
      templateUrl: 'apps/response/no-matching/no-matching'
      controller: 'NoMatchingCtrl'

    .state 'app.response.concern',
      url: '/concern'
      templateUrl: 'apps/response/concern/concern'
      controller: 'ConcernCtrl'
