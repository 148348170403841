angular.module 'app'
.controller 'FindInformationCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert, $timeout, $uibModalInstance, data, Emoji) ->

  $scope.ui =
    menuActive: 0
    articletitle: data.articledata.title
    totalItems: 0
    currentPage: 1
    perPage: 7
    maxSize: 8
    commentArr: []
    commentContent: ''
    isSelectAll: false
    isLoading: false
    user_comment_ids: []
    wechats: Storage.get('Wechats')
    wechat: null
    resourcesUrl: config.api.baseUrl


  _.each $scope.ui.wechats, (wechat) ->
    if wechat.original_id == $stateParams.original_id
      $scope.ui.wechat = wechat

  findData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/comment?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        sort: '-created_at'
        mass_send_id: data._id
        index: data.index
        comment_type: 'all'
        content: $scope.ui.commentContent
    .then (res) ->
      _.each res.data.data, (data) ->
        if typeof(data.content) == 'string'
          data.content = Emoji.replaceFace data.content
        if typeof(data.reply?.content) == 'string'
          data.reply.content = Emoji.replaceFace data.reply.content
          data.isShow = true
        else
          data.isShow = false

        data.create_time = moment(data.create_time).format('YYYY-MM-DD HH:mm:ss')
      $scope.ui.commentArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 切换焦点
  $scope.switchMenu = (_index) ->
    $scope.ui.menuActive = _index
    $scope.ui.isSelectAll = false

  # 全选
  $scope.selectAll = ->
    if $scope.ui.isSelectAll == true
      _.each $scope.ui.commentArr, (commentdata) ->
        if !commentdata.isChecked
          commentdata.isChecked = true
          $scope.ui.user_comment_ids.push commentdata.user_comment_id
    else
      _.each $scope.ui.commentArr, (commentdata) ->
        if commentdata.isChecked == true
          commentdata.isChecked = false
          $scope.ui.user_comment_ids = _.filter $scope.ui.user_comment_ids, (user_comment_id) ->
            user_comment_id if commentdata.user_comment_id isnt user_comment_id
  # 单选
  $scope.selectSingle = (data) ->
    if data.isChecked == true
      $scope.ui.user_comment_ids.push data.user_comment_id
    else
      $scope.ui.user_comment_ids = _.filter $scope.ui.user_comment_ids, (user_comment_id) ->
        user_comment_id if data.user_comment_id isnt user_comment_id


  # 回復
  $scope.toReply = (operation,data) ->
    dialogs.create('apps/message-management/reply-information/reply-information', 'ReplyInformationCtrl', {status: operation,replyCommentdata: data}, {size: 'md'})
    .result.then ((option) ->
      findData()
     ), ->

  # 操作評論
  $scope.operationComment = (operation,data) ->
    if data == undefined
      a = []
      _.each $scope.ui.commentArr, (d) ->
        if _.contains($scope.ui.user_comment_ids, d.user_comment_id)
          a.push d
      data =
        mass_id: a[0].mass_id
        index: a[0].index
        user_comment_id: $scope.ui.user_comment_ids
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/comment?access_token=" + Storage.get('access_token'),
        mass_send_id: data.mass_id
        index: data.index
        user_comment_ids: data.user_comment_id
        status: operation
    .then (res) ->
      growl.success "移入精選成功！" if operation == 'markelect'
      growl.success "移出精選成功！" if operation == 'unmarkelect'
      growl.success "刪除成功！" if operation == 'delete'
      growl.success "回復成功！" if operation == 'reply-add'
      growl.success "刪除回復成功！" if operation == 'reply-delete'
      $scope.ui.isSelectAll = false
      $scope.ui.user_comment_ids = []
      findData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 更新評論
  $scope.updateComment = ->
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/comment/update?access_token=" + Storage.get('access_token'),
        mass_send_id: data._id
        index: data.index
    .then (res) ->
      growl.success "更新圖文評論成功！"
      findData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 搜索
  $scope.searchComment = ->
    findData()

  $scope.$watch 'ui.currentPage', findData

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'


