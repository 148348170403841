angular.module 'app'
.controller 'EditAlumniCtrl', ($scope, $http, $uibModalInstance, config, growl, data) ->
  $scope.ui =
    student: angular.copy data.student
    classData: angular.copy data.classData

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $http.put "#{config.api.baseUrl}/cumpus/student/#{$scope.ui.student._id}",
      $scope.ui.student
    .then (res) ->
      growl.success '保存成功'
      $uibModalInstance.close 'Save'
    .catch (error) ->
      growl.error '保存失敗'
