angular.module 'app'
.controller 'RecordFailCtrl', ($scope, $http, $state, $uibModalInstance, config, Storage, FileUploader, growl, data) ->

  $scope.ui =
    reasons: []
    status: ''
    error: ''


  if data.reason
    $scope.ui.reasons = data.reason
    $scope.ui.status = data.status

  if data.error
    $scope.ui.error = data.error

  $scope.cancel = ->
    $uibModalInstance.dismiss 'Cancel'
