angular.module 'app'
.controller 'AdvisoryDataCtrl', ($rootScope, $stateParams, $scope, $http, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').advisory == undefined

  $scope.ui =
    radioModel: 'tour'
    tourData: []
    busLineData: []
    busStationData: []
    parkData: []

    search:
      tour_name: ''

      routeName: ''

      large_area_tc: ''
      area_tc: ''
      stop_address_tc: ''
      stop_alias: ''

      park_name: ''

    large_area_tc: [
        key: 'one'
        val: '澳門'
      ,
        key: 'two'
        val: '氹仔'
      ,
        key: 'three'
        val: '路環'
    ]

    area_tc: [
        key: 'one'
        val: ['新口岸及外港新填海區', '澳門西北區', '澳門東北區', '西望洋山/媽閣區', '澳門市中心', '東望洋山(松山)及若憲山區', '澳門南灣區', '澳門新馬路區']
      ,
        key: 'two'
        val: ['聖母灣區', '機場區', '路氹填海區', '氹仔西北區', '氹仔市中心區', '澳門大學新校區', '氹仔東北區', '氹仔村']
      ,
        key: 'three'
        val: ['石排灣', '九澳', '聯生工業區', '黑沙', '路環村', '竹灣']
    ]

    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6

  getData = ->
    $scope.ui.tourData = []
    $scope.ui.busStationData = []
    $scope.ui.parkData = []

    _params =
      type: $scope.ui.radioModel
      limit: $scope.ui.perPage
      offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage

    _params.tour_name = $scope.ui.search.tour_name if $scope.ui.radioModel == 'tour'

    _params.routeName = $scope.ui.search.routeName if $scope.ui.radioModel == 'bus_line'

    _params.large_area_tc = $scope.ui.large_area_tc[$scope.ui.search.large_area_tc].val if $scope.ui.radioModel == 'bus_station' && $scope.ui.search.large_area_tc != ''
    _params.area_tc = $scope.ui.search.area_tc if $scope.ui.radioModel == 'bus_station'
    _params.stop_address_tc = $scope.ui.search.stop_address_tc if $scope.ui.radioModel == 'bus_station'
    _params.stop_alias = $scope.ui.search.stop_alias if $scope.ui.radioModel == 'bus_station'

    _params.park_name = $scope.ui.search.park_name if $scope.ui.radioModel == 'park'

    $http.get "https://macauzxt-api.ersinfotech.cn/query?access_token=" + Storage.get('access_token'),
      params: _params
    .then (res) ->
      $scope.ui.tourData = res.data.data if $scope.ui.radioModel == 'tour'
      $scope.ui.busLineData = res.data.data if $scope.ui.radioModel == 'bus_line'
      $scope.ui.busStationData = res.data.data if $scope.ui.radioModel == 'bus_station'
      $scope.ui.parkData = res.data.data if $scope.ui.radioModel == 'park'

      if $scope.ui.radioModel == 'park'
        _.each $scope.ui.parkData, (data) ->
          data.data.refresh.date = moment(data.data.refresh.date).format('YYYY-MM-DD HH:mm:ss')

      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.search = ->
    getData()

  $scope.showModal = (data) ->
    _size = 'md'
    _size = 'lg' if $scope.ui.radioModel == 'bus_line'

    dlg = dialogs.create('apps/advisory/advisory-modal/advisory-modal', 'AdvisoryModalCtrl', data, {size: _size})
    dlg.result.then ((option) ->
    ), ->

  $scope.$watch 'ui.radioModel', (newValue, oldValue) ->
    return if newValue == oldValue

    $scope.ui.search.tour_name = ''
    $scope.ui.search.routeName = ''

    $scope.ui.totalItems = 0
    $scope.ui.currentPage = 1
    getData()

  $scope.$watch 'ui.currentPage', getData
