angular.module 'app'
.controller 'EditLinkCtrl', ($scope, $http, $uibModalInstance, Storage, config, growl, data) ->
  $scope.ui =
    user: Storage.get('login_user')
    baseUrl: config.api.baseUrl

    column: angular.copy data
    homePage: {}

  do findHomePage = ->
    $http.get "#{config.api.baseUrl}/cumpus/homepage",
      params:
        group_id: $scope.ui.user.group_id
    .then (res) ->
      if res.data.data.length > 0
        $scope.ui.homePage = res.data.data[0]

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $http.put "#{config.api.baseUrl}/cumpus/programa/#{$scope.ui.column._id}", $scope.ui.column
    .then (res) ->
      growl.success '保存成功'
      $uibModalInstance.close 'Save'
    .catch (error) ->
      growl.error '保存失敗'

    if $scope.ui.homePage.layouts?.length > 0
      _.each $scope.ui.homePage.layouts, (layout) ->
        _.each layout.headMenus, (menu, i) ->
          if menu._id is $scope.ui.column._id
            layout.headMenus[i] = $scope.ui.column
        _.each layout.centerColumns, (menu, i) ->
          if menu._id is $scope.ui.column._id
            layout.centerColumns[i] = $scope.ui.column
        _.each layout.bottomColumns, (menu, i) ->
          if menu._id is $scope.ui.column._id
            layout.bottomColumns[i] = $scope.ui.column
      $http.put "#{config.api.baseUrl}/cumpus/homepage/#{$scope.ui.homePage._id}", {layouts: $scope.ui.homePage.layouts}
      .then (res) ->
        console.log '更新版面設定成功！'
      .catch (error) ->
        console.log '更新版面設定失敗！'
