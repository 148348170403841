angular.module 'app'
.controller 'AdvisoryViewCtrl', ($rootScope, $stateParams, $scope, $http, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').advisory == undefined

  # 模拟数据
  analogData = [
      name: '查公交'
      icon: 'bus'
      iconType: 'font'
      background: 'http://microdata2-api.ersinfotech.com/file/uploadfiles/1/images/191aaf17e89e05ac6b3a864dac9ef763.jpg'
      hasChild: true
      val: [
          name: '线路查询'
          icon: 'http://microdata2-api.ersinfotech.com/file/uploadfiles/logo/public/61a03b42a72e525538512e52902278c9.png'
          iconType: 'img'
          background: ''
          hasChild: false
          api:
            url: 'http://openapi.aibang.com/bus/lines'
            type: 'GET'
            params:
              app_key: ''
              city: ''
              q: ''
        ,
          name: '站点查询'
          icon: 'http://microdata2-api.ersinfotech.com/file/uploadfiles/logo/public/61a03b42a72e525538512e52902278c9.png'
          iconType: 'img'
          background: ''
          hasChild: false
          api:
            url: 'http://openapi.aibang.com/bus/stats'
            type: 'GET'
            params:
              app_key: ''
              city: ''
              q: ''
      ]
    ,
      name: '查天气'
      icon: 'cloud'
      iconType: 'font'
      background: ''
      hasChild: false
      api:
        url: ''
        type: ''
        params: {}
    ,
      name: '查快递'
      icon: 'truck'
      iconType: 'font'
      background: 'http://microdata2-api.ersinfotech.com/file/uploadfiles/1/images/57ad7a2d5e47193b7dc86dec.png'
      hasChild: true
      val: [
          name: '圆通速递'
          icon: 'xxx/xxx.png'
          iconType: 'img'
          background: ''
          hasChild: false
          api:
            url: ''
            type: ''
            params: {}
        ,
          name: '圆通速递'
          icon: 'xxx/xxx.png'
          iconType: 'img'
          background: ''
          hasChild: false
          api:
            url: ''
            type: ''
            params: {}
      ]
  ]

  $scope.ui =
    data:
      name: '資訊通'
      background: 'http://microdata2-api.ersinfotech.com/file/uploadfiles/1/images/bdee8f8ca39d8443b94bf42edb7e7b19.jpg'
      hasChild: true
      val: analogData
    activeView: {}  # 当前所选功能视图
    activeData: {}  # 当前所选功能信息
    activeNum: -1
    level: []  # 层级下标

    isDisableSave: false

  # 获取数据
  do $scope.getData = ->
    $scope.ui.activeView = $scope.ui.data

  # 选择功能
  $scope.switchData = (index) ->
    if index == $scope.ui.activeNum
      $scope.ui.activeData = {}
      $scope.ui.activeNum = -1
      return

    $scope.ui.activeData = $scope.ui.activeView.val[index]
    $scope.ui.activeNum = Number index

  # 添加/管理子项
  $scope.getChild = ->
    if !$scope.ui.activeData.hasChild
      SweetAlert.swal {
        title: "你確定嗎?"
        text: "添加子項將會清空當前功能的API信息！"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          delete $scope.ui.activeData.api
          $scope.ui.activeData.val = []
          $scope.ui.activeData.hasChild = true
          $scope.ui.activeData.background = 'http://microdata2-api.ersinfotech.com/file/uploadfiles/1/images/bdee8f8ca39d8443b94bf42edb7e7b19.jpg' if $scope.ui.activeData.background == ''

          $scope.ui.activeView = $scope.ui.activeData
          $scope.ui.activeData = {}
          $scope.ui.level.push $scope.ui.activeNum
          $scope.ui.activeNum = -1
        else
          return
    else
      $scope.ui.activeView = $scope.ui.activeData
      $scope.ui.activeData = {}
      $scope.ui.level.push $scope.ui.activeNum
      $scope.ui.activeNum = -1

  # 添加功能
  $scope.addData = ->
    $scope.ui.activeView.val.push {name: '名称', icon: 'smile-o', iconType: 'font', background: '', hasChild: false, api: {url: '', type: '', params: {}}}

  # 删除当前功能
  $scope.delete = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否刪除“#{$scope.ui.activeData.name}”功能？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.activeView.val.splice $scope.ui.activeNum, 1
        $scope.ui.activeData = {}
        $scope.ui.activeNum = -1

  # 删除所有功能
  $scope.deleteAll = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否刪除“#{$scope.ui.activeView.name}”中的所有功能？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        if $scope.ui.level.length == 0
          $scope.ui.activeView.val = []
          $scope.ui.activeData = {}
          $scope.ui.activeNum = -1
        else
          delete $scope.ui.activeView.val
          $scope.ui.activeView.hasChild = false
          $scope.ui.activeView.api =
            url: ''
            type: ''
            params: {}
          $scope.backData()

  # 返回上一级
  $scope.backData = ->
    return if $scope.ui.level.length == 0
    $scope.ui.level.splice ($scope.ui.level.length - 1), 1

    $scope.ui.activeView = $scope.ui.data
    $scope.ui.activeData = {}
    $scope.ui.activeNum = -1

    _.each $scope.ui.level, (data) ->
      $scope.ui.activeView = $scope.ui.activeView.val[data]

  # 返回主页
  $scope.backHome = ->
    return if $scope.ui.level.length == 0
    $scope.ui.level = []
    $scope.ui.activeView = $scope.ui.data
    $scope.ui.activeData = {}
    $scope.ui.activeNum = -1

  # 去除api params
  $scope.minusParam = (obj) ->
    return if $scope.ui.activeData.api.params[obj] == undefined
    delete $scope.ui.activeData.api.params[obj]

  # 增加api params
  $scope.plusParam = (obj) ->
    return growl.error '參數名不可為空' if obj == '' || obj == null
    return growl.error '參數名已存在' if $scope.ui.activeData.api.params[obj] != undefined
    $scope.ui.activeData.api.params[obj] = ''

  # 保存
  $scope.save = ->
    console.log $scope.ui.data

 ##############
 # {
 #    name: '资讯通',
 #    background: 'xxx/xxx.png',
 #    val: [
 #        {
 #            name: '查公交',
 #            icon: 'bus',
 #            iconType: 'font',
 #            background: 'xxx/xxx.png',
 #            hasChild: true,
 #            val: [
 #                {
 #                    name: '线路查询',
 #                    icon: 'xxx/xxx.png',
 #                    iconType: 'img',
 #                    background: 'xxx/xxx.png',
 #                    hasChild: false,
 #                    api: {
 #                        url: 'http://openapi.aibang.com/bus/lines',
 #                        type: 'GET',
 #                        params: {
 #                            app_key: '',
 #                            city: '',
 #                            q: ''
 #                        }
 #                    }
 #                },{
 #                    name: '站点查询',
 #                    icon: 'xxx/xxx.png',
 #                    iconType: 'img',
 #                    background: 'xxx/xxx.png',
 #                    hasChild: false,
 #                    api: {
 #                        url: 'http://openapi.aibang.com/bus/stats',
 #                        type: 'GET',
 #                        params: {
 #                            app_key: '',
 #                            city: '',
 #                            q: ''
 #                        }
 #                    }
 #                }
 #            ]
 #        },{
 #            name: '查天气',
 #            icon: 'cloud',
 #            iconType: 'font',
 #            background: 'xxx/xxx.png',
 #            hasChild: false,
 #            api: {}
 #        },{
 #            name: '查快递',
 #            icon: 'truck',
 #            iconType: 'font',
 #            background: 'xxx/xxx.png',
 #            hasChild: false,
 #            api: {}
 #        }
 #    ]
 # }
 ##############
