angular.module 'app'
.controller 'UserLogCtrl', ($scope, $state, $stateParams, $http, Storage, $rootScope, growl, config, dialogs) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').system.log.is_use == false

  $scope.ui =
    isLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    user: ""
    logs: []
    operations: []
    eadminDisplay: config.eadmin.display

    date:
      startDate: moment().subtract(1, 'days')
      endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#material-graphic'
  $scope.ui.opts.opens = 'right'

  do fetchType = ->
    $http.get "#{config.api.baseUrl}/manage/log_usages/operations?access_token=" + Storage.get('access_token'),
      {}
    .then (res) ->
      $scope.ui.operations  = res.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  do fetchUsers = ->
    if $scope.ui.eadminDisplay == true
      users = """
        query(
          $query: UserQueryInput
        ){
          user(
            query: $query
          ){
            count
            data{
              id
              username
              email
              lang
              createdAt
              groupId
              groupName
              lang
              roles(
                query: {}
              ){
                roleId
                groupId
                productId
                roleName
                groupName
                productName
                createdAt
              }
            }
          }
        }
      """
      variables =
        query:
          groupId: String(Storage.get('login_user').group_id)
          productId: String(config.eadmin.clientId)
          limit: 1000
      $http.post "#{config.eadmin.newBaseUrl}/graphql?access_token=" + Storage.get('access_token'),
        query: users
        variables: variables
      .then (res) ->
        $scope.ui.users = res.data.data.user.data
    else
      $http.get "#{config.eadmin.baseUrl}/account/groups/#{Storage.get('login_user').group_id}/users?access_token=" + Storage.get('access_token') + "&product_id=#{config.eadmin.clientId}",
        params:
          limit: 1000
      .then (res) ->
        $scope.ui.users = res.data.data

  fetchLogs = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/manage/log_usages?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
        user_id: $scope.ui.user
        start_date: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00')
        end_date: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59')
        group_id: Storage.get('login_user').group_id
        operation: $scope.ui.activeCountry
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.isLoading = false
      $scope.ui.logs = []
      _.each res.data.data, (val) ->
        val.created_at = moment(val.created_at).format('YYYY-MM-DD HH:mm')
        _.each val.process, (valProcess) ->
          valProcess.created_at = moment(val.created_at).format('YYYY-MM-DD HH:mm')
        $scope.ui.logs.push val
        $scope.ui.logs.splice $scope.ui.logs.length, 0, {show: false, value: val}
      $scope.ui.totalItems = res.data.count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', fetchLogs

  $scope.search = ->
    fetchLogs()

  $scope.openData = (_index) ->
    $scope.ui.logs[_index].show = !$scope.ui.logs[_index].show

