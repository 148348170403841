angular.module 'app'
.controller 'WechatTopCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').monitor.top.is_use == false

  $scope.ui =
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    dataArr: []
    activeDataArr: []
    activeSort: ''
    region: ''
    date:
      startDate: moment().subtract(6, 'days')
      endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#wechat-top-monitor'
  $scope.ui.opts.opens = 'right'
  $scope.ui.opts.eventHandlers =
    'apply.daterangepicker': (ev, picker) ->
      growl.error '時間範圍不能超過七天' if $scope.ui.date.endDate.format('YYYY-MM-DD') > moment($scope.ui.date.startDate).add(6, 'days').format('YYYY-MM-DD')
      $scope.ui.date =
        startDate: moment($scope.ui.date.startDate)
        endDate: moment($scope.ui.date.startDate).add(6, 'days')

  $scope.findData = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(6, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')

    $scope.ui.dataArr = []
    $scope.ui.activeDataArr = []
    $scope.ui.totalItems = 0
    $http.get "#{config.api.baseUrl}/monitor/ranking/wechat?access_token=" + Storage.get('access_token'),
      params:
        start_date: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_date: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
        region: $scope.ui.region
    .then (res) ->
      $scope.ui.dataArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.$watch 'ui.currentPage', (newData) ->
        return if $scope.ui.dataArr.length == 0
        $scope.ui.activeDataArr = $scope.ui.dataArr.slice((newData - 1) * $scope.ui.perPage, (newData - 1) * $scope.ui.perPage + $scope.ui.perPage)
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.sort = (_name) ->
    return if $scope.ui.dataArr.length == 0
    $scope.ui.dataArr.sort (a, b) ->
      if $scope.ui.activeSort != _name
        return b.total_read_num - (a.total_read_num) if _name == 'total_read_num'
        return b.total_like_num - (a.total_like_num) if _name == 'total_like_num'
        return b.average_read_num - (a.average_read_num) if _name == 'average_read_num'
        return b.average_like_num - (a.average_like_num) if _name == 'average_like_num'
        return b.e_index - (a.e_index) if _name == 'e_index'
      else
        return a.total_read_num - (b.total_read_num) if _name == 'total_read_num'
        return a.total_like_num - (b.total_like_num) if _name == 'total_like_num'
        return a.average_read_num - (b.average_read_num) if _name == 'average_read_num'
        return a.average_like_num - (b.average_like_num) if _name == 'average_like_num'
        return a.e_index - (b.e_index) if _name == 'e_index'
    $scope.ui.activeDataArr = $scope.ui.dataArr.slice(($scope.ui.currentPage - 1) * $scope.ui.perPage, ($scope.ui.currentPage - 1) * $scope.ui.perPage + $scope.ui.perPage)
    if $scope.ui.activeSort != _name
      $scope.ui.activeSort = _name
    else
      $scope.ui.activeSort = ''

  $scope.findData()


  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.activeDataArr = []
      $scope.ui.currentPage = 1
      $scope.findData()
