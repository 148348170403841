angular.module("app")
.directive 'btn', ->
  restrict: 'C'
  link: (scope, element) ->
    if element.hasClass('btn-icon') or element.hasClass('btn-float')
      Waves.attach element, [ 'waves-circle' ]
    else if element.hasClass('btn-light')
      Waves.attach element, [ 'waves-light' ]
    else
      Waves.attach element
    Waves.init()

.directive 'lightgallery', ->
  restrict: 'A'
  link: (scope, element, attrs) ->
    if scope.$last
      element.parent().lightGallery({thumbnail: true})

.directive 'contenteditable', ->
  require: '?ngModel'
  link: (scope, element, attrs, ctrl) ->
    return if !ctrl
    element.bind 'input enterKey', ->
      rerender = false
      html = if attrs.ishtml then element.html() else element.text()
      if attrs.noLineBreaks
        # html = html.replace(/<div>/g, '').replace(/<br>/g, '').replace(/<\/div>/g, '')
        rerender = true
      scope.$apply ->
        ctrl.$setViewValue html
        ctrl.$render() if rerender
    element.keyup (e) ->
      element.trigger('enterKey') if e.keyCode == 13
    ctrl.$render = ->
      element.html(ctrl.$viewValue)
    ctrl.$render()
