angular.module 'app'
.controller 'GroupAddMemberCtrl', ($scope, $http, $state, $uibModalInstance, config, Storage, growl, data) ->
  $scope.ui =
    thisUrl: window.location.origin
    user: Storage.get('login_user')
    isLoading: false
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    canAddUsers: []
    isAddUsers: []
    selected: ''
    states: []

  findUserList = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/manage/group_manage/getmembers?access_token=" + Storage.get('access_token'),
      params:
        wechat_id: data.wechat_id
    .then (res) ->
      $scope.ui.users = res.data.can_add
      $scope.ui.canAddUsers = _.clone $scope.ui.users
      $scope.ui.isAddUsers = res.data.is_add
      $scope.ui.isLoading = false
      $scope.$watch 'ui.selected', ->
        if $scope.ui.selected.id != undefined
          $scope.ui.canAddUsers = []
          $scope.ui.canAddUsers.push($scope.ui.selected)
        else
          $scope.ui.canAddUsers = _.clone $scope.ui.users
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  $scope.select = (_index) ->
    $scope.ui.isAddUsers.push $scope.ui.canAddUsers[_index]
    _.each $scope.ui.users, (data, i) ->
      if i < $scope.ui.users.length
        $scope.ui.users.splice i, 1 if $scope.ui.canAddUsers[_index].id == data.id
    $scope.ui.canAddUsers = _.clone $scope.ui.users

  $scope.enSelect = (_index) ->
    $scope.ui.users.unshift $scope.ui.isAddUsers[_index]
    $scope.ui.canAddUsers = _.clone $scope.ui.users
    $scope.ui.isAddUsers.splice _index, 1

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $http.post "#{config.api.baseUrl}/manage/group_manage?access_token=" + Storage.get('access_token'),
      wechat_id: data.wechat_id
      user_ids: $scope.ui.isAddUsers
      user_group_id: $scope.ui.user.group_id
    .then (res) ->
      growl.success '保存成功'
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findUserList()
