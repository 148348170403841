angular.module 'app'
.controller 'WechatChooseCtrl', ($scope, $http, $state, $uibModalInstance, config, Storage, growl, data) ->
  $scope.ui =
    search: ""
    type: ""
    isLoading: false
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 3
    wechats: []
    activeWechat: []

  do fetchChoosen = ->
    $http.get "#{config.api.baseUrl}/monitor/wechat?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: Storage.get('login_user').group_id
        is_add: true
    .then (resSelect) ->
      $scope.ui.activeWechat = resSelect.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findWechatList = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/monitor/wechat?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        gzh_name: $scope.ui.search
        type: $scope.ui.type
        is_add: false
        user_group_id: Storage.get('login_user').group_id
    .then (res) ->
      $scope.ui.wechats = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  $scope.$watch 'ui.currentPage', findWechatList

  $scope.search = ->
    findWechatList()

  $scope.select = (_index) ->
    $scope.ui.activeWechat.push $scope.ui.wechats[_index]
    $scope.ui.wechats.splice _index, 1

  $scope.enSelect = (_index) ->
    $scope.ui.wechats.push $scope.ui.activeWechat[_index]
    $scope.ui.activeWechat.splice _index, 1

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    wechat_id_list = []
    _.each $scope.ui.activeWechat, (val) ->
      wechat_id_list.push val._id
    return growl.error '最多可選擇10個帳號' if wechat_id_list.length > 10
    $http.post "#{config.api.baseUrl}/monitor/wechat/addbylist?access_token=" + Storage.get('access_token'),
      is_add: true
      user_group_id: Storage.get('login_user').group_id
      wechat_id_list: wechat_id_list
    .then (res) ->
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
