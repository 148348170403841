angular.module 'app'
.controller 'WechatMonitorCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').monitor.wechat.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    isGroupLoading: false
    isLoading: false
    yesterdayDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
    wechatList: []  # 已添加公众号列表
    activeWechatNum: 0  # 监听公众号数量
    yesterdayList: []  # 昨日数据列表
    trendList: []
    activeTrend: 'e_index'
    activeSort: ''
    switchKey: 1
    top5List: []
    top5AllList: []
    date:
      startDate: moment().subtract(6, 'days')
      endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#view-wechat-monitor'
  $scope.ui.opts.opens = 'left'

  # 圖表
  $scope.chartConfig =
    title:
      text: ''
    tooltip:
      trigger: 'axis'
    legend:
      x: 'center'
      y: 'bottom'
      data: ['']
    grid:
      top: 35
      left: 35
      right: 35
      containLabel: true
    toolbox:
      show: false
    xAxis: [
        type: 'category'
        boundaryGap: false
        splitLine:
          show: false
        data: ['']
    ]
    yAxis: [
        type: 'value'
    ]
    series: []

  # 打開choose
  $scope.chooseWechat = ->
    dlg = dialogs.create('apps/monitor/wechat-monitor/choose/choose', 'WechatChooseCtrl', {}, {size: 'lg'})
    dlg.result.then ((wechats) ->
      findWechatList()
    ), ->

  # 查询已添加到监测账号的列表
  findWechatList = ->
    $scope.ui.isGroupLoading = true
    $scope.ui.activeWechatNum = 0
    $http.get "#{config.api.baseUrl}/monitor/wechat?access_token=" + Storage.get('access_token'),
      params:
        is_add: true
        user_group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.wechatList = res.data.data

      _.each $scope.ui.wechatList, (data) ->
        $scope.ui.activeWechatNum++ if data.is_monitor == true

      findYesterdayData()

      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isGroupLoading = false

  # 查询昨日数据
  findYesterdayData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/monitor/yesterday?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.yesterdayList = res.data.data
      $scope.findTrendData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.findTrendData()

  # 昨日数据排序
  $scope.sort = (_name) ->
    return if $scope.ui.yesterdayList.length == 0
    $scope.ui.yesterdayList.sort (a, b) ->
      if $scope.ui.activeSort != _name
        return b.total_read_num - (a.total_read_num) if _name == 'total_read_num'
        return b.total_like_num - (a.total_like_num) if _name == 'total_like_num'
        return b.average_read_num - (a.average_read_num) if _name == 'average_read_num'
        return b.average_like_num - (a.average_like_num) if _name == 'average_like_num'
        return b.e_index - (a.e_index) if _name == 'e_index'
        return b.sw_article_num - (a.sw_article_num) if _name == 'sw_article_num'
      else
        return a.total_read_num - (b.total_read_num) if _name == 'total_read_num'
        return a.total_like_num - (b.total_like_num) if _name == 'total_like_num'
        return a.average_read_num - (b.average_read_num) if _name == 'average_read_num'
        return a.average_like_num - (b.average_like_num) if _name == 'average_like_num'
        return a.e_index - (b.e_index) if _name == 'e_index'
        return a.sw_article_num - (b.sw_article_num) if _name == 'sw_article_num'
    if $scope.ui.activeSort != _name
      $scope.ui.activeSort = _name
    else
      $scope.ui.activeSort = ''

  # 处理指标趋势数据
  setTrendData = ->
    $scope.chartConfig.series = []
    $scope.chartConfig.legend.data = []
    $scope.chartConfig.xAxis[0].data = []
    _.each $scope.ui.trendList, (data, i) ->
      $scope.chartConfig.xAxis[0].data.push(data.date)
      _.each data.data, (eData, j) ->
        if i == 0
          $scope.chartConfig.legend.data.push(eData.gzh_name)
          $scope.chartConfig.series.push({name: eData.gzh_name, type: 'line', data: [eData.e_index]}) if $scope.ui.activeTrend == 'e_index'
          $scope.chartConfig.series.push({name: eData.gzh_name, type: 'line', data: [eData.release_num]}) if $scope.ui.activeTrend == 'release_num'
          $scope.chartConfig.series.push({name: eData.gzh_name, type: 'line', data: [eData.article_num]}) if $scope.ui.activeTrend == 'article_num'
          $scope.chartConfig.series.push({name: eData.gzh_name, type: 'line', data: [eData.average_read_num]}) if $scope.ui.activeTrend == 'average_read_num'
          $scope.chartConfig.series.push({name: eData.gzh_name, type: 'line', data: [eData.average_like_num]}) if $scope.ui.activeTrend == 'average_like_num'
          $scope.chartConfig.series.push({name: eData.gzh_name, type: 'line', data: [eData.total_read_num]}) if $scope.ui.activeTrend == 'total_read_num'
          $scope.chartConfig.series.push({name: eData.gzh_name, type: 'line', data: [eData.total_like_num]}) if $scope.ui.activeTrend == 'total_like_num'
          $scope.chartConfig.series.push({name: eData.gzh_name, type: 'line', data: [eData.sw_article_num]}) if $scope.ui.activeTrend == 'sw_article_num'
        else
          $scope.chartConfig.series[j].data.push(eData.e_index) if $scope.ui.activeTrend == 'e_index'
          $scope.chartConfig.series[j].data.push(eData.release_num) if $scope.ui.activeTrend == 'release_num'
          $scope.chartConfig.series[j].data.push(eData.article_num) if $scope.ui.activeTrend == 'article_num'
          $scope.chartConfig.series[j].data.push(eData.average_read_num) if $scope.ui.activeTrend == 'average_read_num'
          $scope.chartConfig.series[j].data.push(eData.average_like_num) if $scope.ui.activeTrend == 'average_like_num'
          $scope.chartConfig.series[j].data.push(eData.total_read_num) if $scope.ui.activeTrend == 'total_read_num'
          $scope.chartConfig.series[j].data.push(eData.total_like_num) if $scope.ui.activeTrend == 'total_like_num'
          $scope.chartConfig.series[j].data.push(eData.sw_article_num) if $scope.ui.activeTrend == 'sw_article_num'

  # 查询指标趋势
  $scope.findTrendData = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(6, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')

    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/monitor/trend?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        start_date: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_date: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      $scope.ui.trendList = res.data.data
      setTrendData()
      top5()
    .finally ->
      $scope.$watch 'ui.activeTrend', setTrendData
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      top5()

  # 选项卡切换
  $scope.switch = (key) ->
    return if $scope.ui.switchKey == key
    $scope.ui.switchKey = key

  top5 = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/monitor/top5/wechat?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.top5List = res.data.data
      _.each $scope.ui.top5List, (data) ->
        _.each data.articles, (obj) ->
          obj.date = moment(obj.date).format('YYYY-MM-DD HH:mm:ss')
      top5All()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      top5All()

  top5All = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/monitor/top5/all?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.top5AllList = res.data.data
      _.each $scope.ui.top5AllList, (obj) ->
        obj.date = moment(obj.date).format('YYYY-MM-DD HH:mm:ss')
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  # 监听/取消
  $scope.setMonitor = (_index) ->
    return if $scope.ui.wechatList.length < 1

    _is_monitor = !$scope.ui.wechatList[_index].is_monitor
    return if $scope.ui.activeWechatNum == 1 && _is_monitor == false

    $http.put "#{config.api.baseUrl}/monitor/wechat/#{$scope.ui.wechatList[_index]._id}?access_token=" + Storage.get('access_token'),
      is_monitor: _is_monitor
      user_group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.activeWechatNum++ if res.status == 200 && _is_monitor == true
      $scope.ui.activeWechatNum-- if res.status == 200 && _is_monitor == false
      $scope.ui.wechatList[_index].is_monitor = _is_monitor if res.status == 200
      findYesterdayData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findWechatList()


  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.findTrendData()
