angular.module 'app'
.controller 'UploadFileCampusCtrl', ($scope, $stateParams, $uibModalInstance, data, growl, config, FileUploader, Storage, $http) ->
  $scope.ui =
    user: Storage.get('login_user')
    type: angular.copy data.type
    material_group_id: angular.copy data.material_group_id
    waterMark: false
    _filters: []

  # end cancel
  $scope.cancel = ->
    $uibModalInstance.close 'Cancle'

  uploader = $scope.uploader = new FileUploader(
    removeAfterUpload: true
    queueLimit: 10
    url: "#{config.api.baseUrl}/cumpus/upload/file?programa_id=#{data._id}&group_id=#{$scope.ui.user.group_id}&type=image"
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 0.5
        growl.error("圖片大小不能超過0.5M")
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1


  uploader.onBeforeUploadItem = (item) ->

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    _tip = "上傳成功！"
    _tip = _tip + "正在打水印..." if $scope.ui.waterMark
    growl.success(_tip)

    if $scope.ui.waterMark
      $http.post "#{config.api.baseUrl}/material/article/wm?access_token=" + Storage.get('access_token'),
        material_id: response.material_id
        original_id: $stateParams.original_id
      .then (res) ->
        growl.success('打水印成功！')
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error("上傳失敗！")





