angular.module 'app'
.controller 'MaterialModalCtrl', ($uibModalInstance, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, data) ->

  $scope.ui =
    radio: data.radio
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    ungroups: [
        name: '全部'
        _id: ''
      ,
        name: '未分組'
        _id: '0'
    ]
    activeGroup:
      name: '全部'
      _id: ''
    saveState: false
    search:
      text: ''

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.$watch 'ui.currentPage', findDatas
    findGroups()
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/material/article/articleinfo?access_token=" + Storage.get('access_token'),
      params:
        type: "news"
        title: $scope.ui.search.text
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data
      _.each $scope.ui.materials, (material) ->
        material.isSelect = false
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/material/group?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        type: 'news'
        limit: 100
    .then (res) ->
      $scope.ui.ungroups[0].material_count = res.data.total
      $scope.ui.ungroups[1].material_count = res.data.no_group
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.setSelect = (material) ->
    material.isSelect = !material.isSelect
    if $scope.ui.radio is true
      _.each $scope.ui.materials, (material1) ->
        if material1._id isnt material._id then material1.isSelect = false

  #
  getMaterialId = (_id, _wechatId, callback) ->
    $http.put "#{config.api.baseUrl}/material/uploadWechat/#{_id}?access_token=" + Storage.get('access_token'),
      wechat_id: _wechatId
    .then (res) ->
      callback(res.data)
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      callback('err')

  $scope.searchText = ->
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    materials = _.where($scope.ui.materials, {isSelect: true})
    if materials.length < 1
       #  == true
      $scope.ui.saveState = false
      growl.error("請至少選擇一篇圖文或取消")
      return
    if materials[0].articles.length > 1 && data.prompt
      SweetAlert.swal {
        title: "你確定嗎?"
        text: "圖文數量超出限制，微信僅回覆第1條。"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          $scope.ui.saveState = true

          $uibModalInstance.close materials
    else
      $scope.ui.saveState = true

      $uibModalInstance.close materials
