angular.module 'app'
.controller 'UserGroupCtrl', ($scope, $stateParams, $rootScope, growl, config, Storage, $http, dialogs) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').system.user.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.api.baseUrl

    wechats: []

  getWechats = ->
    $http.get "#{config.api.baseUrl}/manage/group_manage?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
    .then (res) ->
      _.each res.data.data, (data) ->
        data.versionname = if data.version is '0' then '內地版' else '海外版'
        data.typename = '訂閱號' if data.type == '0'
        data.typename = '服務號' if data.type == '1'
        data.typename = '企業號' if data.type == '2'
      $scope.ui.wechats = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.editModal = (wechat) ->
    dlg = dialogs.create('apps/user/group/add-member/add-member', 'GroupAddMemberCtrl', {wechat_id: wechat._id}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      getWechats()
      $scope.$emit "changeWechat"
    ), ->

  getWechats()
