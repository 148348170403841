angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.information',
      url: '/information'
      templateUrl: 'apps/information/layout/layout'
      controller: 'InformationLayoutCtrl'

    .state 'app.information.new-task',
      url: '/new-task'
      templateUrl: 'apps/information/new-task/new-task'
      controller: 'NewTaskCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    .state 'app.information.examine',
      url: '/examine'
      templateUrl: 'apps/information/examine/examine'
      controller: 'ExamineCtrl'

    .state 'app.information.record',
      url: '/record'
      templateUrl: 'apps/information/record/record'
      controller: 'RecordCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])
