angular.module 'app'
.controller 'PersonalizedMenuCtrl', ($rootScope, $stateParams, $scope, $http, $timeout, $state, config, Storage, growl, dialogs, SweetAlert, findRules) ->

  $scope.ui =
    totalItems: 0
    currentPage: 1
    perPage: 7
    maxSize: 8
    individualization: []
    isLoading: true
    matchrule: {}

  # 获取个性化菜单
  findIndividualization = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        sort: '-created_at'
    .then (res) ->
      $scope.ui.individualization = res.data.data
      $scope.ui.totalItems = res.data.count
      findRules()
      .then (resRule) ->
        _.each res.data.data, (data) ->
          data.matchrule.country = data.matchrule.country
          data.matchrule.province = data.matchrule.province
          data.matchrule.city = data.matchrule.city
          $scope.ui.matchruleList = resRule
          _.each $scope.ui.matchruleList[0].Val, (data1) ->
            data.matchrule.tag = data1.key if data.matchrule.tag == data1.value
          _.each $scope.ui.matchruleList[2].Val, (data2) ->
            data.matchrule.language = data2.key if data.matchrule.language == data2.value
          _.each $scope.ui.matchruleList[3].Val, (data3) ->
            data.matchrule.sex = data3.key if data.matchrule.sex == data3.value
          _.each $scope.ui.matchruleList[4].Val, (data4) ->
            data.matchrule.client_platform_type = data4.key if data.matchrule.client_platform_type == data4.value
          data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
          $scope.ui.matchrule = data.matchrule


    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 修改
  $scope.editPersonality = (data) ->
    $state.go("app.menumanagement.menu-matching", {_id: data._id})


  # 刪除
  $scope.deletePersonality = (data) ->
    SweetAlert.swal {
      title: "確定刪除此個性化菜單?"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu/#{data._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findIndividualization()
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.addPersonalized = ->
    dialogs.create('apps/features/add-personalized/add-personalized', 'AddPersonalizedCtrl', {}, {size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $state.go("app.menumanagement.menu-matching", {_id: option.data_id})
     ), ->


  $scope.findTest = ->
    dlg = dialogs.create('apps/features/select-fans/select-fans', 'SelectFansCtrl', {text: '請選擇1名粉絲進行測試'}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $state.go("app.menumanagement.menu-test", {openid: option.openid})
    ), ->

  $scope.testPersonality = (data) ->
    dlg = dialogs.create('apps/features/select-fans/select-fans', 'SelectFansCtrl', {text: '請選擇1名粉絲進行測試', menu_id: data._id}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $state.go("app.menumanagement.menu-test", {menu_id: option.menu_id, openid: option.openid})
    ), ->


  $scope.$watch 'ui.currentPage', findIndividualization
