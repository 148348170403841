angular.module 'app'
.controller 'EditPictureCtrl', ($rootScope, data, $uibModalInstance, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert) ->

  $scope.ui =
    baseUrl: config.api.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    picture: data.image

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $http.put "#{config.api.baseUrl}/cumpus/programa/list/#{$scope.ui.picture._id}", $scope.ui.picture
    .then (res) ->
      growl.success '保存成功！', ttl: 2000
      $uibModalInstance.close 'Save'
    .catch (error) ->
      growl.error '保存失敗！', ttl: 2000