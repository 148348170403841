
angular.module 'app'

  .config ($provide) ->

    $provide.decorator '$rootScope', ['$delegate', ($delegate) ->

      Object.defineProperty $delegate.constructor.prototype, '$onRootScope',
        enumerable: false
        value: (name, listener) ->
          unbind = $delegate.$on name, listener
          @$on '$destroy', unbind

      $delegate
    ]
