angular.module 'app'
.controller 'MemberListCtrl', ($scope, $stateParams, $http, Storage, $rootScope, growl, config, dialogs, $state) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').system.user.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    product_id: config.eadmin.clientId
    eadminDisplay: config.eadmin.display

    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0

    username: ''
    members: []
    isListLoading: false
    query: {}

  findUsers = ->
    if $scope.ui.eadminDisplay == true
      $scope.ui.isListLoading = true
      users = """
        query(
          $query: UserQueryInput
        ){
          user(
            query: $query
          ){
            count
            data{
              id
              username
              email
              lang
              createdAt
              groupId
              groupName
              lang
              status
              roles(
                query: {}
              ){
                roleId
                groupId
                productId
                roleName
                groupName
                productName
                createdAt
              }
            }
          }
        }
      """
      variables =
        query:
          groupId: String($scope.ui.user.group_id)
          productId: String($scope.ui.product_id)
          q: $scope.ui.username
          limit: $scope.ui.perPage
          offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      $http.post "#{config.eadmin.newBaseUrl}/graphql?access_token=" + Storage.get('access_token'),
        query: users
        variables: variables
      .then (res) ->
        $scope.ui.totalItems = res.data.data.user.count
        $scope.ui.members = res.data.data.user.data
        _.each $scope.ui.members, (val) ->
          val.lang_name = '簡體' if val.lang == 'cn'
          val.lang_name = 'English' if val.lang == 'en'
          val.lang_name = '繁體' if val.lang == 'tw' || val.lang == 'hk'
          val.createdAt = moment(val.createdAt).format('YYYY-MM-DD')
        $scope.ui.isListLoading = false
      .catch (error) ->
        growl.error('網絡連接失敗，請稍後重試')
    else
      $scope.ui.isListLoading = true
      $http.get "#{config.eadmin.baseUrl}/account/groups/#{$scope.ui.user.group_id}/users?access_token=" + Storage.get('access_token') + "&product_id=#{$scope.ui.product_id}",
        params:
          username: $scope.ui.username
          limit: $scope.ui.perPage
          offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      .then (res) ->
        $scope.ui.totalItems = res.data.count
        $scope.ui.members = res.data.data
        _.each $scope.ui.members, (val) ->
          val.lang_name = '簡體' if val.lang == 'cn'
          val.lang_name = 'English' if val.lang == 'en'
          val.lang_name = '繁體' if val.lang == 'tw'
          val.created_at = moment(val.created_at).format('YYYY-MM-DD')
        $scope.ui.isListLoading = false
      .catch (error) ->
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', (newValue) ->
    findUsers()

  $scope.search = ->
    $scope.ui.currentPage = 1
    findUsers()

  $scope.edit = (member) ->
    dlg = dialogs.create('apps/user/member/edit/edit', 'UserMemberEditCtrl', {member: member, title: 'edit'}, {size: 'md'})
    dlg.result.then ((option) ->
      findUsers()
    ), ->

  # $scope.addUser = ->
  #    modalInstance = $modal.open
  #     templateUrl: "modules/users/user/member/edit/edit"
  #     controller: "UserMemberEditCtrl"
  #     resolve:
  #       title: -> 'add'
  #       member: -> {}

    # modalInstance.close = (reason) ->
    #   findUsers()
