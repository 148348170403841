angular.module 'app'
.controller 'SingleGraphicCtrl', ($uibModalInstance, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, data) ->
  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    ungroups: [
        name: '全部'
        _id: ''
      ,
        name: '未分組'
        _id: '0'
    ]
    activeGroup:
      name: '全部'
      _id: ''
    saveState: false
    search:
      text: ''
    activeArticle: {}

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.$watch 'ui.currentPage', findDatas
    findGroups()
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/material/article/articleinfo?access_token=" + Storage.get('access_token'),
      params:
        type: "news"
        title: $scope.ui.search.text
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data
      _.each $scope.ui.materials, (material) ->
        _.each material.articles, (article) ->
          article.isSelect = false
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/material/group?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        type: 'news'
        limit: 100
    .then (res) ->
      $scope.ui.ungroups[0].material_count = res.data.total
      $scope.ui.ungroups[1].material_count = res.data.no_group
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.searchText = ->
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.setSelect = (article) ->
    if article.isSelect == true
      article.isSelect = false
      $scope.ui.activeArticle = {}
      return

    $scope.ui.activeArticle = {}
    _.each $scope.ui.materials, (material) ->
      _.each material.articles, (obj) ->
        obj.isSelect = false

    article.isSelect = true
    $scope.ui.activeArticle = article

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $scope.ui.saveState = true

    _isEmpty = true
    for key of $scope.ui.activeArticle
      _isEmpty = false

    if _isEmpty
      $scope.ui.saveState = false
      growl.error("請至少選擇一篇圖文或取消")
      return

    $uibModalInstance.close $scope.ui.activeArticle
