angular.module 'app'
.controller 'MaterialTemplateModalCtrl', ($uibModalInstance, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, data, CreateHtml) ->
  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    ungroups: [
        name: '全部'
        _id: ''
      ,
        name: '未分組'
        _id: '0'
    ]
    activeGroup:
      name: '全部'
      _id: ''
    saveState: false

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.$watch 'ui.currentPage', findDatas
    findGroups()
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/ers_material/article/template?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        type: "news_template"
        material_group_id: $scope.ui.activeGroup._id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (data) ->
        data.content_top_html = CreateHtml.toHtml data.content_top
        data.content_bottom_qr_html = CreateHtml.toHtml data.content_bottom_qr
        data.content_bottom_ro_html = CreateHtml.toHtml data.content_bottom_ro
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢分組素材總數
  countMaterial = (group) ->
    $http.get "#{config.api.baseUrl}/ers_material/article/template?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        material_group_id: group._id
        type: 'news_template'
    .then (res) ->
      group.totalItems = res.data.count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/material/group?access_token=" + Storage.get('access_token'),
      params:
        material_group_id: $scope.ui.activeGroup._id
        type: 'news_template'
        user_group_id: $scope.ui.user.group_id
        limit: 100
    .then (res) ->
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
      _.each $scope.ui.groups, (group) ->
        countMaterial(group)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.setSelect = (material) ->
    material.isSelect = !material.isSelect

    _.each $scope.ui.materials, (material1) ->
      if material1._id isnt material._id then material1.isSelect = false

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $scope.ui.saveState = true

    materials = _.where($scope.ui.materials, {isSelect: true})

    if materials.length < 1
      $scope.ui.saveState = false
      growl.error("請至少選擇一套圖文模板或取消")
      return

    _obj =
      content_top: materials[0].content_top
      content_bottom_qr: materials[0].content_bottom_qr
      content_bottom_ro: materials[0].content_bottom_ro

    $uibModalInstance.close _obj
