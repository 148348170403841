angular.module 'app'
.controller 'MaterialTemplateCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, CreateHtml) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').manage.materialTemplate.is_use == false

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    ungroups: [
        name: '全部'
        _id: ''
      ,
        name: '未分組'
        _id: '0'
    ]
    activeGroup:
      name: '全部'
      _id: ''
    search:
      text: ''
    date: ''
      # startDate: moment().subtract(29, 'days')
      # endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#material-template'
  $scope.ui.opts.opens = 'right'

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    findGroups()
    $scope.$watch 'ui.currentPage', findDatas
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    _start_created_at = ''
    _end_created_at = ''

    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      _start_created_at = ''
      _end_created_at = ''
    else
      _start_created_at = moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
      _end_created_at = moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')

    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/ers_material/article/template?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        type: "news_template"
        name: $scope.ui.search.text
        material_group_id: $scope.ui.activeGroup._id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_created_at: _start_created_at
        end_created_at: _end_created_at
    .then (res) ->
      _.each res.data.data, (data) ->
        if !config.api.display
          data.content_top = data.content_top.replace(/http\:\/\/microdata2-api\.ersinfotech\.com\/imgserve\?\_url\=/g, '') if data.content_top
          data.content_bottom_qr = data.content_bottom_qr.replace(/http\:\/\/microdata2-api\.ersinfotech\.com\/imgserve\?\_url\=/g, '') if data.content_bottom_qr
          data.content_bottom_ro = data.content_bottom_ro.replace(/http\:\/\/microdata2-api\.ersinfotech\.com\/imgserve\?\_url\=/g, '') if data.content_bottom_ro
        data.content_top_html = CreateHtml.toHtml data.content_top
        data.content_bottom_qr_html = CreateHtml.toHtml data.content_bottom_qr
        data.content_bottom_ro_html = CreateHtml.toHtml data.content_bottom_ro
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/material/group?access_token=" + Storage.get('access_token'),
      params:
        material_group_id: $scope.ui.activeGroup._id
        type: 'news_template'
        user_group_id: $scope.ui.user.group_id
        limit: 100
    .then (res) ->
      $scope.ui.ungroups[0].material_count = res.data.total
      $scope.ui.ungroups[1].material_count = res.data.no_group
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  # 新增模板
  $scope.newGraphic = ->
    dlg = dialogs.create('apps/material/template/detail/detail', 'MaterialTemplateDetailCtrl', {group_id: $scope.ui.activeGroup._id}, {size: 'lg'})
    dlg.result.then ((option) ->
      findDatas()
      findGroups()
    ), ->

  # 修改模板
  $scope.editMaterial = (_material) ->
    dlg = dialogs.create('apps/material/template/detail/detail', 'MaterialTemplateDetailCtrl', {material: _material}, {size: 'lg'})
    dlg.result.then ((option) ->
      findDatas()
      findGroups()
    ), ->

  # 刪除模板
  toDel = (_id) ->
    $http.delete "#{config.api.baseUrl}/ers_material/article/template/#{_id}?access_token=" + Storage.get('access_token') + "&wechat_id=#{$scope.ui.wechat._id}", {}
    .then (res) ->
      findDatas()
      findGroups()
      growl.success "刪除成功！"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.delMaterial = (material_id) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除該模板？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        toDel(material_id)

  # 新建分組
  $scope.groupPopover =
    isOpen: false
    title: "請填寫分組名稱"
    name: ''
    templateUrl: 'groupPopoverTemplate.html'
    open: ->
      $scope.groupPopover.isOpen = true
    close: ->
      $scope.groupPopover.isOpen = false
    save: ->
      $http.post "#{config.api.baseUrl}/material/create/group?access_token=" + Storage.get('access_token'),
        wechat_id: $stateParams.original_id
        name: $scope.groupPopover.name
        type: 'news_template'
        user_group_id: $scope.ui.user.group_id
      .then (res) ->
        findGroups()
        growl.success "保存成功！"
        $scope.groupPopover.isOpen = false
        $scope.groupPopover.name = ''
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 修改組名
  $scope.editGroupPopover =
    isOpen: false
    title: "修改組名"
    name: ''
    templateUrl: 'editGroupPopoverTemplate.html'
    open: ->
      $scope.editGroupPopover.isOpen = true
    close: ->
      $scope.editGroupPopover.isOpen = false
    save: ->
      $http.put "#{config.api.baseUrl}/ers_material/group/#{$scope.ui.activeGroup._id}?access_token=" + Storage.get('access_token'),
        name: $scope.editGroupPopover.name
      .then (res) ->
        $scope.ui.activeGroup.name = $scope.editGroupPopover.name
        _.each $scope.ui.groups, (group) ->
          if group._id is $scope.ui.activeGroup._id
            group.name = $scope.editGroupPopover.name
        growl.success "保存成功！"
        $scope.editGroupPopover.isOpen = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 移動分組
  $scope.exchangePopover =
    data: null
    title: "移動分組"
    templateUrl: 'exchangePopoverTemplate.html'
    save: ->
      $http.put "#{config.api.baseUrl}/ers_material/article/template/#{$scope.exchangePopover.data._id}?access_token=" + Storage.get('access_token'),
        material_group_id: if $scope.exchangePopover.data.material_group_id is 'unclass' then '0' else $scope.exchangePopover.data.material_group_id
      .then (res) ->
        growl.success "移動分組成功！"
        findDatas()
        findGroups()
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  $scope.setExchangePopover = (material) ->
    $scope.exchangePopover.data = angular.copy material

  # 刪除分組
  $scope.delGroup = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{$scope.ui.activeGroup.name}”分組？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/ers_material/group/#{$scope.ui.activeGroup._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findGroups()

          $scope.ui.activeGroup = $scope.ui.groups[0]
          $scope.ui.totalItems = 0
          $scope.ui.materials = []
          $scope.ui.currentPage = 1
          findDatas()

          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.searchText = ->
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()


  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.materials = []
      $scope.ui.currentPage = 1
      findDatas()
