angular.module 'app'
.controller 'RetrievePasswordCtrl', ($uibModalInstance, config, $scope, $stateParams, growl, $http, data, Storage) ->
  $scope.ui =
    email: ''

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.confirm = ->
    return growl.error "請輸入您的帳戶名(電郵地址)" if $scope.ui.email == ''
    $http.post "#{config.eadmin.newBaseUrl}/oauth/forgot_password",
      email: $scope.ui.email
      clientId: config.eadmin.clientId
    .success (res) ->
      return growl.success "新密碼已發送至您的電子郵箱，請注意查收"
    .error (err) ->
      return growl.error "帳戶名(電郵地址)不存在"
