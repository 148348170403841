angular.module 'app'
.controller 'AdvisoryFeedbackCtrl', ($rootScope, $stateParams, $scope, $http, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').advisory == undefined

  $scope.ui =
    isLoading: false
    data: []

    totalItems: 0   # 总条数
    currentPage: 1   # 当前页码
    perPage: 10   # 每页条数
    maxSize: 8   # 页码最大数量

    date:
      startDate: moment().subtract(29, 'days')
      endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#advisory-feedback'
  $scope.ui.opts.opens = 'right'

  $scope.getData = getData = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')

    $scope.ui.isLoading = true
    $http.get "https://macauzxt-api.ersinfotech.cn/user/suggest?access_token=" + Storage.get('access_token'),
      params:
        start_date: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_date: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')

      $scope.ui.data = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', getData
