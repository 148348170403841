angular.module 'app'
.controller 'BasicInformationCtrl', ($scope, $http, $stateParams, $state, config, Storage, FileUploader, growl, $rootScope, dialogs) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').campus.websiteSet.is_use == false

  $scope.ui =
    isLoading: false
    isSave: false
    user: Storage.get('login_user')
    baseUrl: config.api.baseUrl

    homePage: {}
    flag: ''

  do getData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/cumpus/homepage",
      params:
        group_id: $scope.ui.user.group_id
    .then (res) ->
      if res.data.data.length > 0
        $scope.ui.homePage = res.data.data[0]
      $scope.ui.isLoading = false
    .catch (error) ->
      growl.error '查詢出錯'
      $scope.ui.isLoading = false

  $scope.uploaderClick = (type) ->
    $scope.ui.flag = type
    if $scope.ui.flag is 'logo'
      $scope.uploader.url = "#{config.api.baseUrl}/cumpus/upload/image?width=364&height=84"
    else if $scope.ui.flag is 'bgimg'
      $scope.uploader.url = "#{config.api.baseUrl}/cumpus/upload/image?width=1080&height=1920"
    else if $scope.ui.flag is 'qrcode'
      $scope.uploader.url = "#{config.api.baseUrl}/cumpus/upload/image"

  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    removeAfterUpload: true
    queueLimit: 1
    url: ""
  )

  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 0.5
        growl.error "不能上傳大於0.5MB的圖片！"
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->
    dialogs.wait '請稍後', '正在上傳...'

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    if $scope.ui.flag is 'logo'
      $scope.ui.homePage.logo = response.url
    else if $scope.ui.flag is 'bgimg'
      $scope.ui.homePage.background_img = response.url
    else if $scope.ui.flag is 'qrcode'
      $scope.ui.homePage.qr_code = response.url
    $rootScope.$broadcast 'dialogs.wait.complete'
    growl.success "上傳成功！"

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    $rootScope.$broadcast 'dialogs.wait.complete'
    growl.error "上傳失敗！"

  $scope.save = ->
    $scope.ui.isSave = true
    $scope.ui.homePage = _.omit $scope.ui.homePage, 'layouts'
    if _.isUndefined $scope.ui.homePage._id
      $http.post "#{config.api.baseUrl}/cumpus/homepage", $scope.ui.homePage
      .then (res) ->
        growl.success '保存成功'
        $scope.ui.isSave = false
      .catch (error) ->
        growl.error '保存失敗'
        $scope.ui.isSave = false
    else
      $http.put "#{config.api.baseUrl}/cumpus/homepage/#{$scope.ui.homePage._id}", $scope.ui.homePage
      .then (res) ->
        growl.success '保存成功'
        $scope.ui.isSave = false
      .catch (error) ->
        growl.error '保存失敗'
        $scope.ui.isSave = false
