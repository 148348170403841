angular.module 'app'
.controller 'MaterialManageModalCtrl', ($scope, $http, $state, $stateParams, $uibModalInstance, config, Storage, growl, data) ->

  $scope.ui =
    isLoading: false
    data: data

    user: Storage.get('login_user')
    del_group_id: ""
    fansGroups: []

    sync:
      radio: 'one'
      radio1: 'two'

    date: ''
    opts:
      autoApply: true
      parentEl: 'div#material-clear-img'
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

    date1: ''
    opts1:
      autoApply: true
      parentEl: 'div#material-clear-img'
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

  switch $scope.ui.data.type
    when 'news'
      $scope.ui.data.name = '圖文'
    when 'image'
      $scope.ui.data.name = '圖片'
    when 'video'
      $scope.ui.data.name = '視頻'
    else
      $scope.ui.data.name = '無'

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#material-manage-modal'
  $scope.ui.opts.opens = 'right'

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  # 查詢粉絲分組
  do findGroups = ->
    $http.get "#{config.api.baseUrl}/material/group?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        type: $scope.ui.data.type
        limit: 100
    .then (res) ->
      $scope.ui.fansGroups = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.save = ->
    _url = ''
    _params = {}

    if $scope.ui.data.category == 'sync'
      if $scope.ui.sync.radio == 'one'
        if $scope.ui.data.type == 'video'
          _url = "#{$stateParams.original_id}/init/sync/video"
        else
          _url = 'material/official/syncWechat_Local'
          _params =
            original_id: $stateParams.original_id
            type: $scope.ui.data.type
      else
        _url = 'material/official/syncLocal_Wechat'
        _params =
          original_id: $stateParams.original_id
          type: $scope.ui.data.type

      _params.is_whole = false
      if $scope.ui.sync.radio1 == 'one'
        _params.is_whole = true
        _params.start_date = ''
        _params.end_date = ''
      else
        return growl.error('開始時間格式不正確') if String(new Date($scope.ui.date)) is 'Invalid Date'
        return growl.error('結束時間格式不正確') if String(new Date($scope.ui.date1)) is 'Invalid Date'
        return growl.error('結束時間必須大於開始時間') if new Date($scope.ui.date) > new Date($scope.ui.date1)
        _params.start_date = moment($scope.ui.date).format('YYYY-MM-DD HH:mm:ss')
        _params.end_date = moment($scope.ui.date1).format('YYYY-MM-DD HH:mm:ss')
    else
      _url = 'material/delete/byNum'
      _params.is_whole = false
      _params.original_id = $stateParams.original_id
      _params.type = $scope.ui.data.type
      _params.del_by = $scope.ui.user.username
      if $scope.ui.sync.radio1 == 'one'
        _params.is_whole = true
        _params.date = ''
      else if $scope.ui.sync.radio1 == 'two'
        return growl.error('開始時間格式不正確') if String(new Date($scope.ui.date)) is 'Invalid Date'
        _params.date = moment($scope.ui.date).format('YYYY-MM-DD HH:mm:ss')
      else
        _params.material_group_id = $scope.ui.del_group_id

    $scope.ui.isLoading = true
    $http.post "#{config.api.baseUrl}/#{_url}?access_token=" + Storage.get('access_token'), _params
    .then (res) ->
      growl.success '同步成功' if $scope.ui.data.category == 'sync'
      growl.success '正在處理中' if $scope.ui.data.category == 'delete'
      $scope.ui.isLoading = false
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false
