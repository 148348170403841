angular.module 'app'
.controller 'MenuCtrl', ($scope, $http, $stateParams, $state, config, Storage, $sce, growl, $timeout, dialogs, $rootScope) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.menu.is_use == false

  $scope.ui =
    click: []
    user: []
    average: []
    versions: []
    version: ""
    table_data: []
    menu: ""
    menus: []
    search: "click_count"
    search_name: "菜單點擊次數"
    charts: []

    radioModel: '0'

    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()

    table_date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()

    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#analysis-menu'
  $scope.ui.opts.opens = 'right'

  $scope.htmlPopover = $sce.trustAsHtml('<div class="help-popover" style="width: 300px"><dt>菜單點擊數</dt><dd>菜單被用戶點擊的次數</dd><dt>菜單點擊人數</dt><dd>點擊菜單的去重用戶數</dd><dt>人均點擊次數</dt><dd>菜單點擊次數/菜單點擊的去重用戶數</dd><dt>版本</dt><dd>每提交更新一次菜單算作一個版本</dd><dd class="border-top-solid padding-top-md">數據從2013年7月1日開始統計。由於服務器緩存，以及指標計算方法和統計時間的差異，數據可能出現微小誤差，一般在1%以內。</dd></div>')

  # 取绝对值
  $scope.abs = (data) ->
    return Math.abs data

  $scope.newsChart =
    title:
      text: ''
    tooltip:
      trigger: 'axis'
    legend:
      x: 'center'
      y: 'bottom'
      data: ['']
    grid:
      containLabel: true
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    xAxis: [{
      type: 'category'
      boundaryGap: false
      splitLine:
        show: false
      data: ['']
    }]
    yAxis: [{
      type: 'value'
    }]
    series: [
        name: ''
        type: 'line'
        data: []
    ]

  do fetchKeyrate = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/menu/keyrate?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.click = res.data.click_count
      $scope.ui.user = res.data.people_count
      $scope.ui.average = res.data.average_count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  do fetchVersion = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/menu/menu_analysis?access_token=" + Storage.get('access_token'),
      params:
        start_created_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      $scope.ui.versions = res.data
      $scope.ui.version = $scope.ui.versions[0] if $scope.ui.versions.length > 0
      selectVersion()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  do fetchTableData = ->
    $scope.ui.table_data = []
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/menu/menu_statis?access_token=" + Storage.get('access_token'),
      params:
        start_created_at: moment($scope.ui.table_date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.table_date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      _result = []
      _.each res.data.data, (val) ->
        _index = 0
        _.each val.statistic, (valStat) ->
          _.each valStat.data, (valData, index) ->
            obj = {}
            obj =
              version: val.version
              version_length: val.list_length
            obj.menu = valStat.menu_name
            obj.main_length = valStat.data.length
            obj.version_index = _index
            obj.menu_index = index
            obj.submenu = valData.sub_name
            obj.click_count = valData.click_count
            obj.people_count = valData.people_count
            obj.average_count = valData.average_count
            obj.key_id = valData.key_id
            _result.push obj
            ++_index
      $scope.ui.table_data = _result
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.selectVersion = selectVersion = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/menu/menu_analysis?access_token=" + Storage.get('access_token'),
      params:
        start_created_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
        version: $scope.ui.version
    .then (res) ->
      if res.data.length > 0
        $scope.ui.menus = _.pluck res.data[0].data, "top5_name"
        $scope.ui.menus.unshift '全部'
        $scope.ui.menu = $scope.ui.menus[0]

      $scope.newsChart.xAxis[0].data = _.pluck res.data, "statis_time"

      _result = []
      _.each res.data, (val) ->
        _.each val.data, (valData) ->
          obj = {}
          obj.key = valData.top5_name
          obj.click_count = valData.click_count
          obj.people_count = valData.people_count
          obj.avg_count = valData.avg_count
          _result.push obj

      if res.data.length > 0
        $scope.ui.charts = []
        _.each res.data[0].data, (val) ->
          obj = {}
          obj.name = val.top5_name
          obj.type = "line"
          data = _.where _result, key: val.top5_name
          obj.click_count = _.pluck data, "click_count"
          obj.people_count = _.pluck data, "people_count"
          obj.avg_count = _.pluck data, "avg_count"
          $scope.ui.charts.push obj

      charts = []
      $scope.newsChart.legend.data = []
      _.each $scope.ui.charts, (val) ->
        obj = {}
        obj.name = $scope.ui.version + "-" + val.name
        obj.type = "line"
        obj.data = val[$scope.ui.search]
        charts.push obj
        $scope.newsChart.legend.data.push $scope.ui.version + "-" + val.name
      $scope.newsChart.series = charts
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.changeMenu = changeMenu = ->
    charts = []
    $scope.newsChart.legend.data = []
    _.each $scope.ui.charts, (val) ->
      obj = {}
      obj.name = $scope.ui.version + "-" + val.name
      obj.type = "line"
      obj.data = val[$scope.ui.search]
      charts.push obj
      $scope.newsChart.legend.data.push $scope.ui.version + "-" + val.name
    if $scope.ui.menu is "全部"
      $scope.newsChart.series = charts
    else
      $scope.newsChart.legend.data = [$scope.ui.version + "-" + $scope.ui.menu]
      $scope.newsChart.series = _.where charts, name: $scope.ui.version + "-" + $scope.ui.menu

  $scope.changeType = (type, name) ->
    $scope.ui.search = type
    $scope.ui.search_name = name
    changeMenu()

  $scope.$watch 'ui.date', (newValue, oldValue) ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')
    return if newValue == oldValue
    fetchVersion()

  $scope.$watch 'ui.table_date', (newValue, oldValue) ->
    if $scope.ui.table_date.startDate is null or $scope.ui.table_date.endDate is null or String(new Date($scope.ui.table_date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.table_date.endDate)) is 'Invalid Date'
      $scope.ui.table_date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')

    return if newValue == oldValue
    fetchTableData()

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadData = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/menu/statis_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        start_created_at: moment($scope.ui.table_date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.table_date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.showPeople = (data) ->
    return if data.people_count == 0
    dlg = dialogs.create('apps/qrcode/modal/qrcode-modal', 'QrcodeModalCtrl', {type: 'menu', version: data.version, key_id: data.key_id, startDate: moment($scope.ui.table_date.startDate).format('YYYY-MM-DD 00:00:00'), endDate: moment($scope.ui.table_date.endDate).format('YYYY-MM-DD 23:59:59')}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
    ), ->