angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.qrcode',
      url: '/qrcode'
      templateUrl: 'apps/qrcode/list/list'
      controller: 'QrcodeListCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'jqueryFileDownload/jquery.fileDownload.js'
              ]
          ])
