angular.module 'app'
.controller 'LinkManageCtrl', ($scope, $state, $http, $q, Storage, $stateParams, config, SweetAlert, growl, dialogs) ->

  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')
    column: {}
    baseUrl: config.api.baseUrl
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    links: []

  do findColumn = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/#{$stateParams._id}", {}
    .then (res) ->
      $scope.ui.column = res.data
    .catch (error) ->

  $scope.goBack = ->
    $state.go("app.column-manage", {})

  findData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_content: true
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        group_id: $scope.ui.user.group_id
        programa_id: $stateParams._id
        sort: '-created_at'
    .then (res) ->
      _.each res.data.data, (data) ->
        data.release_time = moment(data.release_time).format('YYYY-MM-DD')
        data.created_at = moment(data.created_at).format('YYYY-MM-DD')
      $scope.ui.links = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .catch (error) ->
      $scope.ui.isLoading = false
      growl.error '查詢錯誤!', {ttl: 2000}

  $scope.$watch 'ui.currentPage', findData

  $scope.addLink = ->
    dlg = dialogs.create("apps/campus/column-manage/modal/edit-link-manage/edit-link-manage", "EditLinkManageCtrl", {_id: $stateParams._id, type: 'add'}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  $scope.editLink = (link) ->
    dlg = dialogs.create("apps/campus/column-manage/modal/edit-link-manage/edit-link-manage", "EditLinkManageCtrl", {_id: $stateParams._id, link: link, type: 'edit'}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  $scope.editIsShow = (link) ->
    $http.put "#{config.api.baseUrl}/cumpus/programa/list/#{link._id}", link
    .then (res) ->
      growl.success '保存成功！', ttl: 2000
    .catch (error) ->
      link.data.is_show = !link.is_show
      growl.error '保存失敗！', ttl: 2000

  $scope.delLink = (link) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{link.data.title}”文章嗎？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cumpus/programa/list/#{link._id}", {}
        .then (res) ->
          findData()
          growl.success '刪除成功'
        .catch (error) ->
          growl.error '刪除失敗'