angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.messagemanagement',
      url: '/message-management'
      templateUrl: 'apps/message-management/layout/layout'
      controller: 'MessageManagementCtrl'

    .state 'app.messagemanagement.messagelist',
      url: '/message-list'
      templateUrl: 'apps/message-management/message-list/message-list'
      controller: 'MessageListCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    .state 'app.messagemanagement.massinformation',
      url: '/mass-information'
      templateUrl: 'apps/message-management/mass-information/mass-information'
      controller: 'MassInformationCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
