angular.module 'app'
.controller 'SelectedFansCtrl', ($rootScope, $timeout, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, $uibModalInstance, data) ->

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 8
    maxSize: 8
    totalItems: 0
    fans: []

  do findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/task/#{data._id}/get-tousers?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.fans = res.data.data
      _.each res.data.data, (data) ->
        data.subscribe_time = moment(data.subscribe_time).format('YYYY-MM-DD')
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.$watch 'ui.currentPage', findDatas