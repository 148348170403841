angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.pagetemplate',
      url: '/page-template'
      templateUrl: 'apps/page-template/page-template'
      controller: 'PageTemplateCtrl'

    .state 'app.selectTemplate',
      url: '/select-Template'
      templateUrl: 'apps/page-template/select-Template'
      controller: 'SelectTemplateCtrl'

    .state 'app.listtemplate',
      url: '/list-template/:_id'
      templateUrl: 'apps/page-template/template/list-template'
      controller: 'ListTemplateCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    .state 'app.covertemplate',
      url: '/cover-template/:_id'
      templateUrl: 'apps/page-template/template/cover-template'
      controller: 'CoverTemplateCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    .state 'app.addListtemplate',
      url: '/addList-template'
      templateUrl: 'apps/page-template/add/addList-template'
      controller: 'AddListTemplateCtrl'

    .state 'app.addCovertemplate',
      url: '/addCover-template'
      templateUrl: 'apps/page-template/add/addCover-template'
      controller: 'AddCoverTemplateCtrl'


