angular.module 'app'
.controller 'UserDataCtrl', ($uibModalInstance, config, $scope, $stateParams, growl, $http, data, Storage) ->
  $scope.ui =
    user: Storage.get('login_user')
    eadminDisplay: config.eadmin.display

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.confirm = ->
    if $scope.ui.eadminDisplay == true
     $http.post "#{config.eadmin.newBaseUrl}/account/update_me?access_token=" + Storage.get('access_token'),
        username: $scope.ui.user.username
      .then (res) ->
        growl.success "保存成功！"
        Storage.set('login_user', $scope.ui.user)
        $uibModalInstance.close res.data.username
      .catch (error) ->
        growl.error "保存失敗！"
    else
      $http.put "#{config.eadmin.baseUrl}/account/groups/#{$scope.ui.user.group_id}/users/#{$scope.ui.user.id}?access_token=" + Storage.get('access_token'), $scope.ui.user
      .then (res) ->
        growl.success "保存成功！"
        Storage.set('login_user', $scope.ui.user)
        $uibModalInstance.close res.data.username
      .catch (error) ->
        growl.error "保存失敗！"
