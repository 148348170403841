angular.module 'app'
.controller 'AddVideoCtrl', ($scope, $http, $state, $uibModalInstance, config, Storage, growl, data) ->
  $scope.ui =
    original_id: data.originId
    name: ""
    url: ""
    wechats: []
    wechat: ""

  $http.get "#{config.api.baseUrl}/manage/log_usages/wechats?access_token=" + Storage.get('access_token'), {}
  .then (res) ->
    $scope.ui.wechats  = res.data

  $scope.cancel = ->
    $uibModalInstance.dismiss 'Cancel'

  $scope.save = ->
    $http.post "#{config.api.baseUrl}/material/add-video?access_token=" + Storage.get('access_token'),
      original_id: $scope.ui.wechat
      name: $scope.ui.name
      url: $scope.ui.url
    .then (res) ->
      $uibModalInstance.close 'Save'
      growl.success "保存成功！"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
