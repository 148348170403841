angular.module 'app'
.controller 'AddQaCtrl', ($scope, $http, $state, $uibModalInstance, $stateParams, config, $timeout, Storage, dialogs, growl, data, FileUploader, $rootScope, SweetAlert) ->

  $scope.ui =
    isLoading: false
    appmsg: {
      id: ''
      first: ''
      second: ''
      qas: []
      question: ''
      answer: ''
    }
    randomLength: 16
    keywordQa:
      id: ''
      question: ''
      answer: ''
    keywordQas: []
    activeKeywordQas: {}
    keyword: false
    step: 0

  findDatas = ->
    if data.appmsg._id
      $scope.ui.step = 1
      if data.appmsg.first == '--'
        $scope.ui.appmsg.first = ''
      else
        $scope.ui.appmsg.first = data.appmsg.first
        $scope.ui.keyword = true
      if data.appmsg.second == '--'
        $scope.ui.appmsg.second = ''
      else
        $scope.ui.appmsg.second = data.appmsg.second
        $scope.ui.keyword = true
      $scope.ui.appmsg.question = data.appmsg.question
      $scope.ui.appmsg.answer = data.appmsg.answer
      $scope.ui.appmsg._id = data.appmsg._id
    else
      $scope.ui.appmsg.first = ''
      $scope.ui.appmsg.second = ''
      $scope.ui.appmsg._id = ''

  buildRandom = (length) ->
    charts = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    i = 0
    _id = ''
    while i < length
      _id += charts.charAt(Math.floor(Math.random() * charts.length))
      i++
    findId = _.where $scope.ui.secKeywords, {id: _id}
    return buildRandom($scope.ui.randomLength) if findId.length > 0
    return _id

  $scope.addSecKeyword = ->
    secKeyword = angular.copy $scope.ui.secKeyword
    secKeyword.id = buildRandom($scope.ui.randomLength)
    secKeyword.lists = []
    $scope.ui.secKeywords.push secKeyword

    $scope.ui.activeSecKeyword = $scope.ui.secKeywords[$scope.ui.secKeywords.length - 1]
    $scope.ui.activeIndex = $scope.ui.secKeywords.length - 1

  # 添加問題
  addQa = ->
    keywordQa = angular.copy $scope.ui.keywordQa
    keywordQa.id = buildRandom($scope.ui.randomLength)
    $scope.ui.appmsg.qas.push keywordQa

    $scope.ui.activeIndex = $scope.ui.appmsg.qas.length - 1
    $scope.ui.activeKeywordQas = $scope.ui.appmsg.qas[$scope.ui.activeIndex]


  addQa()
  findDatas()

  $scope.addQa = ->
    addQa()

  #刪除問題
  $scope.delQa = (qa) ->
    return if $scope.ui.appmsg.qas.length == 1
    SweetAlert.swal {
      title: "確定刪除此問題?"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.appmsg.qas = _.reject $scope.ui.appmsg.qas, (data) ->
          data.id == $scope.ui.appmsg.qas[$scope.ui.appmsg.qas.length - 1].id

  $scope.save = ->
    $scope.ui.isLoading = true
    if $scope.ui.keyword == true
      return growl.error "請輸入主次關鍵字" if $scope.ui.appmsg.first == '' && $scope.ui.appmsg.second == ''
      return growl.error "請輸入次關鍵字" if $scope.ui.appmsg.first != '' && $scope.ui.appmsg.second == ''
      return growl.error "請輸入主關鍵字" if $scope.ui.appmsg.first == '' && $scope.ui.appmsg.second != ''
    else
      $scope.ui.appmsg.first = ''
      $scope.ui.appmsg.second = ''
    if data.appmsg._id
      return growl.error "請輸入問題" if $scope.ui.appmsg.question == ''
      return growl.error "請輸入答案" if $scope.ui.appmsg.answer == ''
      $http.put "#{config.api.baseUrl}/dsi/qa/#{data.appmsg._id}?access_token=" + Storage.get('access_token'),
        first: $scope.ui.appmsg.first
        second: $scope.ui.appmsg.second
        question: $scope.ui.appmsg.question
        answer: $scope.ui.appmsg.answer
      .then (res) ->
        if res.data.repeated_q_no == 0
          growl.success '修改成功'
        else
          return growl.error '請不要添加相同的問題'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    else
      qEmpty = false
      aEmpty = false
      _.each $scope.ui.appmsg.qas, (data) ->
        qEmpty = true if data.question == ''
        aEmpty = true if data.answer == ''
      return growl.error "請輸入問題" if qEmpty
      return growl.error "請輸入答案" if aEmpty
      $http.post "#{config.api.baseUrl}/dsi/qa?access_token=" + Storage.get('access_token'),
        first: $scope.ui.appmsg.first
        second: $scope.ui.appmsg.second
        qas: $scope.ui.appmsg.qas
      .then (res) ->
        if res.data.repeated_q_no == 0
          growl.success '創建成功'
        else
          growl.success '創建成功'
          growl.error '已排除重複問題集' + res.data.repeated_q_no + '條'
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'


  $scope.uploadBtn = ->
    $scope.uploader.url = "#{config.api.baseUrl}/dsi/qa/upload_excel?access_token=#{Storage.get('access_token')}"

  uploader = $scope.uploader = new FileUploader(
    removeAfterUpload: true
    autoUpload: true
    url: ""
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      type = '|' + item.name.slice(item.name.lastIndexOf('.') + 1) + '|'
      '|xls|xlsx|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->
    dialogs.wait '請稍後', '正在上傳...'

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $rootScope.$broadcast 'dialogs.wait.complete'
    $uibModalInstance.close 'Save'
    growl.success '上傳成功,已排除格式不符問題集'
    findDatas()

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    $rootScope.$broadcast 'dialogs.wait.complete'
    if response.error.indexOf('errtext') >= 0
      err = JSON.parse response.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')
    findDatas()
  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadSample = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/dsi/qa/download_sample?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      # data:
      #   q: $scope.ui.search.text
      #   status: $scope.ui.status
      prepareCallback: prepare
      successCallback: success
      failCallback: error
  # 手動添加
  $scope.manully = ->
    $scope.ui.step = 1

  # 批量添加
  $scope.batches = ->
    $scope.ui.step = 2

  # 上一步
  $scope.prev = ->
    $scope.ui.step = 0
