angular.module 'app'
.controller 'FigureDetailCtrl', ($rootScope, $stateParams, $scope, $http, $state, $uibModalInstance, config, ueditorConfig, Storage, FileUploader, SweetAlert, growl, dialogs, data, SocketIO, $interval, $timeout) ->


  $scope.ui =
    config:
      wordCount: false
      maximumWords: 20000
      wordCountMsg: '&nbsp;當前您已輸入&nbsp;{#count}&nbsp;個字符。'
      elementPathEnabled: false
      autoHeightEnabled: false
      autoFloatEnabled: false
      autoTransWordToList: true
      imagePopup: true
      insertorderedlist:
        'decimal': ''
        'lower-alpha': ''
        'lower-roman': ''
        'upper-alpha': ''
        'upper-roman': ''
      insertunorderedlist:
        circle: ''
        disc: ''
        square: ''
      lang: "zh-tw"
      initialFrameHeight: angular.element('#graphic-detail').height() - 100
      toolbars: ueditorConfig.ue.config.toolbars
    content: '內文'

    randomLength: 16  # 隨機ID長度
    articleId: []  # 圖文ID集合
    newArticleId: []  # 新增圖文ID集合
    activeIndex: 0  # 當前焦點下標
    intervalIndex: '--'

  $scope.$on '$destroy', ->
    editor = UE.getEditor 'uecontainer'
    editor.destroy()

  ### 获取编辑器模板 ###
  _.defer ->
    $scope.ui.config.initialFrameHeight = angular.element('#graphic-detail').height()
    uex('uecontainer', ->
      $http.get "#{config.api.baseUrl}/ueditor/group?access_token=" + Storage.get('access_token'), {}
      .then (res) ->
        console.log res
        setTemplate('uecontainer', res.data.data)
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    )
