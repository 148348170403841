angular.module 'app'
.controller 'MaterialManageModalPostCtrl', ($scope, $http, $state, $stateParams, $uibModalInstance, config, $rootScope, dialogs, SweetAlert, Storage, growl, data ) ->

  $scope.ui =
    isLoading: false
    data: data

    user: Storage.get('login_user')
    del_group_id: ""
    fansGroups: []

    sync:
      radio: 'one'
      radio1: 'two'

    date: ''
    opts:
      autoApply: true
      parentEl: 'div#material-clear-img'
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

    date1: ''
    opts1:
      autoApply: true
      parentEl: 'div#material-clear-img'
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

  switch $scope.ui.data.type
    when 'news'
      $scope.ui.data.name = '圖文'
    when 'image'
      $scope.ui.data.name = '圖片'
    when 'video'
      $scope.ui.data.name = '視頻'
    else
      $scope.ui.data.name = '無'

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#material-manage-modal'
  $scope.ui.opts.opens = 'right'

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    _params =
      original_id: $stateParams.original_id

    if $scope.ui.sync.radio1 == 'one'
      _params.start_date = ''
      _params.end_date = ''
    if $scope.ui.sync.radio1 == 'two'
      return growl.error('開始時間格式不正確') if String(new Date($scope.ui.date)) is 'Invalid Date'
      return growl.error('結束時間格式不正確') if String(new Date($scope.ui.date1)) is 'Invalid Date'
      return growl.error('結束時間必須大於開始時間') if new Date($scope.ui.date) > new Date($scope.ui.date1)
      _params.start_date = moment($scope.ui.date).format('YYYY-MM-DD HH:mm:ss')
      _params.end_date = moment($scope.ui.date1).format('YYYY-MM-DD HH:mm:ss')
    SweetAlert.swal {
      title: ""
      text: "同步後，圖文素材將被官方平台的覆蓋!"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      confirmButtonText: '同步'
      cancelButtonText: '取消'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.isLoading = true
        $http.post "#{config.api.baseUrl}/material/sync/news?access_token=" + Storage.get('access_token'), _params
        .then (res) ->
          setTimeout( ->
            if res.status == 200
              $scope.ui.isLoading = false
              $uibModalInstance.close 'Save'
            else
              growl.error('網絡連接失敗，請稍後重試')
          , 4000)
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          $scope.ui.isLoading = false
