angular.module 'app'
.controller 'CardCouponsBasicInformationCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').cardCoupons.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    baseUrl: config.api.baseUrl

    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6

    dataArr: []
    logoUrl: ''
    cardType:
      GROUPON: '團購券'
      DISCOUNT: '折扣券'
      GIFT: '禮品券'
      CASH: '代金券'
      GENERAL_COUPON: '通用券'
      MEMBER_CARD: '會員卡'
      SCENIC_TICKET: '景點門票'
      MOVIE_TICKET: '電影票'
      BOARDING_PASS: '飛機票'
      MEETING_TICKET: '會議門票'
      BUS_TICKET: '汽車票'
    cardStatus:
      CARD_STATUS_NOT_VERIFY: '待審核'
      CARD_STATUS_VERIFY_FAIL: '審核失敗'
      CARD_STATUS_VERIFY_OK: '通過審核'
      CARD_STATUS_DELETE: '卡券被商戶刪除'
      CARD_STATUS_DISPATCH: '在公眾平台投放過的卡券'

  findData = ->
    $scope.ui.activeDataArr = []
    $http.get "#{config.api.baseUrl}/cards/getCardInfos?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        open_id: ''
        card_type: ''
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.dataArr = res.data.data
      $scope.ui.logoUrl = res.data.webapp_logo if res.data.webapp_logo
      $scope.ui.totalItems = res.data.count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.refresh = ->
    $http.post "#{config.api.baseUrl}/cards/refresh?access_token=" + Storage.get('access_token'),
      original_id: $stateParams.original_id
    .then (res) ->
      findData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.logo = ->
    dlg = dialogs.create('apps/card-coupons/modal/logo-manage/logo-manage', 'LogoManageCtrl', {path: $scope.ui.logoUrl}, {size: 'md'})
    dlg.result.then ((option) ->
      findData()
    ), ->

  $scope.$watch 'ui.currentPage', findData
