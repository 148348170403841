angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    # 公眾號監測
    .state 'app.wechat-monitor',
      url: '/wechat-monitor'
      templateUrl: 'apps/monitor/wechat-monitor/wechat-monitor'
      controller: 'WechatMonitorCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'jqueryFileDownload/jquery.fileDownload.js'
                'Highcharts-4.2.4/js/highcharts.src.js'
              ]
          ])

    # 文章排名
    .state 'app.hot-article',
      url: '/hot-article'
      templateUrl: 'apps/monitor/hot-article/hot-article'
      controller: 'HotArticleCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'jqueryFileDownload/jquery.fileDownload.js'
              ]
          ])

    # 公眾號排名
    .state 'app.wechat-top',
      url: '/wechat-top'
      templateUrl: 'apps/monitor/wechat-top/wechat-top'
      controller: 'WechatTopCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
