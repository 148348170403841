angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.advisory',
      url: '/advisory'
      templateUrl: 'apps/advisory/layout/layout'
      controller: 'AdvisoryCtrl'

    # 资讯通  视图
    .state 'app.advisory.view',
      url: '/advisory-view'
      templateUrl: 'apps/advisory/advisory-view/advisory-view'
      controller: 'AdvisoryViewCtrl'

    # 资讯通  数据
    .state 'app.advisory.data',
      url: '/advisory-data'
      templateUrl: 'apps/advisory/advisory-data/advisory-data'
      controller: 'AdvisoryDataCtrl'

    # 资讯通  用户反馈
    .state 'app.advisory.feedback',
      url: '/advisory-feedback'
      templateUrl: 'apps/advisory/advisory-feedback/advisory-feedback'
      controller: 'AdvisoryFeedbackCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
