angular.module 'app'
.controller 'AddPersonalizedCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert, $timeout, $uibModalInstance, data, findRules) ->

  $scope.ui =
    matchruleList: []  # 个性化菜单条件列表
    activeCountry: []
    activeProvince: []
    activeCity: []
    data_id: ''
    stateParams_id: $stateParams._id if $stateParams._id
    menu_name: data.menu_name  # 个性化菜单名称
    activeIndividualization:  # 当前个性化菜单信息
      matchrule:  # 個性化菜單匹配條件
        tag: ''
        country: ''
        province: ''
        city: ''
        language: ''
        sex: ''
        client_platform_type: ''


  do getIndividualizationMatchrule = ->
    if data.matchrule
      $scope.ui.activeIndividualization.matchrule.country = data.matchrule.country
      $scope.ui.activeIndividualization.matchrule.province = data.matchrule.province
      $scope.ui.activeIndividualization.matchrule.city = data.matchrule.city

    findRules()
    .then (res) ->
      $scope.ui.matchruleList = res
      $scope.ui.activeCountry = $scope.ui.matchruleList[1].Val
      _.each $scope.ui.matchruleList[0].Val, (data1) ->
        if data.matchrule
          $scope.ui.activeIndividualization.matchrule.tag = data1.value if data.matchrule.tag == data1.key
      _.each $scope.ui.matchruleList[2].Val, (data2) ->
        if data.matchrule
          $scope.ui.activeIndividualization.matchrule.language = data2.value if data.matchrule.language == data2.key
      _.each $scope.ui.matchruleList[3].Val, (data3) ->
        if data.matchrule
          $scope.ui.activeIndividualization.matchrule.sex = data3.value if data.matchrule.sex == data3.key
      _.each $scope.ui.matchruleList[4].Val, (data4) ->
        if data.matchrule
          $scope.ui.activeIndividualization.matchrule.client_platform_type = data4.value if data.matchrule.client_platform_type == data4.key

      $scope.changeCountry()
      $scope.changeProvince()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.changeCountry = ->
    _.each $scope.ui.activeCountry, (_country) ->
      $scope.ui.activeProvince = _country.value if $scope.ui.activeIndividualization.matchrule.country == _country.key
      $scope.ui.activeCity = []

  $scope.changeProvince = ->
    _.each $scope.ui.activeProvince, (_provincey) ->
      $scope.ui.activeCity = _provincey.value if $scope.ui.activeIndividualization.matchrule.province == _provincey.key


  # $scope.$watch 'ui.activeCountry', (newValue, oldValue) ->
  #   return if newValue == oldValue
  #   $scope.ui.activeProvince = []
  #   $scope.ui.activeCity = []


  # $scope.$watch 'ui.activeProvince', (newValue, oldValue) ->
  #   return if newValue == oldValue
  #   $scope.ui.activeCity = []

  $scope.savePersonalized = ->
    if $stateParams._id && $stateParams._id != ''
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu/#{$stateParams._id}?access_token=" + Storage.get('access_token'),
        matchrule: $scope.ui.activeIndividualization.matchrule
        menu_name: $scope.ui.menu_name
        type: "update_rule"
      .then (res) ->
        $scope.ui.isDisableSave = false
        $scope.ui.data_id = res.data._id
        $scope.ui.activeIndividualization.matchrule = res.data.matchrule
        growl.success "保存成功"
        $uibModalInstance.close {
          data_id: $scope.ui.data_id
    }
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.isDisableSave = false
    else
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu?access_token=" + Storage.get('access_token'),
        matchrule: $scope.ui.activeIndividualization.matchrule
        menu_name: $scope.ui.menu_name
        type: "update_rule"
      .then (res) ->
        $scope.ui.isDisableSave = false
        $scope.ui.data_id = res.data._id
        $scope.ui.activeIndividualization.matchrule = res.data.matchrule
        growl.success "保存成功"
        $uibModalInstance.close {
          data_id: $scope.ui.data_id
    }
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.isDisableSave = false

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
