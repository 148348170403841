angular.module 'app'
.controller 'AlumniCtrl', ($scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').campus.schoolInformation.is_use == false

  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')

    status: 'all'
    student_class: ''
    student_grade: ''
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    students: []
    classData: {}


  do findClass = ->
    $http.get "#{config.api.baseUrl}/cumpus/class",
      params:
        group_id: $scope.ui.user.group_id
    .then (res) ->
      res.data.student_grade = res.data.student_grade.sort()
      res_class = []
      _.each res.data.student_class, (val) ->
        obj = {}
        obj.name = val
        obj.key = switch val
          when "甲" then 1
          when "乙" then 2
          when "丙" then 3
          when "丁" then 4
          when "戊" then 5
          when "己" then 6
          when "庚" then 7
          when "辛" then 8
          when "壬" then 9
          when "癸" then 10
        res_class.push obj
      res.data.student_class = _.sortBy res_class, "key"
      res_grade = []
      _.each res.data.student_grade, (val) ->
        obj = {}
        obj.name = val
        obj.key = switch val
          when "幼兒" then 1
          when "幼初" then 2
          when "幼高" then 3
          when "小一" then 4
          when "小二" then 5
          when "小三" then 6
          when "小四" then 7
          when "小五" then 8
          when "小六" then 9
          when "初一" then 10
          when "初二" then 11
          when "初三" then 12
          when "高一" then 13
          when "高二" then 14
          when "高三" then 15
        res_grade.push obj
      res.data.student_grade = _.sortBy res_grade, "key"
      $scope.ui.classData = res.data
    .catch (error) ->

  findData = ->
    $scope.ui.isLoading = true
    myparams =
      limit: $scope.ui.perPage
      offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      group_id: $scope.ui.user.group_id
      student_class: $scope.ui.student_class
      student_grade: $scope.ui.student_grade
      student_id: $scope.ui.student_id
      sort: 'student_class'

    if $scope.ui.is_verification is 'true'
      myparams.is_verification = true
    else if $scope.ui.is_verification is 'false'
      myparams.is_verification = ''

    if $scope.ui.status is 'all'
      myparams.status = ['2', '3', '4']
    else
      myparams.status = $scope.ui.status

    $http.get "#{config.api.baseUrl}/cumpus/student",
      params:
        myparams
    .then (res) ->
      $scope.ui.students = res.data.data
      $scope.ui.totalItems = res.data.count

      _.each $scope.ui.students, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm')
        data.last_update_time = moment(data.last_update_time).format('YYYY-MM-DD HH:mm')
        data.verification_time = moment(data.verification_time).format('YYYY-MM-DD HH:mm') if data.verification_time && data.verification_time != ''

      $scope.ui.isLoading = false
    .catch (error) ->
      $scope.ui.isLoading = false
      growl.error '查詢錯誤'

  $scope.$watch 'ui.currentPage', findData

  $scope.search = ->
    $scope.ui.currentPage = 1
    $scope.ui.totalItems = 0
    findData()

  $scope.del = (student) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{student.student_id}”學生嗎？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cumpus/student/#{student._id}", {}
        .then (res) ->
          findData()
          growl.success '刪除成功'
        .catch (error) ->
          growl.error '刪除失敗'

  $scope.delAll = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除所有學生信息嗎？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cumpus/student?group_id=#{$scope.ui.user.group_id}&status=2", {}
        .then (res) ->
          findData()
          growl.success '刪除成功'
        .catch (error) ->
          growl.error '刪除失敗'

  $scope.edit = (student) ->
    dlg = dialogs.create('apps/campus/school-information/modal/edit-alumni/edit-alumni', 'EditAlumniCtrl', {student: student, classData: $scope.ui.classData}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  $scope.restore = (student) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否將該學生恢復「在校」狀態？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.post "#{config.api.baseUrl}/cumpus/student/restore",
          student_id: student.student_id
        .then (res) ->
          growl.success '保存成功'
          findData()
        .catch (error) ->
          growl.error '保存失敗'


  $scope.downloadCsv = ->
    myparams =
      type: 'student'
      group_id: $scope.ui.user.group_id
      student_class: $scope.ui.student_class
      student_grade: $scope.ui.student_grade
      student_id: $scope.ui.student_id
      sort: 'student_class'

    if $scope.ui.is_verification is 'true'
      myparams.is_verification = true
    else if $scope.ui.is_verification is 'false'
      myparams.is_verification = ''

    if $scope.ui.status is 'all'
      myparams.status = ['2', '3', '4']
    else
      myparams.status = $scope.ui.status

    $.fileDownload "#{config.api.baseUrl}/cumpus/export/csv",
      httpMethod: "POST"
      data: myparams

