angular.module 'app'
.controller 'LockCtrl', ($scope, $http, $state, $uibModalInstance, config, Storage, FileUploader, growl, data, dialogs) ->

  $scope.ui =
    is_encrypted: data.wechat.is_encrypted
    encodingAESKey: data.wechat.encodingAESKey
    radioType: if data.wechat.is_encrypted then 'SafeMode' else 'plaintextMode'

  $scope.commit = ->
    return growl.error '消息加密密鑰必須由43位字符組成' if $scope.ui.encodingAESKey != null && $scope.ui.encodingAESKey.length < 43

    return growl.error 'EncodingAESKey 不可為空' if $scope.ui.encodingAESKey == null && lockmode != 'plaintextMode'

    if $scope.ui.radioType == 'plaintextMode'
      $scope.ui.is_encrypted = false
    else
      $scope.ui.is_encrypted = true

    $http.post "#{config.api.baseUrl}/wechat/switchMode?access_token=" + Storage.get('access_token'),
      original_id: data.wechat.original_id
      encodingAESKey: $scope.ui.encodingAESKey
      is_encrypted: $scope.ui.is_encrypted
    .then (res) ->
      $uibModalInstance.close 'Save'
      growl.success "提交成功！"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.dismiss 'Cancel'
