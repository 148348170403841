angular.module 'app'
.controller 'UserDetailsCtrl', ($uibModalInstance, config, $scope, $stateParams, growl, $http, data, Storage) ->
  $scope.ui =
    data: data
    tableArr: []

  do getData = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/follower_detail?access_token=" + Storage.get('access_token'),
      params:
        type: $scope.ui.data.type
        statis_time: $scope.ui.data.time
    .then (res) ->
      $scope.ui.tableArr = res.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
